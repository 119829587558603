import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './css/TextGeneration.css';
import Select from 'react-select';
import PopupRowData from '../popups/PopupRowData';
import PopupTextReplace from '../popups/PopupTextReplace';
import PopupTextBulkActions from '../popups/PopupTextBulkActions';
import PopupTextPublish from '../popups/PopupTextPublish';




import { ClipLoader } from 'react-spinners';
import { 
  FaArrowLeft, 
  FaSignOutAlt, 
  FaSyncAlt, 
  FaCalendarAlt, 
  FaFileExport, 
  FaFilter, 
  FaTools, 
  FaTimes, 
  FaChevronRight, 
  FaChevronLeft,
  FaUpload,
  FaDownload,
  FaCheckCircle,
  FaBan,
  FaExclamationTriangle,
  FaHourglassEnd,
  FaInfoCircle,
  FaQuestionCircle
} from 'react-icons/fa';
import './css/TextGeneration.css'; // Ensure your CSS is correctly imported


const TextGeneration = ({ token, isAdmin }) => {
  const [data, setData] = useState([]);
  const [progressData, setProgressData] = useState(null);
  const [isFetchingProgress, setIsFetchingProgress] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [globalLoading, setGlobalLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [clientName, setClientName] = useState('');
  const [xmlLoading, setXmlLoading] = useState(false);
  const [isPaginating, setIsPaginating] = useState(false);
  const [totalItemsAll, setTotalItemsAll] = useState(0);
  const [selectedRows, setSelectedRows] = useState({});
  const [unselectedColumns, setUnselectedColumns] = useState([]);
  const [generationScope, setGenerationScope] = useState('selectedIds');
  const [isCanceling, setIsCanceling] = useState(false);
  const [pendingChanges, setPendingChanges] = useState({});
  const [selectedColumns, setSelectedColumns] = useState(JSON.parse(localStorage.getItem('selectedColumns')) || []);
  const [selectedColumnItems, setSelectedColumnItems] = useState([]);
  const [draggedColumnIndex, setDraggedColumnIndex] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});
  const [popupRowData, setPopupRowData] = useState(null); // State for popup row data
  const [activeProcesses, setActiveProcesses] = useState(0);
  const [fieldSelection, setFieldSelection] = useState([]); // Add this state for field selection
  const [selectedRowIds, setSelectedRowIds] = useState([]); // Add this state to track selected row IDs
  const qfeColumns = originalData.length > 0 ? Object.keys(originalData[0]).filter(key => key.startsWith('qfe_') && !key.endsWith('_state')) : [];
  const [processedCount, setProcessedCount] = useState(0);
  const hasFetchedInitialData = useRef(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [stateUpdateScope, setStateUpdateScope] = useState('selectedIds');
  const [popupFieldSelection, setPopupFieldSelection] = useState([]); // Add this state
  const [isProcessing, setIsProcessing] = useState(false);
  const [generatingRows, setGeneratingRows] = useState([]);
  const [disableEditing, setDisableEditing] = useState(false);
  const [selectedStateColumns, setSelectedStateColumns] = useState([]);
  const [isCaseSensitive, setIsCaseSensitive] = useState(true);
  const [savingCells, setSavingCells] = useState([]);
  const [promptConfigMessage, setPromptConfigMessage] = useState('');
  const isRunning = progressData?.status === 'running';

  const [promptConfigMessageType, setPromptConfigMessageType] = useState('');
  const resizeColumnRef = useRef(null);
  const [feedUrl, setFeedUrl] = useState('');
  const [cost, setCost] = useState(0);
  const [publishing, setPublishing] = useState(false);
  const [columnWidths, setColumnWidths] = useState({});
  const [popupSaving, setPopupSaving] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0); // Add this state
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [promptConfigurations, setPromptConfigurations] = useState([]);
  const [selectedPromptConfiguration, setSelectedPromptConfiguration] = useState('');
  const [filters, setFilters] = useState([{ key: '', operator: 'contains', value: '', logic: 'AND' }]);
  const [stateFieldSelection, setStateFieldSelection] = useState([]);
  const [stateUpdateChoice, setStateUpdateChoice] = useState('active');
  const [showFeedPopup, setShowFeedPopup] = useState(false);
  const [currentBalance, setCurrentBalance] = useState(0);
  const [orderBy, setOrderBy] = useState(""); // Default order by 'id'
  const [orderDirection, setOrderDirection] = useState('asc');  // Default order direction 'asc'
  const [replacePopupVisible, setReplacePopupVisible] = useState(false);
  const [replaceColumn, setReplaceColumn] = useState('');
  const [replaceOriginalValue, setReplaceOriginalValue] = useState('');
  const [replaceNewValue, setReplaceNewValue] = useState('');
  const [isProcessingReplace, setIsProcessingReplace] = useState(false);
  const [updatedRowCount, setUpdatedRowCount] = useState(0);
  const [columnConfigurations, setColumnConfigurations] = useState([]);
  const [selectedConfiguration, setSelectedConfiguration] = useState(null);
  const [showBulkActionsPopup, setShowBulkActionsPopup] = useState(false);
  const [bulkActionType, setBulkActionType] = useState('generate'); // 'generate' or 'state'


  const API_URL_SCHEDULER = process.env.REACT_APP_API_URL_SCHEDULER;

  const navigate = useNavigate();

  useEffect(() => {
    console.log('Selected Columns Updated:', selectedColumns);
    localStorage.setItem('selectedColumns', JSON.stringify(selectedColumns));
  }, [selectedColumns]);

  useEffect(() => {
    const savedColumns = JSON.parse(localStorage.getItem('selectedColumns')) || [];
    setSelectedColumns(savedColumns);
  }, []);

  useEffect(() => {
    const fetchConfigurations = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/column-configurations`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setColumnConfigurations(response.data);
      } catch (err) {
        console.error('Failed to fetch column configurations', err);
      }
    };
  
    fetchConfigurations();
  }, [token]);

  const fetchProgress = useCallback(async () => {
    if (!selectedClient) {
      setProgressData(null);
      return;
    }

    setIsFetchingProgress(true); // Optionally, set a loading state

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/get-progress`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { client_name: selectedClient },
      });
      setProgressData(response.data);
    } catch (error) {
      console.error('Error fetching progress data:', error);
      setProgressData(null);
    } finally {
      setIsFetchingProgress(false); // Reset the loading state
    }
  }, [selectedClient, token]);

  // Use fetchProgress inside useEffect for periodic fetching
  useEffect(() => {
    // Determine polling interval based on job status
    let intervalTime = 10000

    // Fetch progress immediately upon status change
    fetchProgress();

    // Set up the interval
    const intervalId = setInterval(fetchProgress, intervalTime);

    // Clean up the interval on unmount or when dependencies change
    return () => clearInterval(intervalId);
  }, [fetchProgress, progressData?.status, selectedClient]);

  

  const handleClientChange = (selectedOption) => {
    const newSelectedClient = selectedOption ? selectedOption.value : '';
  
    // If user picked a *different* feed than what is currently set, clear columns
    if (newSelectedClient && newSelectedClient !== selectedClient) {
      setSelectedColumns([]);                  // Clear out selected columns in state
      localStorage.removeItem('selectedColumns'); // Optional: remove from localStorage
    }
  
    setSelectedClient(newSelectedClient);
    hasFetchedInitialData.current = false; // Reset fetch state
    setSelectedRows({}); // Deselect all rows
    localStorage.setItem('selectedClient', newSelectedClient); // Save to localStorage
  
    if (!newSelectedClient) {
      setGlobalLoading(false); // Stop loading if client is cleared
    } else {
      setGlobalLoading(true); // Start loading if client is selected
    }
  };
  
  

  useEffect(() => {
    const savedClient = localStorage.getItem('selectedClient');
    if (savedClient) {
      setSelectedClient(savedClient);
    }
  }, []);

  useEffect(() => {
    const fetchConfigData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/config`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setClientName(response.data.client_name);
        
        const linkedClients = response.data.linked_clients || [];
        const clientOptions = linkedClients.map(client => ({
          value: client,
          label: client,
        }));
        setClients(clientOptions);
      } catch (err) {
        console.error('Failed to fetch configuration data', err);
      }
    };
  
    fetchConfigData();
  }, [token]);
  

  useEffect(() => {
    const fetchInitialDataIfNeeded = async () => {
      if (!hasFetchedInitialData.current && selectedClient) {
        await fetchInitialData(selectedClient, page, perPage, filters, orderBy, orderDirection, false);
        hasFetchedInitialData.current = true;
      }
    };
    fetchInitialDataIfNeeded();
  }, [selectedClient, page, perPage, filters, orderBy, orderDirection]);
  

  useEffect(() => {
    const fetchPromptConfigurations = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/client-data`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const clientData = response.data.find(client => client.client_name === selectedClient);
        setPromptConfigurations(clientData.prompt_combinations || []);
        setCurrentBalance(clientData.credit_balance || 0);  // Add this line to set the current balance
      } catch (err) {
        console.error('Failed to fetch prompt configurations', err);
      }
    };

    if (selectedClient) {
      fetchPromptConfigurations();
    }
  }, [selectedClient, token]);

  const handlePromptConfigurationChange = (value) => {
    setSelectedPromptConfiguration(value);
  };


  const handleConfigurationChange = (selectedOption) => {
    if (selectedOption) {
      const selectedConfig = columnConfigurations.find(config => config.id === selectedOption.value);
      if (selectedConfig) {
        setSelectedColumns(selectedConfig.selected_columns);
        setSelectedConfiguration(selectedOption);
      }
    } else {
      setSelectedConfiguration(null);
    }
  };
  
  const handleAddConfiguration = () => {
    const configName = prompt('Enter a name for this column configuration');
    if (configName) {
      saveColumnConfiguration(configName, selectedColumns);
    }
  };
  
  const saveColumnConfiguration = async (name, selectedColumns) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/column-configurations`, {
        name,
        selected_columns: selectedColumns
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setColumnConfigurations(prevConfigs => [...prevConfigs, { id: response.data.id, name, selected_columns: selectedColumns }]);
    } catch (err) {
      console.error('Failed to save column configuration', err);
    }
  };


  const handleCancelJob = async () => {
    if (!selectedClient) return;

    const confirmCancel = window.confirm('Are you sure you want to cancel the ongoing job?');
    if (!confirmCancel) return;

    setIsCanceling(true); // This sets the shared state

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/cancel-generation`,
        { client_name: selectedClient },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.status === 200) {
        setPromptConfigMessageType('success');
        setPromptConfigMessage('Job cancellation requested successfully.');
      } else {
        setPromptConfigMessageType('error');
        setPromptConfigMessage('Failed to cancel the job.');
      }
    } catch (error) {
      console.error('Error cancelling the job:', error);
      setPromptConfigMessageType('error');
      setPromptConfigMessage('An error occurred while trying to cancel the job.');
    } finally {
      // Optionally keep isCanceling = true until the job is actually 'cancelled' in Firestore
      // If you want the spinner to revert sooner, set isCanceling(false) here.
    }
  };
  
  const handleDeleteConfiguration = async () => {
    if (selectedConfiguration) {
      const confirmDelete = window.confirm('Are you sure you want to delete this configuration?');
      if (confirmDelete) {
        try {
          await axios.delete(`${process.env.REACT_APP_API_URL}/column-configurations/${selectedConfiguration.value}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setColumnConfigurations(prevConfigs => prevConfigs.filter(config => config.id !== selectedConfiguration.value));
          setSelectedConfiguration(null);
        } catch (err) {
          console.error('Failed to delete column configuration', err);
        }
      }
    }
  };

  const handleCancelCloseWarning = () => {
    setShowWarning(false);
  };
  
  
  
  const fetchInitialData = async (client, page, perPage, filters, orderBy = 'id', orderDirection = 'asc', isPagination = false) => {
    if (!client) return;
  
    if (isPagination) {
      setIsPaginating(true);
    } else {
      setGlobalLoading(true);
    }
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/load-data`, {
        client_name: client,
        page,
        per_page: perPage,
        filters,
        order_by: orderBy,
        order_direction: orderDirection
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const combinedData = JSON.parse(response.data.data);
      setData(combinedData);
      setOriginalData(combinedData);
      setTotalItems(response.data.total_items);
      setTotalItemsAll(response.data.total_items_all);

      // Calculate total pages
      const calculatedTotalPages = Math.ceil(response.data.total_items / perPage);
      setTotalPages(calculatedTotalPages);

      if (combinedData.length > 0) {
        const allColumns = Object.keys(combinedData[0]);
        const filteredUnselectedColumns = allColumns.filter(key =>
          !selectedColumns.includes(key) && !key.includes('_state')
        );
        setUnselectedColumns(filteredUnselectedColumns);
      }
    } catch (error) {
      console.error('There was an error fetching the data!', error);
    } finally {
      if (isPagination) {
        setIsPaginating(false);
      } else {
        setGlobalLoading(false);
      }
    }
  };

  useEffect(() => {
    if (originalData.length > 0) {
      const allColumns = Object.keys(originalData[0]);
      const filteredUnselectedColumns = allColumns.filter(key =>
        !selectedColumns.includes(key) && !key.includes('_state')
      );
      setUnselectedColumns(filteredUnselectedColumns);
    }
  }, [selectedColumns, originalData]);

  const fetchLatestData = async (client) => {
    if (!client) return;
    setXmlLoading(true);
    try {
      const response = await axios.get(`${API_URL_SCHEDULER}/refresh-text-data`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { client_name: client }
      });
      console.log('Fetch Latest Data response:', response.data);

      // Call fetchInitialData to update the frontend with the latest data
      await fetchInitialData(client, page, perPage, filters);
    } catch (error) {
      console.error('There was an error fetching the latest data!', error);
    } finally {
      setXmlLoading(false);
    }
  };

  const handleReplaceAction = () => {
    setReplacePopupVisible(true);
  };

  const handleReplaceConfirm = async () => {
    if (!replaceColumn || !replaceOriginalValue || !replaceNewValue) {
      return;
    }

    setIsProcessingReplace(true); // Start processing

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/replace-value`, {
        client_name: selectedClient,
        column: replaceColumn,
        original_value: replaceOriginalValue,
        new_value: replaceNewValue,
        case_sensitive: isCaseSensitive,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        setUpdatedRowCount(response.data.updated_count); // Set the updated row count
        // Refresh the data
        await fetchInitialData(selectedClient, page, perPage, filters, orderBy, orderDirection);
      } else {
        console.error('Error updating values:', response.data.message);
      }
    } catch (error) {
      console.error('There was an error processing the replacement:', error);
    } finally {
      setIsProcessingReplace(false); // End processing
    }
  };

  // Handle page change
  const handlePageChange = async (newPage) => {
    if (newPage < 1 || newPage > totalPages) {
      return;
    }
    setPage(newPage);
    setSelectedRows({}); // Deselect all rows
    await fetchInitialData(selectedClient, newPage, perPage, filters, orderBy, orderDirection, true);
  };
  

  const handlePerPageChange = (event) => {
    const newPerPage = parseInt(event.target.value, 10);
    setPerPage(newPerPage);
    setPage(1);
    setSelectedRows({}); // Deselect all rows
    fetchInitialData(selectedClient, 1, newPerPage, filters);
  };

  const handleFilterChange = (index, key, value) => {
    const newFilters = [...filters];
    newFilters[index][key] = value;
    setFilters(newFilters);
  };

  const handleAddFilter = () => {
    setFilters([...filters, { key: '', operator: 'contains', value: '', logic: 'AND' }]);
  };

  const handleRemoveFilter = (index) => {
    const newFilters = filters.filter((_, i) => i !== index);
    setFilters(newFilters);
  };

  const handleApplyFilter = () => {
    setSelectedRows({});
    setPage(1);
    fetchInitialData(selectedClient, 1, perPage, filters);
  };

  const handleOrderByChange = (event) => {
    setOrderBy(event.target.value);
    fetchInitialData(selectedClient, 1, perPage, filters, event.target.value, orderDirection);
  };

  const handleOrderDirectionChange = (event) => {
    setOrderDirection(event.target.value);
    fetchInitialData(selectedClient, 1, perPage, filters, orderBy, event.target.value);
  };

  const combinedColumns = [...new Set([...selectedColumns, ...unselectedColumns])];

  const sortedColumns = combinedColumns.sort((a, b) => {
    if (a.startsWith('qfe_') && !b.startsWith('qfe_')) {
      return 1;
    }
    if (!a.startsWith('qfe_') && b.startsWith('qfe_')) {
      return -1;
    }
    return a.localeCompare(b);
  });

  const handleDownloadAllData = async () => {
    if (selectedClient) {
      setIsDownloading(true); // Set isDownloading to true immediately
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/download-data`,
          {
            client_name: selectedClient,
            selected_columns: selectedColumns,
            filters,
            order_by: orderBy,
            order_direction: orderDirection,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
            responseType: 'blob',
          }
        );
  
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${selectedClient}_data.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error downloading the data:', error);
      } finally {
        setIsDownloading(false); // Set isDownloading back to false after download is complete
      }
    }
  };
  

  const handleResizeStart = (index, event) => {
    event.stopPropagation();
    document.body.classList.add('resizing');
    resizeColumnRef.current = { index, startX: event.clientX, startWidth: columnWidths[index] || 200 };
    document.addEventListener('mousemove', handleResizing);
    document.addEventListener('mouseup', handleResizeEnd);
  };

  const handleResizing = (event) => {
    event.preventDefault();
    const { index, startX, startWidth } = resizeColumnRef.current;
    const newWidth = Math.max(startWidth + (event.clientX - startX), 50);
    setColumnWidths((prevWidths) => ({
      ...prevWidths,
      [index]: newWidth,
    }));
  };

  const handleResizeEnd = () => {
    document.body.classList.remove('resizing');
    document.removeEventListener('mousemove', handleResizing);
    document.removeEventListener('mouseup', handleResizeEnd);
    resizeColumnRef.current = null;
  };

  const handleOpenFeedPopup = () => {
    setShowFeedPopup(true);
  };

  const handleCloseFeedPopup = () => {
    setShowFeedPopup(false);
  };

  const handleEdit = async (id, key, value) => {
    if (generatingRows.includes(id)) {
      return; // Prevent editing if row is being generated
    }

    const row = data.find(row => row.id === id);
    const originalValue = originalData.find(row => row.id === id)[key];
    if (originalValue === value || (!originalValue && !value)) {
      return; // Do nothing if value hasn't changed
    }

    // Update the frontend optimistically
    setData(prevData => prevData.map(row => row.id === id ? { ...row, [key]: value } : row));

    setSavingCells(prev => [...prev, { id, key }]);
    setSaving(true);

    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/qpe-data/${id}`,
        { [key]: value, client_name: selectedClient },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // Update the originalData to reflect the latest changes
      setOriginalData(prevData => prevData.map(row => row.id === id ? { ...row, [key]: value } : row));
    } catch (error) {
      console.error('There was an error updating the data!', error);

      // Roll back the change in case of an error
      setData(prevData => prevData.map(row => row.id === id ? { ...row, [key]: originalValue } : row));
    } finally {
      setSavingCells(prev => prev.filter(cell => cell.id !== id || cell.key !== key));
      if (savingCells.length === 1) setSaving(false); // Only set saving to false if there are no more cells being saved
    }
  };

  const GlobalLoadingIndicator = ({ loading }) => (
    loading ? (
      <div className="global-loading-overlay">
        <div className="global-loading-spinner">
          <ClipLoader size={50} color={"#848EE0"} loading={loading} />
        </div>
      </div>
    ) : null
  );

  const handleChange = (id, key, value) => {
    setPendingChanges(prev => ({
      ...prev,
      [id]: {
        ...prev[id],
        [key]: value,
      },
    }));

    setData(prevData => prevData.map(row =>
      row.id === id ? { ...row, [key]: value } : row
    ));
  };

  const handleBlur = async (id, key, value, fromPopup = false) => {
    if (fromPopup) return; // Skip blur event for the popup

    const originalValue = originalData.find(row => row.id === id)[key];
    if (originalValue !== value && !(originalValue === undefined && value === '')) {
      setSavingCells(prev => [...prev, { id, key }]); // Add the cell to saving state
      await handleEdit(id, key, value);
      setSavingCells(prev => prev.filter(cell => !(cell.id === id && cell.key === key))); // Remove the cell from saving state
    }
  };

  const getBackgroundColor = (state, selected, generating) => {
    if (generating) {
      return selected ? '#FFDDC1' : '#FFD4A1'; // Example colors for generating state
    }
    let color;
    switch (state) {
      case 'draft':
        color = selected ? '#d7d0b2' : '#fefbec';
        break;
      case 'active':
        color = selected ? '#c8dbcf' : '#E0EBE4';
        break;
      default:
        color = selected ? '#A9A9A9' : 'transparent';
    }
    return color;
  };

  
  const handleConfirmAndGenerate = async () => {
    if (currentBalance < cost) {
      setPromptConfigMessageType('error');
      setPromptConfigMessage('Not enough credits to generate items. Please purchase more tokens.');
      return;
    }

    setSaving(true); // Optionally, set a saving/loading state

    try {
      // Await the generation process
      await handleGenerate();

      // After successful generation, fetch the updated data
      await fetchInitialData(selectedClient, page, perPage, filters, orderBy, orderDirection);

      // Immediately fetch the latest progress data
      await fetchProgress();

      // Optionally, provide feedback to the user
      setPromptConfigMessageType('success');
      setPromptConfigMessage('QFE fields generated and progress updated successfully.');
    } catch (error) {
      // Handle any errors that might occur during generation or data fetching
      console.error('Error during generation or data fetching:', error);
      setPromptConfigMessageType('error');
      setPromptConfigMessage('An error occurred while generating data or refreshing the table.');
    } finally {
      setSaving(false); // Reset the saving/loading state
    }
  };


  useEffect(() => {
    let calculatedCost = 0;
    if (generationScope === 'selectedIds') {
      calculatedCost = selectedRowIds.length;
    } else if (generationScope === 'filteredIds') {
      calculatedCost = totalItems;
    } else if (generationScope === 'allIds') {
      calculatedCost = totalItemsAll;
    }
    setCost(calculatedCost);
  }, [generationScope, selectedRowIds, totalItems, totalItemsAll]);

  const handleRowSelection = (id) => {
    setSelectedRows((prevSelectedRows) => {
      const newSelectedRows = {
        ...prevSelectedRows,
        [id]: !prevSelectedRows[id],
      };
      const selectedIds = Object.keys(newSelectedRows).filter((id) => newSelectedRows[id]);
      const calculatedCost = selectedIds.length; // Assuming 1 credit per item

      setCost(calculatedCost);
      return newSelectedRows;
    });
  };

  const handleItemClick = (column, listType, event) => {
    if (event.shiftKey) {
      const items = listType === 'unselected' ? unselectedColumns : selectedColumns;
      const selectedColumnItemsCopy = [...selectedColumnItems];
      const lastSelectedIndex = items.findIndex(col => col === selectedColumnItemsCopy[selectedColumnItemsCopy.length - 1]);
      const currentIndex = items.findIndex(col => col === column);
      const [start, end] = [lastSelectedIndex, currentIndex].sort((a, b) => a - b);
      const newSelectedItems = items.slice(start, end + 1);

      newSelectedItems.forEach(item => {
        if (!selectedColumnItemsCopy.includes(item)) {
          selectedColumnItemsCopy.push(item);
        }
      });

      setSelectedColumnItems(selectedColumnItemsCopy);
    } else {
      setSelectedColumnItems(prevItems =>
        prevItems.includes(column) ? prevItems.filter(item => item !== column) : [...prevItems, column]
      );
    }
  };

  const moveToSelected = () => {
    const selectedCols = unselectedColumns.filter(col => selectedColumnItems.includes(col));
    setSelectedColumns([...selectedColumns, ...selectedCols]);
    setUnselectedColumns(unselectedColumns.filter(col => !selectedColumnItems.includes(col)));
    setSelectedColumnItems([]);
  };

  const moveToUnselected = () => {
    const selectedCols = selectedColumns.filter(col => selectedColumnItems.includes(col));
    setUnselectedColumns([...unselectedColumns, ...selectedCols]);
    setSelectedColumns(selectedColumns.filter(col => !selectedColumnItems.includes(col)));
    setSelectedColumnItems([]);
  };

  const onDragStart = (index) => {
    setDraggedColumnIndex(index);
  };

  const onDragOver = (index) => {
    if (draggedColumnIndex === null || draggedColumnIndex === index) {
      return;
    }

    const columns = [...selectedColumns];
    const draggedColumn = columns[draggedColumnIndex];
    columns.splice(draggedColumnIndex, 1);
    columns.splice(index, 0, draggedColumn);

    setDraggedColumnIndex(index);
    setSelectedColumns(columns);
  };

  const onDragEnd = () => {
    setDraggedColumnIndex(null);
  };

  const handlePopupChange = (id, key, value) => {
    setPopupRowData(prevData => ({
      ...prevData,
      [key]: value,
    }));
    setHasUnsavedChanges(true); // Mark as having unsaved changes
  };

  const handlePopupClose = () => {
    setPopupRowData(null); // Close the popup
    setHasUnsavedChanges(false); // Reset unsaved changes state
    setSaveSuccess(false); // Reset save success state
    setShowWarning(false); // Hide the warning overlay as well
  };
  



function handlePopupOverlayClick(e) {
  if (e.target === e.currentTarget) {
    if (hasUnsavedChanges) {
      setShowWarning(true);
    } else {
      handleCloseFeedPopup();
      handlePopupClose();
    }
  }
}


  const confirmClosePopup = () => {
    setPopupRowData(null); // Close the popup
    setHasUnsavedChanges(false); // Reset unsaved changes state
    setSaveSuccess(false); // Reset save success state
    setShowWarning(false); // Hide warning
  };

  const closePopup = () => {
    setPopupRowData(null); // Close the popup
    setHasUnsavedChanges(false); // Reset unsaved changes state
    setSaveSuccess(false); // Reset save success state
  };

  const handleToggleState = async (id, key) => {
    const stateKey = `${key}_state`;
    const currentState = popupRowData[stateKey];
    const newState = currentState === 'active' ? 'draft' : 'active';

    setActiveProcesses(prev => prev + 1); // Increment active process count
    setLoadingStates(prev => ({ ...prev, [`${id}_${stateKey}`]: true }));

    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/qpe-data/${id}`,
        { [stateKey]: newState, client_name: selectedClient },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setPopupRowData(prevData => ({
        ...prevData,
        [stateKey]: newState,
      }));

      setData(prevData => prevData.map(row =>
        row.id === id ? { ...row, [stateKey]: newState } : row
      ));
    } catch (error) {
      console.error('There was an error updating the state!', error);
    } finally {
      setLoadingStates(prev => ({ ...prev, [`${id}_${stateKey}`]: false }));
      setActiveProcesses(prev => prev - 1); // Decrement active process count
    }
  };

  const handleLogout = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/logout`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('Logout successful');
    } catch (err) {
      console.error('Error logging out', err);
    }
    localStorage.removeItem('token');
    navigate('/login');
  };

  const handlePopupFieldSelectionChange = (field) => {
    if (field === 'selectAll') {
      setPopupFieldSelection(qfeColumns); // Select all QFE columns
    } else if (field === 'deselectAll') {
      setPopupFieldSelection([]); // Deselect all fields
    } else {
      setPopupFieldSelection(prevSelection => {
        if (prevSelection.includes(field)) {
          return prevSelection.filter(item => item !== field);
        } else {
          return [...prevSelection, field];
        }
      });
    }
  };
  
  const handleBulkActionsOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      if (hasUnsavedChanges) {
        setShowWarning(true);
      } else {
        setShowBulkActionsPopup(false);
      }
    }
  };
  

  const handleGenerateItemInPopup = async () => {
    setActiveProcesses(prev => prev + 1); // Increment active process count
    setGenerating(true);
    setSaving(true);

    try {
      const requestData = {
        selected_ids: [popupRowData.id],
        selected_fields: popupFieldSelection, // Use selected fields from the popup
        client_name: selectedClient, // Send the already selected client
        prompt_configuration: selectedPromptConfiguration // Add selected prompt configuration
      };

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/generate-qfe-fields`, requestData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data.message === 'Not enough credits') {
        setPromptConfigMessageType('error');
        setPromptConfigMessage('Not enough credits to generate items. Please purchase more tokens.');
      } else {
        const result = response.data.results[0];
        const newCreditBalance = response.data.new_credit_balance;

        // Update the credit balance in the frontend state
        setCurrentBalance(newCreditBalance);

        if (result.status === 'success') {
          const updatedData = data.map(row =>
            row.id === result.id ? { ...row, ...result.data } : row
          );
          setData(updatedData);
          setOriginalData(updatedData);
          setPopupRowData(updatedData.find(row => row.id === popupRowData.id));
        } else {
          console.error(`Failed to generate QFE fields for ${result.id}`, result.message);
          setPromptConfigMessageType('error');
          setPromptConfigMessage(`Failed to generate QFE fields for ${result.id}`);
        }
      }
    } catch (err) {
      console.error('Failed to generate QFE fields', err);
      if (err.response && err.response.data && err.response.data.message) {
        setPromptConfigMessageType('error');
        setPromptConfigMessage(err.response.data.message);
      } else {
        setPromptConfigMessageType('error');
        setPromptConfigMessage('Failed to generate QFE fields');
      }
    } finally {
      setSaving(false);
      setGenerating(false);
      setActiveProcesses(prev => prev - 1); // Decrement active process count
    }
  };

  const handleStateFieldSelectionChange = (field) => {
    setStateFieldSelection(prevSelection => {
      if (prevSelection.includes(field)) {
        return prevSelection.filter(item => item !== field);
      } else {
        return [...prevSelection, field];
      }
    });
  };

  const handleStateUpdatePopup = () => {
    setBulkActionType('state'); // Default to the state tab
    setShowBulkActionsPopup(true);
  };

  const handleStateUpdateChoiceChange = (choice) => {
    setStateUpdateChoice(choice);
  };

  const handleStateUpdate = async () => {
    setSaving(true);
    setIsProcessing(true);

    let idsToProcess = [];
    if (stateUpdateScope === 'selectedIds') {
      idsToProcess = Object.keys(selectedRows).filter(id => selectedRows[id]);
    } else if (stateUpdateScope === 'filteredIds' || stateUpdateScope === 'allIds') {
      // For 'filteredIds' and 'allIds', we don't need to send IDs; the backend will handle it
      idsToProcess = [];
    }

    const updates = {
      ids: idsToProcess,
      fields: stateFieldSelection,
      client_name: selectedClient,
      state: stateUpdateChoice,
      update_scope: stateUpdateScope,
      filters: filters, // Include filters
    };

    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/qfe-update`, updates, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Update the front-end state directly based on scope
      if (stateUpdateScope === 'selectedIds') {
        setData(prevData =>
          prevData.map(row =>
            idsToProcess.includes(row.id)
              ? {
                  ...row,
                  ...Object.fromEntries(
                    stateFieldSelection.map(field => [
                      `${field}_state`,
                      stateUpdateChoice,
                    ])
                  ),
                }
              : row
          )
        );
      } else {
        // For filteredIds and allIds, you might want to refetch the data
        await fetchInitialData(selectedClient, page, perPage, filters);
      }

    } catch (error) {
      console.error(`Error updating fields to ${stateUpdateChoice}:`, error);
    } finally {
      setSaving(false);
      setIsProcessing(false);
    }
  };

  const selectAllFields = () => {
    setFieldSelection(qfeColumns);
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';

    // Check if timestamp is a Firestore Timestamp object
    if (timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000); // Convert Unix seconds to milliseconds
      return date.toLocaleString(); // Format the date in local string format
    }

    // If it's already a valid ISO string or Date object
    const date = new Date(timestamp);
    if (!isNaN(date.getTime())) {
      return date.toLocaleString(); // Format the date in local string format
    }

    return 'Invalid Date'; // Fallback for invalid timestamps
  };

  const deselectAllFields = () => {
    setFieldSelection([]);
  };

  const handleBulkActions = () => {
    const selectedIds = Object.keys(selectedRows).filter(id => selectedRows[id]);
    setSelectedRowIds(selectedIds);
    setBulkActionType('generate'); // Set default action type
    setShowBulkActionsPopup(true);
  };
  

  const handleGenerate = async () => {
    setGenerating(true);
    setDisableEditing(true);
    setSaving(true);
  
    let selectedIds = [];
    if (generationScope === 'selectedIds') {
      selectedIds = selectedRowIds;
    } else if (generationScope === 'filteredIds') {
      selectedIds = []; // Backend handles filtering based on `generation_scope`
    } else if (generationScope === 'allIds') {
      selectedIds = []; // Backend handles fetching all IDs based on `generation_scope`
    }
  
    try {
      const requestData = {
        generation_scope: generationScope,
        selected_ids: selectedIds, // Pass the selected IDs
        filters: filters,
        selected_fields: fieldSelection,
        client_name: selectedClient,
        prompt_configuration: selectedPromptConfiguration
      };
      
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/generate-qfe-fields`, requestData, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      if (response.data.message === 'Not enough credits') {
        setPromptConfigMessageType('error');
        setPromptConfigMessage('Not enough credits to generate items. Please purchase more tokens.');
      } else {
        const results = response.data.results;
        const newCreditBalance = response.data.new_credit_balance;
  
        // Update the credit balance in the frontend state
        setCurrentBalance(newCreditBalance);
  
        results.forEach(result => {
          if (result.status === 'success') {
            setData(prevData => prevData.map(row => row.id === result.id ? { ...row, ...result.data } : row));
            setProcessedCount(prev => prev + 1); // Update processed count
          }
        });
  
        setPromptConfigMessageType('success');
        setPromptConfigMessage('QFE fields generated successfully');
      }
    } catch (err) {
      console.error('Failed to generate QFE fields', err);
      if (err.response && err.response.data && err.response.data.message) {
        setPromptConfigMessageType('error');
        setPromptConfigMessage(err.response.data.message);
      } else {
        setPromptConfigMessageType('error');
        setPromptConfigMessage('Failed to generate QFE fields');
      }
    } finally {
      setSaving(false);
      setGenerating(false);
      setDisableEditing(false);
      setGeneratingRows([]);
      setFieldSelection([]);
      setGenerationScope('selectedIds'); // Reset to default if needed
    }
  };
  

  const handleSelectAllRows = () => {
    if (selectedRowCount === data.length) {
      setSelectedRows({});
    } else {
      const newSelectedRows = {};
      data.forEach(row => {
        newSelectedRows[row.id] = true;
      });
      setSelectedRows(newSelectedRows);
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minWidth: 300,
      fontSize: '12px',
      minWidth: 150, // Adjusted from 300
      maxWidth: 200, // Added max-width
      marginLeft: '20px',
      
      borderColor: state.isFocused ? '#4758EB' : '#ccc',
      boxShadow: state.isFocused ? '0 0 0 1px #4758EB' : 'none',
      '&:hover': {
        borderColor: '#4758EB',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? '#4758EB'
        : state.isFocused
        ? '#e0e0e0'
        : '#fff',
      color: state.isSelected ? '#fff' : '#333',
      '&:hover': {
        backgroundColor: '#e0e0e0',
        color: '#292B3D',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#999',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#333',
    }),
  };

  const handleFieldSelectionChange = (field) => {
    setFieldSelection(prevSelection => {
      if (prevSelection.includes(field)) {
        return prevSelection.filter(item => item !== field);
      } else {
        return [...prevSelection, field];
      }
    });
  };

  const handleSavePopupChanges = async () => {
    setActiveProcesses(prev => prev + 1); // Increment active process count
    setPopupSaving(true);
    const updates = {};

    for (let key of Object.keys(popupRowData)) {
      if (key.startsWith('qfe_')) {
        updates[key] = popupRowData[key];
      }
    }

    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/qpe-data/${popupRowData.id}`, { ...updates, client_name: selectedClient }, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setData(prevData => prevData.map(row =>
        row.id === popupRowData.id ? { ...row, ...updates } : row
      ));

      setOriginalData(prevOriginalData => prevOriginalData.map(row =>
        row.id === popupRowData.id ? { ...row, ...updates } : row
      ));

      setHasUnsavedChanges(false);
      setSaveSuccess(true);
    } catch (error) {
      console.error('There was an error updating the data!', error);
    } finally {
      setPopupSaving(false);
      setActiveProcesses(prev => prev - 1); // Decrement active process count
    }
  };

  const handlePublishSupplementaryFeed = async (includeItemGroupId, includeHighlightNumbers) => {
    if (!selectedClient) {
      setPromptConfigMessageType('error');
      setPromptConfigMessage('Client name is not defined.');
      throw new Error('Client name is not defined.');
    }
  
    setPublishing(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_SCHEDULER}/generate-supplementary-feed?client_name=${selectedClient}`,
        { 
          include_item_group_id: includeItemGroupId,
          include_highlight_numbers: includeHighlightNumbers,
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
  
      // Assuming the backend returns a feed_url on success
      const feedUrl = response.data.feed_url;
      setFeedUrl(feedUrl);
  
      setPromptConfigMessageType('success');
      setPromptConfigMessage('Supplementary feed published successfully');
    } catch (error) {
      console.error('Error generating supplementary feed:', error);
      setPromptConfigMessageType('error');
      setPromptConfigMessage('Error publishing supplementary feed');
      throw error; // Re-throw the error to be caught by PopupTextPublish
    } finally {
      setPublishing(false);
    }
  };
  
  

  const unselectedColumnsRegular = unselectedColumns.filter(col => !col.startsWith('qfe_'));
  const unselectedColumnsQfe = unselectedColumns.filter(col => col.startsWith('qfe_'));

  const selectedRowCount = Object.keys(selectedRows).filter(id => selectedRows[id]).length;

  return (
    <div id="app-container" className={publishing ? 'disabled' : ''}>
      <GlobalLoadingIndicator loading={globalLoading && !isPaginating} />

  
      <div className="top-bar">
        {/* Left Section */}
        <div className="left-section">
          <button onClick={() => navigate(isAdmin ? '/admin' : '/client')} className="back-button" title="Back" aria-label="Back">
            <FaArrowLeft size={20} />
          </button>
  
          <button onClick={handleLogout} className="logout-button" title="Logout" aria-label="Logout">
            <FaSignOutAlt size={20} />
          </button>
  
          <div className="header-text-container">
            <span className="feed-viewer-version">Quantum Feed Engine - Text Based Attributes</span>
            <span className="header-subtitle">Supercharge Your Text Attributes</span>
          </div>
  
          {clients.length > 0 && (
            <Select
              id="client-select"
              value={clients.find(option => option.value === selectedClient) || null}
              onChange={(selectedOption) => handleClientChange(selectedOption)}
              options={clients}
              className="client-select"
              classNamePrefix="react-select"
              placeholder="Select a feed"
              isClearable
              styles={customStyles}
            />
          )}
          {selectedClient && (
            <>
              <button
                onClick={() => fetchLatestData(selectedClient)}
                className="refresh-button"
                disabled={xmlLoading || !selectedClient}
                title="Refresh Data"
                aria-label="Refresh Data"
              >
                {xmlLoading ? <ClipLoader size={14} color={"#FFFFFF"} /> : <FaSyncAlt size={20} />}
              </button>
              <button className="config-button" onClick={() => ""} title="Scheduler" aria-label="Scheduler">
                <FaCalendarAlt size={20} />
              </button>
              <button className="PromptbuilderButton" onClick={() => navigate('/prompt-builder')} title="Prompt Builder" aria-label="Prompt Builder">
                <FaTools size={20} />
              </button>
              <div className="credit-info">
                <i className="fas fa-coins"></i>
                <span>{currentBalance} credits</span>
              </div>
            </>
          )}
        </div>
  
        {/* Right Section */}
        <div className="right-section">
          <img
            src="https://storage.googleapis.com/quantum-feed-engine/workbench/application-images/3_down.png"
            alt="Header Image"
            className="header-image"
          />
        </div>
      </div>

      {selectedClient ? (    
      <div className="main-content">

<div className="column-section">
  <div className="list-container">
    {/* Regular Columns List */}
    <div className="scrollable-list-container">
      <h3>Regular Columns</h3>
      <div className="scrollable-list">
        <ul>
          {unselectedColumnsRegular.map((col) => (
            <li
              key={col}
              className={selectedColumnItems.includes(col) ? 'selected' : ''}
              onClick={(event) => handleItemClick(col, 'unselected', event)}
              style={{ pointerEvents: savingCells.length > 0 ? 'none' : 'auto' }}
            >
              {col}
            </li>
          ))}
        </ul>
      </div>
    </div>

    {/* QFE Columns List */}
    <div className="scrollable-list-container">
      <h3>QFE Columns</h3>
      <div className="scrollable-list">
        <ul>
          {unselectedColumnsQfe.map((col) => (
            <li
              key={col}
              className={selectedColumnItems.includes(col) ? 'selected' : ''}
              onClick={(event) => handleItemClick(col, 'unselected', event)}
              style={{ pointerEvents: savingCells.length > 0 ? 'none' : 'auto' }}
            >
              {col}
              <img
                src="https://storage.googleapis.com/quantum-feed-engine/qfe_toolbox/media/s360logo.png"
                alt="AI Generated"
                className="ai-generated-icon"
              />
            </li>
          ))}
        </ul>
      </div>
    </div>

    {/* Buttons for Moving Columns */}
    <div className="button-group-column-selection">
      <button
        onClick={moveToSelected}
        disabled={disableEditing || savingCells.length > 0 || !selectedClient}
        title="Add Selected Columns"
      >
        <FaChevronRight size={20} />
      </button>
      <button
        onClick={moveToUnselected}
        disabled={disableEditing || savingCells.length > 0 || !selectedClient}
        title="Remove Selected Columns"
      >
        <FaChevronLeft size={20} />
      </button>
    </div>

    {/* Selected Columns List */}
    <div className="scrollable-list-container">
      <div className="selected-columns-header">
        <h3>Selected Columns</h3>
        <div className="column-configuration">
          <Select
            value={selectedConfiguration}
            onChange={(selectedOption) => handleConfigurationChange(selectedOption)}
            options={columnConfigurations.map(config => ({ value: config.id, label: config.name }))}
            className="configuration-select"
            classNamePrefix="react-select"
            placeholder="Select Configuration"
            isClearable
            styles={customStyles}
          />
          <button
            onClick={handleAddConfiguration}
            title="Add Configuration"
            className="addColConfigButton"
          >
            <i className="fas fa-plus"></i>
          </button>
          <button
            onClick={handleDeleteConfiguration}
            disabled={!selectedConfiguration}
            title="Delete Configuration"
            className="RmColConfigButton"
          >
            <i className="fas fa-trash"></i>
          </button>
        </div>
      </div>
      <div className="scrollable-list">
        <ul>
          {selectedColumns.map((col, index) => (
            <li
              key={col}
              className={selectedColumnItems.includes(col) ? 'selected' : ''}
              draggable
              onDragStart={() => onDragStart(index)}
              onDragOver={(e) => {
                e.preventDefault();
                onDragOver(index);
              }}
              onDrop={onDragEnd}
              onClick={(event) => handleItemClick(col, 'selected', event)}
              style={{ pointerEvents: savingCells.length > 0 ? 'none' : 'auto' }}
            >
              {col}
              {col.startsWith('qfe_') && (
                <img
                  src="https://storage.googleapis.com/quantum-feed-engine/qfe_toolbox/media/s360logo.png"
                  alt="AI Generated"
                  className="ai-generated-icon"
                />
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
</div>


<div className={`filter-section`}>
  <div className="filter-box">
    {filters.map((filter, index) => (
      <div key={index} className="filter-group">
        {index > 0 && (
          <select
            value={filter.logic}
            onChange={(e) => handleFilterChange(index, 'logic', e.target.value)}
          >
            <option value="AND">AND</option>
            <option value="OR">OR</option>
          </select>
        )}
        <select
          value={filter.key}
          onChange={(e) => handleFilterChange(index, 'key', e.target.value)}
        >
          <option value="">Select a column</option>
          {sortedColumns.map((col) => (
            <option key={col} value={col}>{col}</option>
          ))}
        </select>
        <select
          value={filter.operator}
          onChange={(e) => handleFilterChange(index, 'operator', e.target.value)}
        >
          <option value="equals">is equal to</option>
          <option value="not_equals">is not equal to</option>
          <option value="contains">contains</option>
          <option value="not_contains">doesn't contain</option>
          <option value="is_blank">is blank</option>
          <option value="is_not_blank">is not blank</option>
          <option value="in">is in (comma, space or NL)</option>
          <option value="not_in">is not in (comma, space or NL)</option>
          <option value="starts_with">starts with</option>
          <option value="not_starts_with">doesn't start with</option>
          <option value="ends_with">ends with</option>
          <option value="not_ends_with">doesn't end with</option>
          <option value="greater_than">is greater than</option>
          <option value="less_than">is less than</option>
          <option value="greater_or_equal">is greater or equal to</option>
          <option value="less_or_equal">is less or equal to</option>
          <option value="length_equals">length equals to</option>
          <option value="length_greater">length longer than</option>
          <option value="length_less">length shorter than</option>
          <option value="matches_regexp">matches regexp</option>
          <option value="not_matches_regexp">doesn't match regexp</option>
          {/* Add the following options */}
        </select>

        <input
          type="text"
          value={filter.value}
          onChange={(e) => handleFilterChange(index, 'value', e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleApplyFilter();
            }
          }}
        />
        <button onClick={() => handleRemoveFilter(index)} className="filter-remove-button">
          <i className="fas fa-times"></i>
        </button>
      </div>
    ))}
    <div className="filter-actions">
    <label>
  <select value={orderBy} onChange={handleOrderByChange} className="order-by-select">
    <option value="" disabled hidden>Order By...</option>
    <option value="id">id</option>
    <option value="title">Title</option>
    <option value="item_group_id">Item Group ID</option>
    <option value="description">Description</option>
    <option value="product_type">Product Type</option>
    <option value="brand">Brand</option>
    <option value="color">Color</option>
    <option value="gender">Gender</option>
    <option value="pattern">Pattern</option>
    <option value="material">Material</option>
    <option value="age_group">Age Group</option>
    <option value="size">Size</option>
    <option value="product_detail">Product Detail</option>
    <option value="google_product_category">Google Product Category</option>
    <option value="product_highlight1">Product Highlight 1</option>
    <option value="product_highlight2">Product Highlight 2</option>
    <option value="product_highlight3">Product Highlight 3</option>
    <option value="product_highlight4">Product Highlight 4</option>
    <option value="product_highlight5">Product Highlight 5</option>
    <option value="qfe_title">QFE Title</option>
    <option value="qfe_description">QFE Description</option>
    <option value="qfe_product_type">QFE Product Type</option>
    <option value="qfe_brand">QFE Brand</option>
    <option value="qfe_color">QFE Color</option>
    <option value="qfe_gender">QFE Gender</option>
    <option value="qfe_pattern">QFE Pattern</option>
    <option value="qfe_material">QFE Material</option>
    <option value="qfe_age_group">QFE Age Group</option>
    <option value="qfe_size">QFE Size</option>
    <option value="qfe_hex">QFE Hex</option>
    <option value="qfe_product_highlight1">QFE Product Highlight 1</option>
    <option value="qfe_product_highlight2">QFE Product Highlight 2</option>
    <option value="qfe_product_highlight3">QFE Product Highlight 3</option>
    <option value="qfe_product_highlight4">QFE Product Highlight 4</option>
    <option value="qfe_product_highlight5">QFE Product Highlight 5</option>
    <option value="other_feed_values">Other Feed Values</option>
    {/* Add other options as needed */}
  </select>
</label>

      <label>
        <select value={orderDirection} onChange={handleOrderDirectionChange} className="order-by-select">
          <option value="asc">Asc</option>
          <option value="desc">Desc</option>
        </select>
      </label>
      <button onClick={handleReplaceAction} className="back-button">
        <i className="fas fa-exchange-alt"></i>
      </button>
      <button onClick={handleAddFilter} className="filter-add-button">
        <i className="fas fa-plus"></i>
      </button>

      <button onClick={handleApplyFilter} className="filter-action-button">
      <i className="fas fa-play"></i>
        </button>
    </div>
  </div>
</div>


<div className="full-width-table-container"> 
          <div className="toolbox">
            <div className="button-group">
              <button 
                className="action-button"
                onClick={handleBulkActions} 
                disabled={!selectedClient} 
                title="Bulk Actions"
                aria-label="Bulk Actions"
              >
                Actions
              </button>
              <button 
                className="publish-button"
                onClick={handleOpenFeedPopup} 
                disabled={!selectedClient} 
                title="Export"
                aria-label="Export"
              >
                Export
              </button>
              <button
                className="download-button"
                onClick={handleDownloadAllData}
                disabled={isDownloading}
                title="Download Data"
                aria-label="Download Data"
              >
                {isDownloading ? <ClipLoader size={14} color="#FFFFFF" /> : <FaDownload />}
              </button>
            </div>

            {progressData ? (
              <>
                {progressData.status === 'running' && (
                    <div className="progress-info running-progress">
                      <ClipLoader size={20} color="#FFA500" loading={progressData.status === 'running'} />
                      <div className="progress-details">
                        <strong>Generating Attributes...</strong>
                        <div className="progress-status-container">
                          <div className="progress-bar-container">
                            <div
                              className="progress-bar"
                              style={{
                                width: `${
                                  progressData.total_items > 0
                                    ? (progressData.items_processed / progressData.total_items) * 100
                                    : 0
                                }%`,
                              }}
                              aria-valuenow={
                                progressData.total_items > 0
                                  ? (progressData.items_processed / progressData.total_items) * 100
                                  : 0
                              }
                              aria-valuemin="0"
                              aria-valuemax="100"
                              role="progressbar"
                            ></div>
                          </div>
                          <div className="progress-info-container">
                            <span className="progress-counter">
                              {progressData.items_processed} / {progressData.total_items}
                            </span>
                          </div>
                        </div>

                      </div>
                      <button
                        onClick={handleCancelJob}
                        className="cancel-button"
                        title="Cancel Job"
                        aria-label="Cancel Job"
                        disabled={isCanceling}
                      >
                        {isCanceling ? <ClipLoader size={10} color="#FFFFFF" /> : <FaTimes size={12} />}
                      </button>
                    </div>
                  )}

                  {progressData.status === 'completed' && (
                    <div className="progress-info completed-progress">
                      <FaCheckCircle className="status-icon" />
                      <div className="progress-details">
                        <strong>Completed</strong>
                        {progressData.message && <span>Message: {progressData.message}</span>}
                        {progressData.successful_generated_products !== undefined && (
                          <span>
                            Successfully generated {progressData.successful_generated_products} out of {progressData.total_items} items ({formatTimestamp(progressData.end_time)}).
                          </span>
                        )}
                      </div>
                    </div>
                  )}

                  {progressData.status === 'cancelled' && (
                    <div className="progress-info cancelled-progress">
                      <FaBan className="status-icon" />
                      <div className="progress-details">
                        <strong>Cancelled</strong>
                        {progressData.message && <span>{progressData.message} ({formatTimestamp(progressData.end_time)})</span>}
                      </div>
                    </div>
                  )}

                  {progressData.status === 'error' && (
                    <div className="progress-info error-progress">
                      <FaExclamationTriangle className="status-icon" />
                      <div className="progress-details">
                        <strong>Error</strong>
                        {progressData.message && <span>{progressData.message} ({formatTimestamp(progressData.end_time)})</span>}
                      </div>
                    </div>
                  )}

                  {progressData.status === 'timeout' && (
                    <div className="progress-info timeout-progress">
                      <FaHourglassEnd className="status-icon" />
                      <div className="progress-details">
                        <strong>Timeout</strong>
                        {progressData.message && <span>{progressData.message} ({formatTimestamp(progressData.end_time)})</span>}
                      </div>
                    </div>
                  )}

                  {progressData.status === 'no_job' && (
                    <div className="progress-info no-progress">
                      <FaInfoCircle className="status-icon" />
                      <span>No active job.</span>
                    </div>
                  )}

                  {/* Catch-all for any other unexpected statuses */}
                  {!['running', 'completed', 'cancelled', 'error', 'timeout', 'no_job'].includes(progressData.status) && (
                    <div className="progress-info unknown-progress">
                      <FaQuestionCircle className="status-icon" />
                      <div className="progress-details">
                        <strong>Job Status: {progressData.status}</strong>
                        {progressData.message && <span>{progressData.message} ({formatTimestamp(progressData.end_time)})</span>}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="progress-info no-progress">
                  <FaInfoCircle className="status-icon" />
                  <span>No job data.</span>
      </div>
    )}

</div>



  </div>


<div className="table-container full-width-table-container">
    {data.length > 0 && selectedColumns.length > 0 ? (
              <table className="full-width-table">
      <thead>
        <tr>
          <th style={{ width: '30px' }}></th> {/* Empty header cell for the "pencil" icon column */}
          <th style={{ width: '40px', textAlign: 'center' }}>
            <input
              type="checkbox"
              checked={selectedRowCount === data.length && data.length > 0}
              onChange={handleSelectAllRows}
              disabled={savingCells.length > 0 || data.length === 0}
            />
          </th>
          {selectedColumns.map((key, index) => (
            <th
              key={key}
              style={{
                position: 'relative', 
                width: columnWidths[index] || 200
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <span>{key}</span>
                {key.startsWith('qfe_') && (
                  <img
                    src="https://s3.eu-north-1.amazonaws.com/static.s360digital.com/s360-logo-white-1.svg"
                    alt="AI Generated"
                    className="ai-generated-icon"
                  />
                )}
              </div>
              <div
                className="resize-handle"
                onMouseDown={(e) => handleResizeStart(index, e)}
                style={{
                  width: '10px',
                  height: '100%',
                  cursor: 'col-resize',
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  zIndex: 9999,
                  backgroundColor: 'transparent'
                }}
              ></div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row) => (
          <tr key={row.id} style={{ backgroundColor: selectedRows[row.id] ? '#d3d3d3' : 'transparent' }}>
            <td style={{ width: '30px', textAlign: 'center' }}>
              <span className="icon-button" onClick={() => setPopupRowData(row)}>
                &#x270E; {/* Edit/Modify icon (pencil icon) */}
              </span>
            </td>
            <td style={{ width: '40px', textAlign: 'center' }}>
              <input
                type="checkbox"
                checked={!!selectedRows[row.id]}
                onChange={() => handleRowSelection(row.id)}
                disabled={savingCells.length > 0 || generatingRows.includes(row.id)}
              />
            </td>
            {selectedColumns.map((key, index) => {
              const stateKey = `${key}_state`;
              const backgroundColor = getBackgroundColor(row[stateKey], selectedRows[row.id], generatingRows.includes(row.id));
              const columnWidth = key === 'image_link' ? '60px' : columnWidths[index] || 200;

              return (
                <td
                  key={key}
                  style={{ backgroundColor, width: columnWidth, position: 'relative' }}
                  className={savingCells.some(cell => cell.id === row.id && cell.key === key) ? 'saving' : ''}
                >
                  {key === 'image_link' ? (
                    <img
                      src={row[key]}
                      alt="Image"
                      style={{ width: '100%', maxWidth: '60px', maxHeight: '50px' }}
                    />
                  ) : (
                    key.includes('qfe_') ? (
                      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <textarea
                          value={row[key]}
                          onChange={(e) => handleChange(row.id, key, e.target.value)}
                          onBlur={(e) => handleBlur(row.id, key, e.target.value)}
                          disabled={disableEditing || savingCells.some(cell => cell.id === row.id && cell.key === key) || generatingRows.includes(row.id)}
                          className="editable-textarea"
                          style={{
                            width: '100%', // Set textarea width to 100%
                            height: '100%',
                            resize: 'both', // Allow both horizontal and vertical resizing
                            boxSizing: 'border-box',
                          }}
                          onMouseDown={(e) => e.stopPropagation()} // Prevent triggering column resize
                        />
                        <div
                          style={{
                            width: '10px',
                            height: '100%',
                            cursor: 'col-resize',
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            backgroundColor: 'transparent',
                          }}
                          onMouseDown={(e) => handleResizeStart(index, e)}
                        />
                      </div>
                    ) : (
                      row[key]
                    )
                  )}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <p>No data available</p>
  )}
</div>

<div className="table-controls">
  <div className="pagination-container">
      <button 
      onClick={() => handlePageChange(page - 1)} 
      disabled={page <= 1 || isPaginating} 
      className="nav-button icon-button"
      title="Previous Page"
      aria-label="Previous Page"
    >
      {isPaginating ? <ClipLoader size={6} color="#FFFFFF" /> : <FaChevronLeft />}
    </button>
    
    <div className="pagination-inputs">
      <label className="pagination-label">
        Page:
        <input 
          type="number" 
          className="input-text page-input"
          value={page} 
          onChange={(e) => {
            const newPage = parseInt(e.target.value, 10);
            if (!isNaN(newPage) && newPage >= 1 && newPage <= totalPages) {
              handlePageChange(newPage);
            }
          }} 
          onBlur={(e) => {
            const newPage = parseInt(e.target.value, 10);
            if (!isNaN(newPage) && newPage >= 1 && newPage <= totalPages) {
              handlePageChange(newPage);
            } else {
              e.target.value = page; 
            }
          }} 
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              const newPage = parseInt(e.target.value, 10);
              if (!isNaN(newPage) && newPage >= 1 && newPage <= totalPages) {
                handlePageChange(newPage);
              } else {
                e.target.value = page; 
              }
            }
          }}
        />
        <span className="pagination-total">/ {totalPages} (Total: {totalItems})</span>
      </label>
      
      <label className="pagination-label">
        <select value={perPage} onChange={handlePerPageChange} className="input-select">
          <option value={10}>10</option>
          <option value={100}>100</option>
          <option value={500}>500</option>
          <option value={1000}>1000</option>
          <option value={2000}>2000</option>
          <option value={5000}>5000</option>
          <option value={10000}>10000</option>
        </select>
      </label>
    </div>

    <button 
      onClick={() => handlePageChange(page + 1)} 
      disabled={page >= totalPages || isPaginating} 
      className="nav-button icon-button"
      title="Next Page"
      aria-label="Next Page"
    >
      {isPaginating ? <ClipLoader size={6} color="#FFFFFF" /> : <FaChevronRight  />}
    </button>
  </div>
</div>



</div>
) : (
  <div className="no-client-selected">
    <h2>Please Select a Client</h2>
    <p>Choose a client from the dropdown above to view and manage feeds.</p>
  </div>
)}
     
     
            <PopupTextPublish
              showFeedPopup={showFeedPopup}
              handlePopupOverlayClick={handlePopupOverlayClick}
              handleCloseFeedPopup={handleCloseFeedPopup}
              handlePublishSupplementaryFeed={handlePublishSupplementaryFeed}
              disableEditing={disableEditing}
              publishing={publishing}
              savingCells={savingCells}
              selectedClient={selectedClient}
              token={token}
            />




          <PopupTextBulkActions
            showBulkActionsPopup={showBulkActionsPopup}
            handleBulkActionsOverlayClick={handleBulkActionsOverlayClick}
            bulkActionType={bulkActionType}
            setBulkActionType={setBulkActionType}
            qfeColumns={qfeColumns}
            fieldSelection={fieldSelection}
            handleFieldSelectionChange={handleFieldSelectionChange}
            selectAllFields={selectAllFields}
            deselectAllFields={deselectAllFields}
            generationScope={generationScope}
            setGenerationScope={setGenerationScope}
            selectedRowIds={selectedRowIds}
            totalItems={totalItems}
            totalItemsAll={totalItemsAll}
            promptConfigurations={promptConfigurations}
            selectedPromptConfiguration={selectedPromptConfiguration}
            handlePromptConfigurationChange={handlePromptConfigurationChange}
            handleConfirmAndGenerate={handleConfirmAndGenerate}
            currentBalance={currentBalance}
            cost={cost}
            stateFieldSelection={stateFieldSelection}
            handleStateFieldSelectionChange={handleStateFieldSelectionChange}
            stateUpdateChoice={stateUpdateChoice}
            setStateUpdateChoice={setStateUpdateChoice}
            stateUpdateScope={stateUpdateScope}
            setStateUpdateScope={setStateUpdateScope}
            selectedRowCount={selectedRowCount}
            isProcessing={isProcessing}
            handleStateUpdate={handleStateUpdate}
            setShowBulkActionsPopup={setShowBulkActionsPopup}
            setStateFieldSelection={setStateFieldSelection}
            jobStatus={progressData ? progressData.status : 'no_job'}
            jobProgress={progressData} // Added prop for detailed progress data
            handleCancelJob={handleCancelJob} // Add this prop
            isCanceling={isCanceling}
          />





          {popupRowData && (
            <PopupRowData
              popupRowData={popupRowData}
              qfeColumns={qfeColumns}
              originalData={originalData}
              savingCells={savingCells}
              generating={generating}
              popupSaving={popupSaving}
              activeProcesses={activeProcesses}
              hasUnsavedChanges={hasUnsavedChanges}
              saveSuccess={saveSuccess}
              showWarning={showWarning}
              popupFieldSelection={popupFieldSelection}
              selectedPromptConfiguration={selectedPromptConfiguration}
              promptConfigurations={promptConfigurations}
              loadingStates={loadingStates}
              handlePopupOverlayClick={handlePopupOverlayClick}
              handlePopupClose={handlePopupClose}
              handleSavePopupChanges={handleSavePopupChanges}
              handlePopupChange={handlePopupChange}
              handleBlur={handleBlur}
              handleToggleState={handleToggleState}
              handlePopupFieldSelectionChange={handlePopupFieldSelectionChange}
              handleGenerateItemInPopup={handleGenerateItemInPopup}
              setSelectedPromptConfiguration={setSelectedPromptConfiguration}
              handleCancelCloseWarning={handleCancelCloseWarning}
            />
          )}

          <PopupTextReplace
            replacePopupVisible={replacePopupVisible}
            setReplacePopupVisible={setReplacePopupVisible}
            replaceColumn={replaceColumn}
            setReplaceColumn={setReplaceColumn}
            replaceOriginalValue={replaceOriginalValue}
            setReplaceOriginalValue={setReplaceOriginalValue}
            replaceNewValue={replaceNewValue}
            setReplaceNewValue={setReplaceNewValue}
            isCaseSensitive={isCaseSensitive}
            setIsCaseSensitive={setIsCaseSensitive}
            updatedRowCount={updatedRowCount}
            isProcessingReplace={isProcessingReplace}
            handleReplaceConfirm={handleReplaceConfirm}
            qfeColumns={qfeColumns}
          />

        </div>
              
  );
};

export default TextGeneration;
