// src/popups/PopupUncropped.js

import React, { useState, useEffect, useRef } from 'react';
import { ClipLoader } from 'react-spinners';
import styles from './css/PopupUncropped.module.css';
import { MdInfoOutline, MdErrorOutline, MdCheckCircleOutline } from 'react-icons/md';
import { Stage, Layer, Image as KonvaImage, Rect } from 'react-konva';
import axios from 'axios';
import PropTypes from 'prop-types';

const PopupUncropped = ({
  isOpen,
  onClose,
  imageUrl,
  existingProcessedImageUrl,
  client,
  itemId,
  columnName,
  onUncropSuccess,
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // Stores error message
  const [naturalDimensions, setNaturalDimensions] = useState({ width: 0, height: 0 });
  const [newDimensions, setNewDimensions] = useState({ width: 0, height: 0 });
  const [imageObj, setImageObj] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [processingError, setProcessingError] = useState(null);
  const [afterImageUrl, setAfterImageUrl] = useState(existingProcessedImageUrl || null);

  const stageRef = useRef(null);
  const CANVAS_SIZE = 500; // Fixed canvas size for consistent display

  // Retrieve the API base URL from environment variables
  const API_URL_IMAGE = process.env.REACT_APP_API_URL_IMAGE;

  useEffect(() => {
    if (!isOpen) return;

    setAfterImageUrl(existingProcessedImageUrl || null);
    setLoading(true);
    setError(null); // Reset error state
    setProcessingError(null); // Reset processing error

    const img = new window.Image();
    const cleanImageUrl = imageUrl.split('?')[0];
    // img.crossOrigin = 'Anonymous';
    img.src = cleanImageUrl;

    console.log('Loading image URL:', cleanImageUrl);

    const handleLoad = () => {
      setLoading(false);
      setNaturalDimensions({ width: img.naturalWidth, height: img.naturalHeight });
      setNewDimensions({ width: img.naturalWidth, height: img.naturalHeight });
      setImageObj(img);
    };

    const handleError = (e) => {
      setLoading(false);
      setError('Failed to load the image. Please check the URL or try again later.');
      console.error('Image loading error:', e);
    };

    img.onload = handleLoad;
    img.onerror = handleError;

    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [imageUrl, isOpen, existingProcessedImageUrl]);

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleConfirm = async () => {
    setProcessing(true);
    setProcessingError(null);
    setAfterImageUrl(null);

    try {
      const token = localStorage.getItem('token');

      const response = await axios.post(
        `${API_URL_IMAGE}/uncrop-image`,
        {
          imageUrl: imageUrl,
          originalWidth: naturalDimensions.width,
          originalHeight: naturalDimensions.height,
          targetWidth: newDimensions.width,
          targetHeight: newDimensions.height,
          client: client,
          item_id: itemId,
          column_name: columnName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const { publicUrl } = response.data;
        setAfterImageUrl(publicUrl);
        onUncropSuccess && onUncropSuccess(response.data);
      } else {
        // Handle unexpected status codes
        setProcessingError('Unexpected response from the server. Please try again later.');
      }
    } catch (error) {
      console.error('Error during uncropping:', error);
      if (error.response && error.response.data && error.response.data.error) {
        // Backend provided an error message
        setProcessingError(error.response.data.error);
      } else if (error.request) {
        // Network error
        setProcessingError('Network error: Unable to reach the server. Please check your connection.');
      } else {
        // Other errors
        setProcessingError('An unexpected error occurred. Please try again.');
      }
    } finally {
      setProcessing(false);
    }
  };

  const handleDimensionChange = (e) => {
    const { name, value } = e.target;
    const intValue = parseInt(value, 10);
    if (!isNaN(intValue) && intValue > 0) {
      setNewDimensions((prev) => ({
        ...prev,
        [name]: intValue,
      }));
    }
  };

  if (!isOpen) return null;

  if (loading || error || !imageObj) {
    return (
      <div
        className={styles.popupOverlay}
        onClick={handleOverlayClick}
        role="dialog"
        aria-modal="true"
        aria-labelledby="popup-title"
      >
        <div
          className={styles.popupContent}
          onClick={(e) => e.stopPropagation()}
        >
          <header className={styles.popupHeader}>
            <h2 id="popup-title">Uncropping: Adjust Image Dimensions</h2>
            <button
              className={styles.closeButton}
              onClick={onClose}
              aria-label="Close"
            >
              &times;
            </button>
          </header>
          <div className={styles.imageContainer}>
            {loading && (
              <div className={styles.loaderContainer} aria-live="polite" aria-busy="true">
                <ClipLoader size={50} color="#4758eb" loading={loading} />
                <p>Loading image...</p>
              </div>
            )}
            {error && (
              <div className={styles.topErrorMessage} role="alert">
                <MdErrorOutline size={24} color="#eb4755" />
                <span>{error}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  // Calculations for canvas and image display
  const { width: originalWidth, height: originalHeight } = naturalDimensions;
  const { width: newWidth, height: newHeight } = newDimensions;

  const scaleFactor = Math.min(newWidth / originalWidth, newHeight / originalHeight);
  const scaledWidth = originalWidth * scaleFactor;
  const scaledHeight = originalHeight * scaleFactor;
  const deltaWidth = newWidth - scaledWidth;
  const deltaHeight = newHeight - scaledHeight;
  const imageX = deltaWidth / 2;
  const imageY = deltaHeight / 2;
  const displayScale = Math.min(CANVAS_SIZE / newWidth, CANVAS_SIZE / newHeight);
  const displayedWidth = newWidth * displayScale;
  const displayedHeight = newHeight * displayScale;
  const displayedImageX = imageX * displayScale;
  const displayedImageY = imageY * displayScale;
  const displayedScaledWidth = scaledWidth * displayScale;
  const displayedScaledHeight = scaledHeight * displayScale;

  return (
    <div
      className={styles.popupOverlay}
      onClick={handleOverlayClick}
      role="dialog"
      aria-modal="true"
      aria-labelledby="popup-title"
    >
      <div
        className={styles.popupContent}
        onClick={(e) => e.stopPropagation()}
      >
        <header className={styles.popupHeader}>
          <h2 id="popup-title">Uncropping: Adjust Image Dimensions</h2>
          <button
            className={styles.closeButton}
            onClick={onClose}
            aria-label="Close"
          >
            &times;
          </button>
        </header>

        {/* Top Error Message */}
        {error && (
          <div className={styles.topErrorMessage} role="alert">
            <MdErrorOutline size={24} color="#eb4755" />
            <span>{error}</span>
          </div>
        )}

        <div className={styles.contentWrapper}>
          {/* Original Image and Controls */}
          <section className={styles.originalImageSection}>
            <figure className={styles.imageFigure}>
              <h3>Original Image</h3>
              <div className={styles.canvasWrapper}>
                <Stage
                  ref={stageRef}
                  width={CANVAS_SIZE}
                  height={CANVAS_SIZE}
                  className={styles.canvasStage}
                >
                  <Layer>
                    {/* Background */}
                    <Rect
                      x={0}
                      y={0}
                      width={CANVAS_SIZE}
                      height={CANVAS_SIZE}
                      fill="#f9f9f9"
                    />
                    {/* Added Areas */}
                    {deltaWidth > 0 && (
                      <>
                        {/* Left */}
                        <Rect
                          x={(CANVAS_SIZE - displayedWidth) / 2}
                          y={(CANVAS_SIZE - displayedHeight) / 2}
                          width={displayedImageX}
                          height={displayedHeight}
                          fill="rgba(0, 128, 0, 0.3)"
                          dash={[10, 5]}
                        />
                        {/* Right */}
                        <Rect
                          x={(CANVAS_SIZE - displayedWidth) / 2 + displayedImageX + displayedScaledWidth}
                          y={(CANVAS_SIZE - displayedHeight) / 2}
                          width={displayedWidth - (displayedImageX + displayedScaledWidth)}
                          height={displayedHeight}
                          fill="rgba(0, 128, 0, 0.3)"
                          dash={[10, 5]}
                        />
                      </>
                    )}
                    {deltaHeight > 0 && (
                      <>
                        {/* Top */}
                        <Rect
                          x={(CANVAS_SIZE - displayedWidth) / 2}
                          y={(CANVAS_SIZE - displayedHeight) / 2}
                          width={displayedWidth}
                          height={displayedImageY}
                          fill="rgba(0, 128, 0, 0.3)"
                          dash={[10, 5]}
                        />
                        {/* Bottom */}
                        <Rect
                          x={(CANVAS_SIZE - displayedWidth) / 2}
                          y={(CANVAS_SIZE - displayedHeight) / 2 + displayedImageY + displayedScaledHeight}
                          width={displayedWidth}
                          height={displayedHeight - (displayedImageY + displayedScaledHeight)}
                          fill="rgba(0, 128, 0, 0.3)"
                          dash={[10, 5]}
                        />
                      </>
                    )}

                    {/* Cropped Areas */}
                    {deltaWidth < 0 && (
                      <>
                        {/* Left Crop */}
                        <Rect
                          x={(CANVAS_SIZE - displayedScaledWidth) / 2}
                          y={(CANVAS_SIZE - displayedScaledHeight) / 2}
                          width={(-deltaWidth / 2) * displayScale}
                          height={displayedScaledHeight}
                          fill="rgba(255, 0, 0, 0.3)"
                          dash={[10, 5]}
                        />
                        {/* Right Crop */}
                        <Rect
                          x={(CANVAS_SIZE + displayedScaledWidth) / 2 - (-deltaWidth / 2) * displayScale}
                          y={(CANVAS_SIZE - displayedScaledHeight) / 2}
                          width={(-deltaWidth / 2) * displayScale}
                          height={displayedScaledHeight}
                          fill="rgba(255, 0, 0, 0.3)"
                          dash={[10, 5]}
                        />
                      </>
                    )}
                    {deltaHeight < 0 && (
                      <>
                        {/* Top Crop */}
                        <Rect
                          x={(CANVAS_SIZE - displayedScaledWidth) / 2}
                          y={(CANVAS_SIZE - displayedScaledHeight) / 2}
                          width={displayedScaledWidth}
                          height={(-deltaHeight / 2) * displayScale}
                          fill="rgba(255, 0, 0, 0.3)"
                          dash={[10, 5]}
                        />
                        {/* Bottom Crop */}
                        <Rect
                          x={(CANVAS_SIZE - displayedScaledWidth) / 2}
                          y={(CANVAS_SIZE + displayedScaledHeight) / 2 - (-deltaHeight / 2) * displayScale}
                          width={displayedScaledWidth}
                          height={(-deltaHeight / 2) * displayScale}
                          fill="rgba(255, 0, 0, 0.3)"
                          dash={[10, 5]}
                        />
                      </>
                    )}

                    {/* Image */}
                    <KonvaImage
                      x={(CANVAS_SIZE - displayedScaledWidth) / 2}
                      y={(CANVAS_SIZE - displayedScaledHeight) / 2}
                      width={displayedScaledWidth}
                      height={displayedScaledHeight}
                      image={imageObj}
                    />
                  </Layer>
                </Stage>
              </div>
            </figure>
            {/* Dimension Inputs */}
            <div className={styles.controls}>
              <div className={styles.dimensionInput}>
                <label htmlFor="width">Target Width (px):</label>
                <input
                  type="number"
                  id="width"
                  name="width"
                  value={newDimensions.width}
                  onChange={handleDimensionChange}
                  min="1"
                  aria-describedby="original-width"
                />
                <small id="original-width" className={styles.originalDimension}>
                  Original: {originalWidth}px
                </small>
              </div>
              <div className={styles.dimensionInput}>
                <label htmlFor="height">Target Height (px):</label>
                <input
                  type="number"
                  id="height"
                  name="height"
                  value={newDimensions.height}
                  onChange={handleDimensionChange}
                  min="1"
                  aria-describedby="original-height"
                />
                <small id="original-height" className={styles.originalDimension}>
                  Original: {originalHeight}px
                </small>
              </div>
            </div>
            {/* Legend */}
            <div className={styles.legend}>
              <div className={styles.legendItem}>
                <span className={styles.addedArea}></span>
                <span>Uncropped/Added Area</span>
              </div>
            </div>
          </section>

          {/* Processed Image Section */}
          <section className={styles.processedImageSection}>
            <figure className={styles.imageFigure}>
              <h3>Processed Image</h3>
              <div className={styles.processedImageContainer}>
                {afterImageUrl ? (
                  <img
                    src={afterImageUrl}
                    alt="Processed"
                    className={styles.processedImage}
                  />
                ) : (
                  <img
                    src="https://storage.googleapis.com/quantum-feed-engine/workbench/error_image.png" // Fallback image
                    alt="Fallback"
                    className={styles.processedImage}
                  />
                )}
                {/* Processing Overlay */}
                {processing && (
                  <div className={styles.processingOverlay} aria-live="polite" aria-label="Processing Image">
                    <ClipLoader size={50} color="#4758eb" loading={processing} />
                    <p>Processing image...</p>
                  </div>
                )}
              </div>
              <figcaption className={styles.processedStatus}>
                {afterImageUrl ? (
                  <>
                    <MdCheckCircleOutline size={24} color="#28a745" aria-hidden="true" />
                    <span>Image has been processed successfully.</span>
                  </>
                ) : (
                  <>
                    <MdInfoOutline size={24} color="#ffc107" aria-hidden="true" />
                    <span>No processed image yet.</span>
                  </>
                )}
              </figcaption>
            </figure>
          </section>
        </div>

        {/* Action Buttons and Credit Info */}
        <footer className={styles.buttonFooter}>
          {/* Processing Error */}
          {processingError && (
            <div className={styles.bottomErrorMessage} role="alert">
              <MdErrorOutline size={24} color="#eb4755" />
              <span>{processingError}</span>
            </div>
          )}
          <div className={styles.buttonContainer}>
            <button
              className={styles.submitButton}
              onClick={handleConfirm}
              disabled={processing}
              aria-disabled={processing}
            >
              {processing ? 'Processing...' : 'Process Image'}
            </button>
            <span className={styles.creditInfo}>
              <MdInfoOutline size={16} />
              This action will cost <strong>100 credits</strong>.
            </span>
          </div>
        </footer>
      </div>
    </div>
  );
};

// Define PropTypes for better type checking
PopupUncropped.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  imageUrl: PropTypes.string.isRequired,
  existingProcessedImageUrl: PropTypes.string,
  client: PropTypes.string.isRequired,
  itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  columnName: PropTypes.string.isRequired,
  onUncropSuccess: PropTypes.func,
};

export default PopupUncropped;
