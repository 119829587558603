// src/components/CustomAttributesPages/CoreVariants/CreateProjectWizard/index.js

import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {
  FaCheckCircle,
  FaArrowLeft,
  FaArrowRight,
  FaSave,
  FaInfoCircle,
  FaExclamationTriangle,
  FaCheck,
  FaMeh,
} from 'react-icons/fa';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import Select from 'react-select';
import axios from 'axios';
import styles from './CreateProjectWizard.module.css';

const CreateProjectWizard = ({ isOpen, onClose, token, apiUrl, onProjectCreated }) => {
  // 1..6 steps
  const [currentStep, setCurrentStep] = useState(1);

  // STEP 1
  const [projectName, setProjectName] = useState('');
  const [market, setMarket] = useState('');
  const [projectNameStatus, setProjectNameStatus] = useState(null);
  const [projectNameLoading, setProjectNameLoading] = useState(false);

  // STEP 2: Merchant Center
  const [selectedMerchantCenter, setSelectedMerchantCenter] = useState(null);
  const [merchantCenterOptions, setMerchantCenterOptions] = useState([]);
  const [merchantCenterVerified, setMerchantCenterVerified] = useState(false);
  const [merchantCenterMetadata, setMerchantCenterMetadata] = useState(null);
  const [merchantCenterValidationError, setMerchantCenterValidationError] = useState(null);
  const [loadingMerchantCenters, setLoadingMerchantCenters] = useState(false);
  const [loadingMerchantCenterValidation, setLoadingMerchantCenterValidation] = useState(false);
  // New state: warning if MC is used in another project
  const [merchantCenterUsageWarning, setMerchantCenterUsageWarning] = useState(null);

  // STEP 3: Google Ads
  const [selectedGoogleAdsAccount, setSelectedGoogleAdsAccount] = useState(null);
  const [googleAdsOptions, setGoogleAdsOptions] = useState([]);
  const [googleAdsValidationResult, setGoogleAdsValidationResult] = useState(null);
  const [loadingGoogleAdsValidation, setLoadingGoogleAdsValidation] = useState(false);
  const [googleAdsValidationError, setGoogleAdsValidationError] = useState(null);
  // New state: warning if GA is used in another project
  const [googleAdsUsageWarning, setGoogleAdsUsageWarning] = useState(null);

  // STEP 4: Scheduler + Download period
  const [selectedStartTime, setSelectedStartTime] = useState(null);
  const [selectedManualInterval, setSelectedManualInterval] = useState(null);
  const [savingProject, setSavingProject] = useState(false);

  const downloadPeriodOptions = [
    { value: 7, label: '1 Week' },
    { value: 30, label: '1 Month' },
    { value: 90, label: '3 Months' },
    { value: 180, label: '6 Months' },
  ];
  const [selectedDownloadPeriod, setSelectedDownloadPeriod] = useState(downloadPeriodOptions[3]);

  // STEP 5: Core Variant Eligibility (fetched early, shown in Step 5)
  const [loadingEligibility, setLoadingEligibility] = useState(false);
  const [eligibilityResult, setEligibilityResult] = useState(null);
  const [eligibilityError, setEligibilityError] = useState(null);

  const modalRef = useRef(null);

  /**
   * Reset all wizard states to initial values
   */
  const resetWizard = () => {
    setCurrentStep(1);
    setProjectName('');
    setMarket('');
    setProjectNameStatus(null);
    setProjectNameLoading(false);

    setSelectedMerchantCenter(null);
    setMerchantCenterVerified(false);
    setMerchantCenterMetadata(null);
    setMerchantCenterValidationError(null);
    setLoadingMerchantCenters(false);
    setLoadingMerchantCenterValidation(false);
    setMerchantCenterUsageWarning(null);

    setSelectedGoogleAdsAccount(null);
    setGoogleAdsOptions([]);
    setGoogleAdsValidationResult(null);
    setLoadingGoogleAdsValidation(false);
    setGoogleAdsValidationError(null);
    setGoogleAdsUsageWarning(null);

    setSelectedStartTime(null);
    setSelectedManualInterval(null);
    setSavingProject(false);
    setSelectedDownloadPeriod(downloadPeriodOptions[3]);

    setLoadingEligibility(false);
    setEligibilityResult(null);
    setEligibilityError(null);
  };

  /**
   * Close wizard -> reset states
   */
  useEffect(() => {
    if (!isOpen) {
      resetWizard();
    }
  }, [isOpen]);

  // Computed name preview
  const computedPreview = `${projectName.trim().toLowerCase().replaceAll(' ', '_') || 'project'}_${market.trim().toLowerCase() || 'xx'}`;

  /**
   * Fetch Merchant Centers on open
   */
  useEffect(() => {
    if (isOpen && token) {
      const fetchMC = async () => {
        setLoadingMerchantCenters(true);
        try {
          const res = await axios.get(`${apiUrl}/fetch_merchant_center_names`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          const opts = res.data.map(item => ({
            value: item.merchantCenterId,
            label: `${item.accountName} (${item.merchantCenterId})`,
            accountName: item.accountName,
          }));
          setMerchantCenterOptions(opts);
        } catch (err) {
          console.error('Error fetching merchant center names:', err);
        }
        setLoadingMerchantCenters(false);
      };
      fetchMC();
    }
  }, [isOpen, token, apiUrl]);

  /**
   * Fetch Google Ads accounts on open
   */
  useEffect(() => {
    if (!isOpen || !token) return;
    const fetchGA = async () => {
      try {
        const res = await axios.get(`${apiUrl}/fetch_google_ads_accounts`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setGoogleAdsOptions(res.data);
      } catch (err) {
        console.error('Error fetching Google Ads accounts:', err);
      }
    };
    fetchGA();
  }, [apiUrl, token, isOpen]);

  /**
   * Debounced project name check
   */
  useEffect(() => {
    if (!projectName.trim() || market.trim().length !== 2) {
      setProjectNameStatus(null);
      return;
    }
    setProjectNameLoading(true);
    const timer = setTimeout(() => {
      axios
        .get(`${apiUrl}/check_project_name`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { projectName, market }
        })
        .then(res => {
          setProjectNameStatus(res.data);
          setProjectNameLoading(false);
        })
        .catch(err => {
          setProjectNameStatus({
            error: err.response ? err.response.data.error : err.message
          });
          setProjectNameLoading(false);
        });
    }, 500);

    return () => clearTimeout(timer);
  }, [projectName, market, apiUrl, token]);

  /**
   * Close wizard if clicking outside modal
   */
  const handleOverlayClick = (e) => {
    if (e.target.closest('.react-select__menu')) return;
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      const hasUnsaved = (
        projectName.trim() !== '' ||
        market.trim() !== '' ||
        selectedMerchantCenter ||
        selectedGoogleAdsAccount ||
        merchantCenterVerified
      );
      if (hasUnsaved) {
        const confirmExit = window.confirm(
          'Are you sure you want to quit the popup? Your changes will be lost.'
        );
        if (!confirmExit) return;
      }
      resetWizard();
      onClose();
    }
  };

  /**
   * Step 2: verify Merchant Center and check if it's already used
   */
  const handleVerifyMerchantCenter = async () => {
    if (!selectedMerchantCenter) return;
    setLoadingMerchantCenterValidation(true);
    setMerchantCenterValidationError(null);
    setMerchantCenterMetadata(null);
    setMerchantCenterUsageWarning(null);
    try {
      // Validate Merchant Center feed connection
      const res = await axios.get(`${apiUrl}/validate_feed`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { merchantCenterId: selectedMerchantCenter.value },
      });
      setMerchantCenterMetadata(res.data);
      setMerchantCenterVerified(true);

      // Check if the selected Merchant Center is already used in another project
      const usageRes = await axios.get(`${apiUrl}/check_merchant_center_usage`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { merchantCenterId: selectedMerchantCenter.value },
      });
      const usage = usageRes.data.usage; // expecting an array of { projectName, users }
      if (usage && usage.length > 0) {
        const usedIn = usage.map(u => u.projectName).join(', ');
        const userLists = usage.map(u => u.users.join(', ')).join(' | ');
        const message = `Merchant Center ${selectedMerchantCenter.value} is already used by project(s): ${usedIn}. Managed by user(s): ${userLists}`;
        setMerchantCenterUsageWarning(message);
      }
    } catch (err) {
      setMerchantCenterValidationError(err.response ? err.response.data.error : err.message);
      setMerchantCenterVerified(false);
    }
    setLoadingMerchantCenterValidation(false);
  };

  /**
   * Step 3: Validate Google Ads & check Core Variant Eligibility concurrently.
   * Also check if the selected Google Ads is already used.
   */
  const handleValidateGoogleAdsData = async () => {
    if (!selectedMerchantCenter || !selectedGoogleAdsAccount) return;
    setLoadingGoogleAdsValidation(true);
    setLoadingEligibility(true);
    setGoogleAdsValidationError(null);
    setEligibilityError(null);
    setGoogleAdsValidationResult(null);
    setEligibilityResult(null);
    setGoogleAdsUsageWarning(null);

    try {
      // Run both API calls concurrently
      const [gaRes, eligRes] = await Promise.allSettled([
        axios.get(`${apiUrl}/validate_google_ads`, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            merchantCenterId: selectedMerchantCenter.value,
            googleAdsAccountId: selectedGoogleAdsAccount.value,
          },
        }),
        axios.get(`${apiUrl}/check_core_variant_eligibility`, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            merchantCenterId: selectedMerchantCenter.value,
            googleAdsId: selectedGoogleAdsAccount.value,
            downloadPeriod: selectedDownloadPeriod?.value || 180,
          },
        }),
      ]);

      // Google Ads validation result
      if (gaRes.status === 'fulfilled') {
        setGoogleAdsValidationResult(gaRes.value.data);
      } else {
        setGoogleAdsValidationError(
          gaRes.reason.response?.data?.error || gaRes.reason.message
        );
      }

      // Eligibility result
      if (eligRes.status === 'fulfilled') {
        setEligibilityResult(eligRes.value.data);
      } else {
        setEligibilityError(
          eligRes.reason.response?.data?.error || eligRes.reason.message
        );
      }

      // Check if the selected Google Ads account is already used in another project
      if (gaRes.status === 'fulfilled') {
        const usageRes = await axios.get(`${apiUrl}/check_google_ads_usage`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { googleAdsId: selectedGoogleAdsAccount.value },
        });
        const usage = usageRes.data.usage;
        if (usage && usage.length > 0) {
          const usedIn = usage.map(u => u.projectName).join(', ');
          const userLists = usage.map(u => u.users.join(', ')).join(' | ');
          const message = `Google Ads account ${selectedGoogleAdsAccount.value} is already used by project(s): ${usedIn}. Managed by user(s): ${userLists}`;
          setGoogleAdsUsageWarning(message);
        }
      }
    } finally {
      setLoadingGoogleAdsValidation(false);
      setLoadingEligibility(false);
    }
  };

  /**
   * The custom react-select styles
   */
  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: '#E0E1EB',
      boxShadow: 'none',
      '&:hover': { borderColor: '#E0E1EB' },
      fontFamily: 'Lexend Deca, sans-serif',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? '#E0E1EB'
        : state.isSelected
        ? '#4758eb'
        : '#fff',
      color: state.isSelected ? '#fff' : '#292B3D',
      fontFamily: 'Lexend Deca, sans-serif',
      '&:hover': {
        backgroundColor: state.isSelected ? '#4758eb' : '#E0E1EB',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#292B3D',
      fontFamily: 'Lexend Deca',
    }),
    menu: (provided) => ({
      ...provided,
      fontFamily: 'Lexend Deca, sans-serif',
      zIndex: 1000,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 1000 }),
  };

  // Interval options
  const manualScheduleOptions = [
    { value: 1, label: 'Every 1 hour' },
    { value: 2, label: 'Every 2 hours' },
    { value: 4, label: 'Every 4 hours' },
    { value: 8, label: 'Every 8 hours' },
    { value: 12, label: 'Every 12 hours' },
    { value: 24, label: 'Every 24 hours' },
  ];

  // Time options (00:00, 00:15, 00:30, 00:45, 01:00, etc.)
  const combinedTimeOptions = [];
  for (let hour = 0; hour < 24; hour++) {
    const hh = hour.toString().padStart(2, '0');
    ['00', '15', '30', '45'].forEach(min => {
      combinedTimeOptions.push({
        value: `${hh}:${min}`,
        label: `${hh}:${min}`,
      });
    });
  }

  // For Step 3 display
  const formatGoogleAdsOptionLabel = (option) => {
    const indent = (option.level - 1) * 10;
    return <div style={{ paddingLeft: indent }}>{option.label}</div>;
  };

  /**
   * Helper for threshold-based messaging in Step 5
   */
  function getVariantIndicatorData(fraction) {
    const percentage = fraction * 100;
    if (percentage >= 50) {
      return {
        variantClass: 'variantBrilliant',
        label: 'Brilliant Fit for Core Variants!',
        icon: <FaCheck />,
        description: 'Over 50% of your item_group ids have more than 1 variant',
      };
    } else if (percentage >= 25) {
      return {
        variantClass: 'variantGood',
        label: 'Good Fit for Core Variants',
        icon: <FaCheck />,
        description: 'Over 25% of your item_group ids have more than 1 variant',
      };
    } else if (percentage >= 5) {
      return {
        variantClass: 'variantOk',
        label: 'Ok Fit for Core Variants',
        icon: <FaMeh />,
        description: '5-25% of your item_group ids have more than 1 variant',
      };
    } else {
      return {
        variantClass: 'variantBad',
        label: 'Bad Fit for Core Variants',
        icon: <FaExclamationTriangle />,
        description: 'Below 5% of your item_group ids have more than 1 variant',
      };
    }
  }

  /**
   * A small sub-component to render the color-coded message in Step 5
   */
  const VariantMessage = ({ fraction }) => {
    const { variantClass, label, icon, description } = getVariantIndicatorData(fraction);
    return (
      <div className={`${styles.variantMessage} ${styles[variantClass]}`}>
        <div className={styles.variantContent}>
          <div className={styles.variantTitle}>
            {icon}
            {label}
          </div>
          <div className={styles.variantDescription}>{description}</div>
        </div>
      </div>
    );
  };

  /**
   * Validate each step before proceeding.
   * For Step 3, we now also require that there is no Google Ads usage warning.
   */
  const isStepValid = () => {
    if (currentStep === 1) {
      return (
        projectName.trim() !== '' &&
        market.trim().length === 2 &&
        projectNameStatus &&
        projectNameStatus.exists === false
      );
    } else if (currentStep === 2) {
      return (
        selectedMerchantCenter &&
        merchantCenterVerified &&
        merchantCenterMetadata
      );
    } else if (currentStep === 3) {
      // Disable next if the GA account is already used (googleAdsUsageWarning exists)
      return (
        selectedGoogleAdsAccount &&
        googleAdsValidationResult &&
        googleAdsValidationResult.totalMatchedIds > 0 &&
        !googleAdsUsageWarning
      );
    } else if (currentStep === 4) {
      return (
        merchantCenterMetadata &&
        selectedManualInterval &&
        selectedStartTime &&
        selectedDownloadPeriod
      );
    } else if (currentStep === 5) {
      if (!loadingEligibility && eligibilityResult && !eligibilityError) {
        const fraction = eligibilityResult.fractionMultiVariant || 0;
        return fraction >= 0.00;
      }
      return false;
    }
    return true;
  };

  /**
   * Check overall validity for final save
   */
  const isAllValid = (
    projectName.trim() !== '' &&
    market.trim().length === 2 &&
    selectedMerchantCenter &&
    selectedGoogleAdsAccount &&
    merchantCenterMetadata &&
    selectedManualInterval &&
    selectedStartTime &&
    selectedDownloadPeriod &&
    projectNameStatus &&
    projectNameStatus.exists === false
  );

  const handleNextStep = () => {
    if (isStepValid()) {
      setCurrentStep(prev => Math.min(prev + 1, 6));
    }
  };
  const handlePrevStep = () => {
    setCurrentStep(prev => Math.max(prev - 1, 1));
  };

  /**
   * Final save
   */
  const handleSaveProject = async () => {
    if (!isAllValid) return;
    setSavingProject(true);

    const payload = {
      projectName: projectName.trim(),
      market: market.trim(),
      computedPreview: computedPreview,
      merchantCenter: selectedMerchantCenter,
      googleAds: selectedGoogleAdsAccount,
      schedule: {
        startTime: selectedStartTime.value,
        interval: selectedManualInterval.value,
      },
      downloadPeriod: selectedDownloadPeriod,
    };

    try {
      const res = await axios.post(`${apiUrl}/save_project`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // On success:
      alert(res.data.message);

      // 1) Construct the new { value, label } object 
      const newProj = {
        value: `${projectName.trim().toLowerCase().replaceAll(' ', '_')}_${market.trim().toLowerCase()}`,
        label: computedPreview, // or whatever label you prefer
      };

      // 2) Inform parent so it can add & select it in the dropdown
      if (onProjectCreated) {
        onProjectCreated(newProj);
      }

      // 3) Reset + close wizard
      resetWizard();
      onClose();
    } catch (err) {
      alert(`Error saving project: ${err.response?.data?.error || err.message}`);
    }
    setSavingProject(false);
  };

  /**
   * A small snippet for Step 3 to show how to pick GA accounts
   */
  const HierarchyExample = () => (
    <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px', color: '#292B3D', marginBottom: '10px' }}>
      <span style={{ fontWeight: '600', marginRight: '5px' }}>EXAMPLE:</span>
      <span style={{ marginRight: '5px' }}>
        Level 1: Manager &gt; Level 2: Sub-account &gt; Level n: n-account
      </span>
      <Tippy content="Select the matching Google Ads account. The dropdown contains Google Ads accounts we have access to." placement="top">
        <span className={styles.infoIcon}>
          <FaInfoCircle />
        </span>
      </Tippy>
    </div>
  );

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className={styles.wizardOverlay} onClick={handleOverlayClick}>
      <div className={styles.wizardModal} ref={modalRef}>

        {/* STEP INDICATOR */}
        <div className={styles.stepIndicator}>
          {[1, 2, 3, 4, 5, 6].map(step => (
            <div
              key={step}
              className={`${styles.stepNumber} ${
                currentStep === step ? styles.active : ''
              } ${currentStep > step ? styles.completed : ''}`}
            >
              {currentStep > step ? <FaCheckCircle /> : step}
            </div>
          ))}
        </div>

        {/* STEP CONTENT */}
        <div className={styles.stepContainer}>

          {/* STEP 1 */}
          {currentStep === 1 && (
            <div className={styles.step}>
              <h2>Step 1: Project Information</h2>
              <label>Project Name</label>
              <input
                type="text"
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                placeholder="e.g. Client Name"
              />
              <label>Market (2 chars)</label>
              <input
                type="text"
                maxLength={2}
                value={market}
                onChange={(e) => setMarket(e.target.value)}
                placeholder="e.g. DK"
              />
              <div className={styles.preview}>
                <strong>Project Name:</strong>{' '}
                {(projectName.trim() || market.trim())
                  ? computedPreview
                  : '(will appear here)'}
                {projectNameLoading && (
                  <span className={styles.spinner} style={{ marginLeft: '10px' }} />
                )}
              </div>

              {projectNameStatus && projectNameStatus.error && (
                <div className={styles.validationError}>
                  <FaInfoCircle style={{ marginRight: '5px' }} />
                  {projectNameStatus.error}
                </div>
              )}
              {projectNameStatus && projectNameStatus.exists === true && (
                <div className={styles.validationError}>
                  <FaInfoCircle style={{ marginRight: '5px' }} />
                  {projectNameStatus.message}
                </div>
              )}
              {projectNameStatus && projectNameStatus.exists === false && (
                <div className={`${styles.validationSummary} ${styles.successMessageStep1}`}>
                  <div className={styles.validationSuccess}>
                    <FaCheckCircle style={{ marginRight: '5px' }} />
                    {projectNameStatus.message}
                  </div>
                </div>
              )}
            </div>
          )}

          {/* STEP 2 */}
          {currentStep === 2 && (
            <div className={styles.step}>
              <h2>Step 2: Connect & Validate Merchant Center</h2>
              <label>Select Merchant Center Account</label>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <div style={{ flexGrow: 1 }}>
                  <Select
                    classNamePrefix="react-select"
                    options={merchantCenterOptions}
                    value={selectedMerchantCenter}
                    onChange={(option) => {
                      setSelectedMerchantCenter(option);
                      // Reset validation state so user must check connection again
                      setMerchantCenterVerified(false);
                      setMerchantCenterMetadata(null);
                      setMerchantCenterValidationError(null);
                      setMerchantCenterUsageWarning(null);
                    }}
                    placeholder="Choose an account"
                    isClearable
                    isDisabled={loadingMerchantCenters}
                    isLoading={loadingMerchantCenters}
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                    styles={customSelectStyles}
                  />

                </div>
                <button
                  className={styles.verifyBtn}
                  onClick={handleVerifyMerchantCenter}
                  disabled={!selectedMerchantCenter || loadingMerchantCenterValidation}
                >
                  {loadingMerchantCenterValidation ? (
                    <div className={styles.spinner} />
                  ) : (
                    <>
                      <FaCheckCircle style={{ marginRight: '5px' }} />
                      Check Connection
                    </>
                  )}
                </button>
              </div>

              {!loadingMerchantCenterValidation && merchantCenterVerified && merchantCenterMetadata && (
                <div className={styles.validationSummary}>
                  <div className={styles.validationSuccess}>
                    <FaCheckCircle style={{ marginRight: '5px' }} />
                    Merchant Center Connected
                  </div>
                  <div className={styles.validationMetrics}>
                    <p>
                      <strong>Feed Name:</strong> {merchantCenterMetadata.feedName}
                    </p>
                    <p>
                      <strong>Content Type:</strong> {merchantCenterMetadata.contentType}
                    </p>
                    <p>
                      <strong>Fetch URL:</strong>{' '}
                      {merchantCenterMetadata.fetchSchedule?.fetchUrl || 'N/A'}
                    </p>
                  </div>
                </div>
              )}

              {!loadingMerchantCenterValidation && merchantCenterValidationError && (
                <div className={styles.validationError}>
                  <FaInfoCircle style={{ marginRight: '5px' }} />
                  Error: {merchantCenterValidationError}
                </div>
              )}

              {merchantCenterUsageWarning && (
                <div className={styles.usageWarning}>
                  <FaExclamationTriangle style={{ marginRight: '5px' }} />
                  {merchantCenterUsageWarning}
                </div>
              )}
            </div>
          )}

          {/* STEP 3 */}
          {currentStep === 3 && (
            <div className={styles.step}>
              <h2>Step 3: Connect & Validate Google Ads</h2>
              {selectedMerchantCenter && (
                <p>
                  Select Google Ads Account Matching <strong>{selectedMerchantCenter.accountName}</strong>
                </p>
              )}
              {!selectedMerchantCenter && (
                <label>Select Google Ads Account</label>
              )}
              <HierarchyExample />
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <div style={{ flexGrow: 1 }}>
                  <Select
                    classNamePrefix="react-select"
                    options={googleAdsOptions}
                    value={selectedGoogleAdsAccount}
                    onChange={(option) => {
                      setSelectedGoogleAdsAccount(option);
                      // Reset validation state so user must check connection again
                      setGoogleAdsValidationResult(null);
                      setGoogleAdsValidationError(null);
                      setGoogleAdsUsageWarning(null);
                    }}
                    placeholder="Choose an account"
                    isClearable
                    styles={customSelectStyles}
                    menuPortalTarget={document.body}
                    formatOptionLabel={formatGoogleAdsOptionLabel}
                  />

                </div>
                <button
                  className={styles.verifyBtn}
                  onClick={handleValidateGoogleAdsData}
                  disabled={
                    !selectedMerchantCenter ||
                    !selectedGoogleAdsAccount ||
                    loadingGoogleAdsValidation ||
                    loadingEligibility
                  }
                >
                  {loadingGoogleAdsValidation || loadingEligibility ? (
                    <div className={styles.spinner} />
                  ) : (
                    <>
                      <FaCheckCircle style={{ marginRight: '5px' }} />
                      Check Connection
                    </>
                  )}
                </button>
              </div>

              {/* Google Ads validation result */}
              {!loadingGoogleAdsValidation && googleAdsValidationResult && (
                googleAdsValidationResult.totalMatchedIds > 0 ? (
                  <div className={styles.validationSummary}>
                    <div className={styles.validationSuccess}>
                      <FaCheckCircle style={{ marginRight: '5px' }} />
                      Google Ads Connected
                    </div>
                    <div className={styles.validationMetrics}>
                      <p style={{ fontSize: '12px', color: '#6c757d', marginTop: '5px' }}>
                        Comparing selected Merchant Center and Google Ads accounts.
                      </p>
                      <p style={{ margin: '5px 0' }}>
                        <strong>Matched IDs:</strong> #{googleAdsValidationResult.totalMatchedIds}
                        <span className={styles.metricSubtext}>
                          (Merchant: #{googleAdsValidationResult.totalMerchantCenterIds} / Google Ads: #{googleAdsValidationResult.totalGoogleAdsIds})
                        </span>
                      </p>
                      <p style={{ margin: '5px 0', display: 'flex', alignItems: 'center' }}>
                        <strong>Matched Markets:</strong>{' '}#{googleAdsValidationResult.totalMatchedMarkets}
                        <span className={styles.metricSubtext}>
                          (Merchant: {googleAdsValidationResult.merchantCenterMarkets.join(', ')} / Google Ads: {googleAdsValidationResult.googleAdsMarkets.join(', ')})
                        </span>
                        {googleAdsValidationResult.totalMatchedMarkets === 0 && (
                          <Tippy content="No matching markets found" placement="top">
                            <span style={{ color: '#f39c12', marginLeft: '8px', fontSize: '16px' }}>
                              <FaExclamationTriangle />
                            </span>
                          </Tippy>
                        )}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className={styles.validationError}>
                    <FaInfoCircle style={{ marginRight: '5px' }} />
                    No matching IDs found between the selected Merchant Center and Google Ads accounts.
                  </div>
                )
              )}

              {!loadingGoogleAdsValidation && googleAdsValidationError && (
                <div className={styles.validationError}>
                  <FaInfoCircle style={{ marginRight: '5px' }} />
                  Google Ads validation failed: {googleAdsValidationError}
                </div>
              )}

              {googleAdsUsageWarning && (
                <div className={styles.usageWarning}>
                  <FaExclamationTriangle style={{ marginRight: '5px' }} />
                  {googleAdsUsageWarning}
                </div>
              )}
            </div>
          )}

          {/* STEP 4 */}
          {currentStep === 4 && (
            <div className={styles.step}>
              <h2>Step 4: Schedule & Backfill Settings</h2>
              <div className={styles.twoColumnContainer}>
                {/* Left Column */}
                <div className={styles.schedulerSettings}>
                  <h3 className={styles.sectionTitle}>Project Update Schedule</h3>
                  <div className={styles.customField}>
                    <label>
                      Start Time (CPH){' '}
                      <Tippy content="Select the starting time (hour and minute) in CPH time." placement="top">
                        <span className={styles.infoIcon}>
                          <FaInfoCircle />
                        </span>
                      </Tippy>
                    </label>
                    <Select
                      classNamePrefix="react-select"
                      options={combinedTimeOptions}
                      value={selectedStartTime}
                      onChange={setSelectedStartTime}
                      placeholder="Select start time"
                      isClearable
                      menuPortalTarget={document.body}
                      menuPosition="fixed"
                      styles={customSelectStyles}
                    />
                  </div>
                  <div className={styles.customField}>
                    <label>
                      Interval (hours){' '}
                      <Tippy content="Select how frequently the feed should be fetched." placement="top">
                        <span className={styles.infoIcon}>
                          <FaInfoCircle />
                        </span>
                      </Tippy>
                    </label>
                    <Select
                      classNamePrefix="react-select"
                      options={manualScheduleOptions}
                      value={selectedManualInterval}
                      onChange={setSelectedManualInterval}
                      placeholder="Select interval"
                      isClearable
                      menuPortalTarget={document.body}
                      menuPosition="fixed"
                      styles={customSelectStyles}
                    />
                  </div>
                  {merchantCenterMetadata?.fetchSchedule && (
                    <div className={styles.referenceSchedule}>
                      <small>
                        MC’s Current Schedule: {merchantCenterMetadata.fetchSchedule.hour}h,{' '}
                        {merchantCenterMetadata.fetchSchedule.timeZone}
                      </small>
                    </div>
                  )}
                </div>

                {/* Right Column */}
                <div className={styles.backfillSettings}>
                  <h3 className={styles.sectionTitle}>Google Ads Backfill Settings</h3>
                  <label>
                    Google Ads Data Period{' '}
                    <Tippy content="Select how much data history Core Variants should be based on" placement="top">
                      <span className={styles.infoIcon}>
                        <FaInfoCircle />
                      </span>
                    </Tippy>
                  </label>
                  <Select
                    classNamePrefix="react-select"
                    options={downloadPeriodOptions}
                    value={selectedDownloadPeriod}
                    onChange={setSelectedDownloadPeriod}
                    placeholder="Select download period"
                    isClearable
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                    styles={customSelectStyles}
                  />
                </div>
              </div>
            </div>
          )}

          {currentStep === 5 && (
            <div className={`${styles.step} ${styles.step5Compact}`}>
              <h2>Step 5: Check Core Variant Eligibility</h2>

              {loadingEligibility && (
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <div className={styles.spinner} />
                  <span style={{ fontSize: '14px', color: '#6c757d' }}>
                    Checking if the client is eligible for Core Variants...
                  </span>
                </div>
              )}

              {!loadingEligibility && !eligibilityError && eligibilityResult && (
                <>
                  {/* Basic summary */}
                  <div className={styles.validationSummary}>
                    <div className={styles.validationMetrics}>
                      <p>
                        <strong>Total variant IDs (MC):</strong> {eligibilityResult.totalVariantIds}
                      </p>
                      <p>With item_group_id: {eligibilityResult.variantIdsWithItemGroupId}</p>
                      <p>Without item_group_id: {eligibilityResult.variantIdsWithoutItemGroupId}</p>
                    </div>
                  </div>

                  <p style={{ marginTop: '10px', marginBottom: '8px' }}>
                    Distribution of variants per <code>item_group_id</code>.<br />
                    <em style={{ color: '#4758eb' }}>The more variants per group, the better!</em>
                  </p>

                  {/* The 3-column grid container */}
                  <div className={styles.distributionGrid}>
                    {/* HEADER ROW (optional) */}
                    <div className={styles.distributionGridHeader} />
                    <div className={styles.distributionGridHeader}>Item Groups</div>
                    <div className={styles.distributionGridHeader}>Conversions</div>

                    {(() => {
                      // 1) Clone your distribution data
                      const distributionData = { ...eligibilityResult.distribution };
                      const distributionConvData = { ...eligibilityResult.distributionByConversions };

                      // 2) Insert "noIG" if relevant
                      if (eligibilityResult.variantIdsWithoutItemGroupId > 0) {
                        distributionData["noIG"] = eligibilityResult.variantIdsWithoutItemGroupId;
                        distributionConvData["noIG"] = distributionConvData["noIG"] || 0;
                      }

                      // 3) Aggregate >= 10 as "10+"
                      const aggregatedDist = {};
                      const aggregatedDistConv = {};

                      const bucketize = (obj, key, val) => {
                        obj[key] = (obj[key] || 0) + val;
                      };

                      for (const [countStr, groupCount] of Object.entries(distributionData)) {
                        if (countStr === 'noIG') {
                          bucketize(aggregatedDist, 'noIG', groupCount);
                          continue;
                        }
                        const numVal = parseInt(countStr, 10) || 0;
                        if (numVal >= 10) {
                          bucketize(aggregatedDist, '10+', groupCount);
                        } else {
                          bucketize(aggregatedDist, String(numVal), groupCount);
                        }
                      }

                      for (const [countStr, conv] of Object.entries(distributionConvData)) {
                        if (countStr === 'noIG') {
                          bucketize(aggregatedDistConv, 'noIG', conv);
                          continue;
                        }
                        const numVal = parseInt(countStr, 10) || 0;
                        if (numVal >= 10) {
                          bucketize(aggregatedDistConv, '10+', conv);
                        } else {
                          bucketize(aggregatedDistConv, String(numVal), conv);
                        }
                      }

                      // 4) Build final array & sort it
                      const rows = Object.keys(aggregatedDist).map(key => ({
                        variantCount: key,
                        groupCount: aggregatedDist[key],
                        conversions: aggregatedDistConv[key] || 0,
                      }));

                      rows.sort((a, b) => {
                        if (a.variantCount === 'noIG') return -1;
                        if (b.variantCount === 'noIG') return 1;
                        if (a.variantCount === '10+') return 1;
                        if (b.variantCount === '10+') return -1;
                        return parseInt(a.variantCount, 10) - parseInt(b.variantCount, 10);
                      });

                      const totalGroups = rows.reduce((acc, r) => acc + r.groupCount, 0) || 1;
                      const totalConversions = rows.reduce((acc, r) => acc + r.conversions, 0) || 1;

                      // 5) Bar color logic
                      const getBarColor = (countValue) => {
                        if (countValue === 'noIG') return '#bdc3c7'; // grey
                        if (countValue === '10+') return '#2ecc71';
                        const val = parseInt(countValue, 10);
                        if (val === 1) return '#e74c3c'; // red
                        if (val >= 2 && val <= 3) return '#f1c40f'; // yellow
                        return '#2ecc71'; // green
                      };

                      // 6) Generate left‐column label
                      const getVariantLabel = (countValue) => {
                        if (countValue === 'noIG')  return 'No item_group_id';
                        if (countValue === '10+')   return '10 or more vars';
                        return `${countValue} var(s)`;
                      };

                      // 7) Render each row
                      return rows.map((row) => {
                        const groupPct = (row.groupCount / totalGroups) * 100;
                        const convPct  = (row.conversions / totalConversions) * 100;
                        const color    = getBarColor(row.variantCount);
                        
                        return (
                          <React.Fragment key={row.variantCount}>
                            {/* Left column: just "1 var(s)" or "no item_group_id" */}
                            <div className={styles.distributionLabelCell}>
                              {getVariantLabel(row.variantCount)}
                            </div>

                            {/* Middle column: Item Groups bar + “(# row.groupCount)” */}
                            <div className={styles.distributionBarCell}>
                              <div className={styles.distributionBarBackground}>
                                <div
                                  className={styles.distributionBarFill}
                                  style={{ width: `${groupPct}%`, backgroundColor: color }}
                                />
                              </div>
                              <span className={styles.distributionPercentage}>
                                {groupPct.toFixed(1)}% of item groups (#{row.groupCount})
                              </span>
                            </div>

                            {/* Right column: Conversions bar + “(# row.conversions)” */}
                            <div className={styles.distributionBarCell}>
                              <div className={styles.distributionBarBackground}>
                                <div
                                  className={styles.distributionBarFill}
                                  style={{ width: `${convPct}%`, backgroundColor: color }}
                                />
                              </div>
                              <span className={styles.distributionPercentage}>
                                {convPct.toFixed(1)}% of conversions (#{row.conversions.toFixed(0)})
                              </span>
                            </div>
                          </React.Fragment>
                        );
                      });
                    })()}
                  </div>

                  {/* Keep the existing "VariantMessage" for overall fraction */}
                  <VariantMessage fraction={eligibilityResult.fractionMultiVariant} />
                </>
              )}

              {!loadingEligibility && eligibilityError && (
                <div className={styles.validationError}>
                  <FaInfoCircle style={{ marginRight: '5px' }} />
                  {eligibilityError}
                </div>
              )}
            </div>
          )}



          {/* STEP 6 */}
          {currentStep === 6 && (
            <div className={styles.step}>
              <h2>Step 6: Review & Save</h2>
              <div className={styles.reviewCard}>
                <div className={styles.reviewItem}>
                  <strong>Project Name (client):</strong>
                  <span>{computedPreview || 'N/A'}</span>
                </div>
                <div className={styles.reviewItem}>
                  <strong>Merchant Center:</strong>
                  <span>{selectedMerchantCenter?.label || 'Not selected'}</span>
                </div>
                <div className={styles.reviewItem}>
                  <strong>Google Ads:</strong>
                  <span>{selectedGoogleAdsAccount?.label || 'Not selected'}</span>
                </div>
                <div className={styles.reviewItem}>
                  <strong>Data Download Period:</strong>
                  <span>{selectedDownloadPeriod?.label || 'Not selected'}</span>
                </div>
                <div className={styles.reviewItem}>
                  <strong>Schedule:</strong>
                  <span>
                    {selectedManualInterval && selectedStartTime
                      ? `Start time: ${selectedStartTime.value}, Run every ${selectedManualInterval.value} hours`
                      : 'Not fully selected'}
                  </span>
                </div>
              </div>
            </div>
          )}

        </div>

        {/* FOOTER NAV */}
        <div className={styles.wizardFooter}>
          {currentStep > 1 && (
            <button
              className={`${styles.buttonBase} ${styles.backButton}`}
              onClick={handlePrevStep}
            >
              <FaArrowLeft />
            </button>
          )}
          {currentStep < 6 && (
            <button
              className={`${styles.buttonBase} ${styles.nextButton}`}
              onClick={handleNextStep}
              disabled={!isStepValid()}
            >
              <FaArrowRight />
            </button>
          )}
          {currentStep === 6 && (
            <button
              className={`${styles.buttonBase} ${styles.saveButton}`}
              onClick={handleSaveProject}
              disabled={!isAllValid || savingProject}
            >
              {savingProject ? <div className={styles.spinner} /> : <FaSave />}
            </button>
          )}
        </div>

      </div>
    </div>,
    document.body
  );
};

export default CreateProjectWizard;
