import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaTimes } from 'react-icons/fa';
import Select from 'react-select';
import ClipLoader from 'react-spinners/ClipLoader';
import styles from './css/PopupCreatePromptCombinationPdp.module.css'; // re-use existing popup styles

const API_URL_PDP = process.env.REACT_APP_API_URL_PDP;

const PopupImportPromptCombinationPdp = ({
  isOpen,
  onClose,
  linkedClients,
  destinationClient,
  token,
  onImported,
}) => {
  const [sourceClient, setSourceClient] = useState(null);
  const [sourceCombinations, setSourceCombinations] = useState([]);
  const [sourceCombination, setSourceCombination] = useState(null);

  // We'll default "destinationName" to the same name as source, but user can override
  const [destinationName, setDestinationName] = useState('');

  const [isLoadingCombos, setIsLoadingCombos] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isImporting, setIsImporting] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setSourceClient(null);
      setSourceCombinations([]);
      setSourceCombination(null);
      setDestinationName('');
      setErrorMsg('');
      setIsLoadingCombos(false);
      setIsImporting(false);
    }
  }, [isOpen]);

  // Fetch prompt combos from the chosen source client
  useEffect(() => {
    if (!sourceClient) {
      setSourceCombinations([]);
      setSourceCombination(null);
      return;
    }
    const fetchSourceCombinations = async () => {
      try {
        setIsLoadingCombos(true);
        setErrorMsg('');
        const res = await axios.get(`${API_URL_PDP}/get-prompt-combinations`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { client_name: sourceClient },
        });
        if (res.status === 200) {
          const combos = res.data.prompt_combinations.map((c) => ({
            label: c,
            value: c,
          }));
          setSourceCombinations(combos);
        } else {
          setErrorMsg('Failed to fetch source prompt combinations.');
        }
      } catch (err) {
        console.error('Error fetching source combos:', err);
        setErrorMsg('Error fetching source prompt combinations.');
      } finally {
        setIsLoadingCombos(false);
      }
    };
    fetchSourceCombinations();
  }, [sourceClient, token]);

  // When the user picks a source combination, default destinationName to match
  useEffect(() => {
    if (sourceCombination) {
      setDestinationName(sourceCombination);
    }
  }, [sourceCombination]);

  if (!isOpen) {
    return null;
  }

  const handleOverlayClick = () => {
    onClose();
  };

  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  const handleImport = async () => {
    if (!sourceClient || !sourceCombination || !destinationName) {
      setErrorMsg(
        'Please select a source client, a source combination, and enter a destination name.'
      );
      return;
    }
    if (!destinationClient) {
      setErrorMsg('No destination client selected in the main page.');
      return;
    }
    try {
      setIsImporting(true);
      setErrorMsg('');

      // 1) Check if that name is already taken in the destination
      const checkRes = await axios.post(
        `${API_URL_PDP}/check-prompt-combination`,
        {
          client_name: destinationClient,
          prompt_combination_name: destinationName,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (checkRes.data.isTaken) {
        setErrorMsg(
          `The name "${destinationName}" is already taken in the destination client.`
        );
        setIsImporting(false);
        return;
      }

      // 2) Fetch the source combination data
      const getRes = await axios.get(`${API_URL_PDP}/get-prompt-combination-data`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          client_name: sourceClient,
          prompt_combination_name: sourceCombination,
        },
      });
      // Could be an empty doc if it doesn't exist
      const sourceData = getRes?.data || {};

      // 3) Create the new doc in the destination (stores minimal fields for now)
      const createRes = await axios.post(
        `${API_URL_PDP}/create-prompt-combination`,
        {
          client_name: destinationClient,
          prompt_combination_name: destinationName,
          multiOutput: sourceData.multiOutput || false,
          // Include research_mode & research_prompt if you want them set at creation time
          research_mode: sourceData.research_mode || false,
          research_prompt: sourceData.research_prompt || '',
          // Include feed data setting at creation time
          include_feed_data: sourceData.include_feed_data !== undefined ? sourceData.include_feed_data : true,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (createRes.status !== 200) {
        setErrorMsg('Failed to create new prompt combination in the destination.');
        setIsImporting(false);
        return;
      }

      // 4) Now update it with all the source fields to replicate the entire config
      const updatePayload = {
        client_name: destinationClient,
        prompt_combination_name: destinationName,
        selected_columns: sourceData.selected_columns || [],
        system_prompt: sourceData.system_prompt || '',
        image_prompt: sourceData.image_prompt || '',
        instruction_prompt: sourceData.instruction_prompt || '',
        few_shot_prompts: sourceData.few_shot_prompts || [],
        audit_prompt_activate: sourceData.audit_prompt_activate || false,
        audit_prompt_text: sourceData.audit_prompt_text || '',
        multiOutput: sourceData.multiOutput || false,

        // Make sure to include the new fields:
        research_mode: sourceData.research_mode || false,
        research_prompt: sourceData.research_prompt || '',
        
        // Always reset PDF settings to disabled when importing, regardless of source settings
        include_pdfs: false,
        pdf_mapping: null,
        
        // Include the feed data setting
        include_feed_data: sourceData.include_feed_data !== undefined ? sourceData.include_feed_data : true,
      };

      await axios.put(`${API_URL_PDP}/update-prompt-combination`, updatePayload, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Done: close popup and notify parent
      setIsImporting(false);
      onClose();
      if (onImported) {
        onImported(destinationName);
      }
    } catch (err) {
      console.error('Error importing prompt combination:', err);
      setErrorMsg(
        err?.response?.data?.message ||
          'An error occurred while importing the prompt combination.'
      );
      setIsImporting(false);
    }
  };

  // React-Select styling
  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '34px',
      borderColor: '#e0e1eb',
      boxShadow: 'none',
      '&:hover': { borderColor: '#e0e1eb' },
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 8px',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px',
      padding: '0px',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '34px',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: '0 8px',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: '0 8px',
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#fff' : '#292B3D',
      backgroundColor: state.isSelected
        ? '#4758EB'
        : state.isFocused
        ? '#E0E1EB'
        : '#fff',
      padding: '8px 12px',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  return (
    <div className={styles.popupOverlay} onClick={handleOverlayClick}>
      <div className={styles.popupContent} onClick={handleContentClick}>
        <div className={styles.popupHeader}>
          <h2>Import Prompt Combination</h2>
          <button className={styles.closeButton} onClick={onClose}>
            <FaTimes size={16} />
          </button>
        </div>

        <div className={styles.popupBody}>
          {errorMsg && <div className={styles.errorMessage}>{errorMsg}</div>}

          <label className={styles.fieldLabel}>Source Client:</label>
          <Select
            styles={customSelectStyles}
            options={linkedClients}
            value={linkedClients.find((c) => c.value === sourceClient) || null}
            onChange={(opt) => {
              setSourceClient(opt?.value || null);
              setSourceCombination(null);
            }}
            placeholder="Select a source client"
            isClearable
          />

          <label className={styles.fieldLabel}>Source Prompt Combination:</label>
          <Select
            styles={customSelectStyles}
            options={sourceCombinations}
            value={
              sourceCombinations.find((sc) => sc.value === sourceCombination) || null
            }
            onChange={(opt) => setSourceCombination(opt?.value || null)}
            placeholder={
              isLoadingCombos
                ? 'Loading...'
                : sourceClient
                ? 'Select a prompt combination'
                : 'Pick a client first'
            }
            isDisabled={!sourceClient || isLoadingCombos}
            isLoading={isLoadingCombos}
            isClearable
          />

          <label className={styles.fieldLabel}>
            Destination Name (in {destinationClient}):
          </label>
          <input
            type="text"
            className={styles.textInput}
            placeholder="Name for the new prompt combo"
            value={destinationName}
            onChange={(e) => setDestinationName(e.target.value)}
          />
        </div>

        <div className={styles.popupFooter}>
          <button
            className={styles.createButton}
            onClick={handleImport}
            disabled={!sourceClient || !sourceCombination || !destinationName || isImporting}
          >
            {isImporting ? 'Importing...' : 'Import'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopupImportPromptCombinationPdp;
