// src/components/CustomAttributes.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './css/CustomAttributes.module.css';
import { FaSignOutAlt, FaArrowLeft } from 'react-icons/fa';

// Import icons for the navigation menu
import { MdDashboard, MdDeveloperMode } from 'react-icons/md';

// Import sub-pages
import AggregatedValues from './CustomAttributesPages/AggregatedValues';
import CoreVariants from './CustomAttributesPages/CoreVariants';

const CustomAttributes = ({ token, clientNameProp, isAdmin }) => {
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState('AggregatedValues');

  const handleLogout = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/logout`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log('Logout successful');
    } catch (err) {
      console.error('Error logging out', err);
    }
    localStorage.removeItem('token');
    navigate('/login');
  };

  const handleBack = () => {
    navigate(isAdmin ? '/admin' : '/client');
  };

  const renderContent = () => {
    switch (activePage) {
      case 'AggregatedValues':
        return <AggregatedValues />;
      case 'CoreVariants':
        {/* Pass the token prop to CoreVariants */}
        return <CoreVariants token={token} />;
      default:
        return <AggregatedValues />;
    }
  };

  return (
    <div>
      {/* Top Bar */}
      <div className={styles.topBar}>
        <button onClick={handleBack} className={styles.backButton} title="Back" aria-label="Back">
          <FaArrowLeft size={20} />
        </button>
        <button onClick={handleLogout} className={styles.logoutBtn} title="Logout" aria-label="Logout">
          <FaSignOutAlt size={20} />
        </button>
        <div className={styles.headerAndDropdown}>
          <div className={styles.headerTextContainer}>
            <span className={styles.feedViewerVersion}>
              Quantum Feed Engine - Custom Attributes
            </span>
            <span className={styles.headerSubtitle}>
              Manage your custom attributes
            </span>
          </div>
          <img
            src="https://storage.googleapis.com/quantum-feed-engine/workbench/application-images/3_down.png"
            alt="Header"
            className={styles.headerImage}
          />
        </div>
      </div>

      {/* Navigation Menu */}
      <div className={styles.navMenu}>
        <button
          className={`${styles.navBtn} ${activePage === 'AggregatedValues' ? styles.active : ''}`}
          onClick={() => setActivePage('AggregatedValues')}
        >
          <MdDashboard size={20} className={styles.icon} /> Aggregated Values
        </button>
        <button
          className={`${styles.navBtn} ${activePage === 'CoreVariants' ? styles.active : ''}`}
          onClick={() => setActivePage('CoreVariants')}
          title="Core Variants"
        >
          <MdDeveloperMode size={20} className={styles.icon} /> Core Variants
        </button>
      </div>

      {/* Content Area */}
      <div className={styles.contentArea}>{renderContent()}</div>
    </div>
  );
};

export default CustomAttributes;
