import React, { useState, useEffect, useContext, useMemo } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { 
  FaArrowLeft, FaSignOutAlt, FaDatabase, FaFilePdf, FaUserAlt
} from 'react-icons/fa';
import Select from 'react-select';
import ClipLoader from "react-spinners/ClipLoader";
import styles from './css/GenerationInput.module.css';
import { PdpNavigationContext } from '../App';

// Import tab components
import TabularDataMapper from './GenerationInputTabs/TabularDataMapper';
import PdfDataMapper from './GenerationInputTabs/PdfDataMapper';

const API_URL_PDP = process.env.REACT_APP_API_URL_PDP;

const GenerationInput = ({ isAdmin, token, selectedClient, setSelectedClient }) => {
  const navigate = useNavigate();
  const { pdpTableState, setPdpTableState, prevPath } = useContext(PdpNavigationContext);

  const [linkedClients, setLinkedClients] = useState([]);
  const [isClientsLoading, setIsClientsLoading] = useState(false);
  const [clientError, setClientError] = useState(null);
  
  // Tab state
  const [activeTab, setActiveTab] = useState('pdf');
  
  // Available tabs configuration - can be extended in the future
  const tabs = useMemo(() => [
    { 
      id: 'pdf', 
      label: 'PDF Data Mapper', 
      icon: <FaFilePdf size={14} style={{ marginRight: '8px' }} />,
      component: <PdfDataMapper token={token} selectedClient={selectedClient} /> 
    },
    { 
      id: 'tabular', 
      label: 'Tabular Data Mapper', 
      icon: <FaDatabase size={14} style={{ marginRight: '8px' }} />,
      component: <TabularDataMapper /> 
    },
    // Additional tabs can be added here in the future
  ], [token, selectedClient]);
  
  // ------------------------------------------------------------------
  // Navigation handlers
  // ------------------------------------------------------------------
  const handleBackButton = () => {
    // Go back in browser history rather than to a specific route
    navigate(-1);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  // ------------------------------------------------------------------
  // Client selection
  // ------------------------------------------------------------------
  useEffect(() => {
    const fetchLinkedClients = async () => {
      if (!token) return;
      setIsClientsLoading(true);
      try {
        const response = await fetch(`${API_URL_PDP}/client-data`, {
          headers: { 'Authorization': `Bearer ${token}` },
        });
        if (response.ok) {
          const data = await response.json();
          setLinkedClients(data.linked_clients.map(client => ({ value: client, label: client })));
          setClientError(null);
        } else {
          setClientError('Failed to load clients');
        }
      } catch (error) {
        console.error('Error fetching clients:', error);
        setClientError('An error occurred');
      } finally {
        setIsClientsLoading(false);
      }
    };
    fetchLinkedClients();
  }, [token]);

  const handleClientChange = (option) => {
    const clientVal = option ? option.value : '';
    setSelectedClient(clientVal);
  };

  // Custom react-select styling
  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '34px',
      height: '34px',
      borderColor: '#e0e1eb',
      boxShadow: 'none',
      '&:hover': { borderColor: '#e0e1eb' },
      padding: '0px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '34px',
      padding: '0 8px',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px',
      padding: '0px',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '34px',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: '0 8px',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: '0 8px',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#e0e1eb',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      padding: '0 6px',
      fontSize: '12px',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      padding: '0 6px',
      ':hover': {
        backgroundColor: '#c0c0c0',
        color: '#fff',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#fff' : '#292B3D',
      backgroundColor: state.isSelected
        ? '#4758EB'
        : state.isFocused
        ? '#E0E1EB'
        : '#fff',
      fontFamily: 'Lexend Deca, sans-serif',
      fontSize: '12px',
      padding: '8px 12px',
      '&:hover': {
        backgroundColor: '#E0E1EB',
        color: '#292B3D',
      },
    }),
    menu: (provided) => ({
      ...provided,
      fontFamily: 'Lexend Deca, sans-serif',
      zIndex: 1000,
      fontSize: '12px',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#292B3D',
      fontFamily: 'Lexend Deca, sans-serif',
      fontSize: '12px',
    }),
  };

  // ------------------------------------------------------------------
  // Tab handling
  // ------------------------------------------------------------------
  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  // Get the active tab component
  const getActiveTabComponent = () => {
    const tab = tabs.find(tab => tab.id === activeTab);
    if (!tab) return null;
    
    // Create a fresh element with a key based on the selectedClient
    // This ensures the component is re-rendered when the client changes
    const Component = React.cloneElement(tab.component, {
      key: `${tab.id}-${selectedClient || 'no-client'}`
    });
    
    return Component;
  };

  // ------------------------------------------------------------------
  // Render
  // ------------------------------------------------------------------
  return (
    <div>
      {/* Top Bar */}
      <div className={styles.topBar}>
        {/* Left side: Back & Logout */}
        <div className={styles.topBarLeft}>
          <button 
            onClick={handleBackButton}
            className={styles.backButton} 
            title="Back"
          >
            <FaArrowLeft size={20} />
          </button>
          <button 
            onClick={handleLogout} 
            className={styles.logoutButton} 
            title="Logout"
          >
            <FaSignOutAlt size={20} />
          </button>
        </div>

        {/* Center: Title & Subtitle */}
        <div className={styles.headerTextContainer}>
          <span className={styles.feedViewerVersion}>
            Quantum Feed Engine - Generation Input
          </span>
          <span className={styles.headerSubtitle}>
            Configure Input for PDP Generation
          </span>
        </div>

        {/* Right side: Only Client Dropdown */}
        <div className={styles.topBarActions}>
          {/* Client Dropdown */}
          <Select
            options={linkedClients}
            onChange={handleClientChange}
            value={linkedClients.find(client => client.value === selectedClient) || null}
            className={styles.clientDropdown}
            placeholder={isClientsLoading ? 'Loading clients...' : 'Select Client'}
            isLoading={isClientsLoading}
            isDisabled={isClientsLoading}
            styles={customStyles}
            noOptionsMessage={() => clientError || 'No clients available'}
            isClearable
          />
        </div>

        {/* Finally, the header image is placed last with marginLeft: 'auto' */}
        <img
          src="https://storage.googleapis.com/quantum-feed-engine/workbench/application-images/3_down.png"
          alt="Header"
          className={styles.headerImage}
        />
      </div>

      {/* Tabs Section - Only shown when a client is selected */}
      {selectedClient ? (
        <div className={styles.tabsContainer}>
          {/* Tab Navigation */}
          <ul className={styles.tabsList}>
            {tabs.map(tab => (
              <li 
                key={tab.id}
                className={`${styles.tabItem} ${activeTab === tab.id ? styles.activeTab : ''}`}
                onClick={() => handleTabChange(tab.id)}
              >
                {tab.icon}
                {tab.label}
              </li>
            ))}
          </ul>
          
          {/* Tab Content */}
          <div className={styles.tabContent}>
            {getActiveTabComponent()}
          </div>
        </div>
      ) : (
        /* Display a prompt to select a client when none is selected */
        <div className={styles.noClientSelectedContainer}>
          <div className={styles.noClientSelectedMessage}>
            <FaUserAlt size={32} className={styles.noClientIcon} />
            <h3>Please Select a Client</h3>
            <p>You need to select a client before you can view the data mapper tools.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default GenerationInput; 