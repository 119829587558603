import React from 'react';
import styles from './css/TabularDataMapper.module.css';

const TabularDataMapper = () => {
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Tabular Data Mapper (IN DEVELOPMENT)</h3>
      <p className={styles.description}>
        Configure mapping for tabular data sources. Upload and map your tabular data to generate high-quality PDPs.
      </p>
      
      <div className={styles.section}>
        <h4 className={styles.sectionTitle}>Data Source Configuration</h4>
        <p className={styles.description}>
          step 1.
        </p>
        {/* Content will be implemented in future steps */}
      </div>
      
      <div className={styles.section}>
        <h4 className={styles.sectionTitle}>Field Mapping</h4>
        <p className={styles.description}>
          step 2.
        </p>
        {/* Content will be implemented in future steps */}
      </div>
    </div>
  );
};

export default TabularDataMapper;
