import React, { useEffect, useRef, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import styles from './css/PopupPdpBulkActions.module.css';

const PopupPdpBulkActions = ({
  isOpen,
  onClose,
  token,
  clientName,
  selectedCount,
  filteredCount,
  allCount,
  filters = [],
  pdpColumns = [],
  selectedIds = [],       // Array of selected row IDs
  progressData,           // Object containing progress information
  isCanceling,            // Boolean: is a cancel request in progress
  handleCancelJob,        // Function to handle cancellation
  onBulkActionComplete,   // Callback for telling parent to reload data
}) => {
  const popupRef = useRef(null);

  // --- Tabs & States ---
  const [activeTab, setActiveTab] = useState('generate'); // 'generate' | 'status'
  const [selectedFields, setSelectedFields] = useState([]);
  const [scope, setScope] = useState('selected');         // 'selected' | 'filtered' | 'all'
  const [isSubmitting, setIsSubmitting] = useState(false);

  // --- "Generate" specific ---
  const [promptCombinations, setPromptCombinations] = useState([]);
  const [isLoadingPrompts, setIsLoadingPrompts] = useState(false);
  const [selectedPromptConfig, setSelectedPromptConfig] = useState('');
  const [selectedPromptData, setSelectedPromptData] = useState(null);

  // --- "Status" specific ---
  const [desiredState, setDesiredState] = useState('activate'); // 'activate' | 'deactivate'

  // --- Small success messages to show inline ---
  const [generateStartedMsg, setGenerateStartedMsg] = useState('');
  const [statusSuccessMsg, setStatusSuccessMsg] = useState('');

  // ------------------------------------------------------------
  //  1. Fetch prompt combinations when popup opens (Generate Tab)
  // ------------------------------------------------------------
  useEffect(() => {
    if (!isOpen || !clientName) return;
    if (activeTab !== 'generate') return; // only needed for Generate tab

    (async () => {
      setIsLoadingPrompts(true);
      try {
        const url = `${process.env.REACT_APP_API_URL_PDP}/get-prompt-combinations`
                  + `?client_name=${encodeURIComponent(clientName)}`;
        const res = await axios.get(url, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (res.status === 200 && Array.isArray(res.data.prompt_combinations)) {
          setPromptCombinations(res.data.prompt_combinations);
        } else {
          setPromptCombinations([]);
        }
      } catch (error) {
        console.error('Error fetching prompt combinations:', error);
        setPromptCombinations([]);
      } finally {
        setIsLoadingPrompts(false);
      }
    })();
  }, [isOpen, clientName, token, activeTab]);

  // ------------------------------------------------------------
  //  2. Fetch prompt configuration data when selected
  // ------------------------------------------------------------
  useEffect(() => {
    if (!selectedPromptConfig || !clientName) return;
    if (activeTab !== 'generate') return;

    (async () => {
      try {
        const url = `${process.env.REACT_APP_API_URL_PDP}/get-prompt-combination-data`
                  + `?client_name=${encodeURIComponent(clientName)}`
                  + `&prompt_combination_name=${encodeURIComponent(selectedPromptConfig)}`;
        const res = await axios.get(url, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (res.status === 200) {
          setSelectedPromptData(res.data);
          // If multiOutput is false, automatically select only 'description'
          // If multiOutput is true, deselect all fields
          if (!res.data.multiOutput) {
            setSelectedFields(['description']);
          } else {
            setSelectedFields([]);
          }
        }
      } catch (error) {
        console.error('Error fetching prompt configuration data:', error);
        setSelectedPromptData(null);
      }
    })();
  }, [selectedPromptConfig, clientName, token, activeTab]);

  // ------------------------------------------------------------
  //  2. Close popup if user clicks outside
  // ------------------------------------------------------------
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen, onClose]);

  // ------------------------------------------------------------
  //  3. Toggle a field in selectedFields
  // ------------------------------------------------------------
  const toggleField = (field) => {
    // If multiOutput is false, only allow 'description'
    if (selectedPromptData && !selectedPromptData.multiOutput && field !== 'description') {
      return;
    }
    
    setSelectedFields((prev) => {
      if (prev.includes(field)) {
        return prev.filter((f) => f !== field);
      }
      return [...prev, field];
    });
  };

  // ------------------------------------------------------------
  //  4. Handle the bulk action submission
  // ------------------------------------------------------------
  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const BASE_URL = process.env.REACT_APP_API_URL_PDP;
      let endpoint = '';
      let method = 'POST';
      let payload = {};

      if (activeTab === 'generate') {
        // --- GENERATE PDP FIELDS ---
        endpoint = '/generate-pdp-fields';
        payload = {
          generation_scope: scope,
          selected_fields: selectedFields,
          selected_ids: scope === 'selected' ? selectedIds : [],
          filters,
          client_name: clientName,
          prompt_configuration_id: selectedPromptConfig,
        };
      } else {
        // --- CHANGE STATUS ---
        endpoint = '/qfe-update-pdp';
        method = 'PUT';

        let update_scope = 'selectedIds';
        if (scope === 'filtered') update_scope = 'filteredIds';
        if (scope === 'all')      update_scope = 'allIds';

        payload = {
          update_scope,
          ids: scope === 'selected' ? selectedIds : [],
          filters,
          client_name: clientName,
          fields: selectedFields,
          state: desiredState,
        };
      }

      const response = await axios({
        url: `${BASE_URL}${endpoint}`,
        method,
        headers: { Authorization: `Bearer ${token}` },
        data: payload,
      });

      if (response.status === 200) {
        if (activeTab === 'generate') {
          // Show a small message, keep popup open
          setGenerateStartedMsg(
            'PDP generation started successfully! You can monitor progress below.'
          );
          // Typically the PDP table is not re-fetched now. 
          // The parent poller or a future refresh will update once done.

        } else {
          // "Change Status"
          setStatusSuccessMsg('Status updated successfully!');
          // Refresh the table behind the popup
          if (onBulkActionComplete) onBulkActionComplete();
        }
      } else {
        alert(`Unexpected response: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Bulk action failed:', error);
      alert(`Bulk action failed: ${error?.response?.data?.message || error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  // ------------------------------------------------------------
  //  5. Helper: format end_time
  // ------------------------------------------------------------
  const formatEndTime = (timeStr) => {
    if (!timeStr) return null;
    return new Date(timeStr).toLocaleString();
  };

  // ------------------------------------------------------------
  //  6. Render the progress/status box (only on "Generate" tab)
  // ------------------------------------------------------------
  const renderProgressBox = () => {
    if (!progressData || !progressData.status || progressData.status === 'no_job') {
      return null;
    }

    return (
      <div className={styles.popupProgressBox}>
        {progressData.status === 'running' && (
          <>
            {/* If user hasn't canceled yet */}
            {!progressData.cancel_run && (
              <div className={styles.progressInfo}>
                <strong style={{ color: '#4758EB' }}>PDP Generation in progress</strong>
                <span>
                  Items Processed: {progressData.items_processed} / {progressData.total_items}
                  {progressData.current_batch > 0 && progressData.total_batches > 0 && (
                    <> | Batch {progressData.current_batch} of {progressData.total_batches}</>
                  )}
                </span>
              </div>
            )}

            {/* If user already clicked "cancel" */}
            {progressData.cancel_run && (
              <div className={styles.progressInfo}>
                <strong style={{ color: 'orange' }}>Finishing current batch...</strong>
                <span>
                  Items Processed: {progressData.items_processed} / {progressData.total_items}
                  {progressData.current_batch > 0 && progressData.total_batches > 0 && (
                    <> | Batch {progressData.current_batch} of {progressData.total_batches}</>
                  )}
                </span>
              </div>
            )}

            {/* Progress bar */}
            <div className={styles.progressBarWrapper}>
              <div className={styles.progressBarOuter}>
                <div
                  className={styles.progressBarInner}
                  style={{
                    width: progressData.total_items
                      ? (progressData.items_processed / progressData.total_items) * 100 + '%'
                      : '0%',
                  }}
                />
              </div>
              <button
                className={styles.cancelGenerationButton}
                onClick={handleCancelJob}
                disabled={isCanceling}
                title="Cancel PDP Generation"
              >
                {isCanceling ? <ClipLoader size={12} color="#ffffff" /> : <FaTimes />}
              </button>
            </div>
          </>
        )}

        {['completed', 'error', 'cancelled', 'timeout'].includes(progressData.status) && (
          <div className={styles.progressInfo}>
            {progressData.status === 'completed' && (
              <strong style={{ color: 'green' }}>PDP Generation completed</strong>
            )}
            {progressData.status === 'error' && (
              <strong style={{ color: 'red' }}>PDP Generation error</strong>
            )}
            {progressData.status === 'cancelled' && (
              <strong style={{ color: 'orange' }}>PDP Generation cancelled</strong>
            )}
            {progressData.status === 'timeout' && (
              <strong style={{ color: 'orange' }}>PDP Generation timed out</strong>
            )}

            <span>
              Items Processed: {progressData.items_processed} / {progressData.total_items}
              {progressData.current_batch > 0 && progressData.total_batches > 0 && (
                <> | Batch {progressData.current_batch} of {progressData.total_batches}</>
              )}
            </span>

            {progressData.end_time && (
              <span style={{ fontSize: '0.85rem', color: '#666' }}>
                End Time: {formatEndTime(progressData.end_time)}
              </span>
            )}

            {progressData.message && (
              <span style={{ fontSize: '0.8rem', color: '#888' }}>
                Message: {progressData.message}
              </span>
            )}
          </div>
        )}
      </div>
    );
  };

  // ------------------------------------------------------------
  //  7. If popup not open, return nothing
  // ------------------------------------------------------------
  if (!isOpen) return null;

  // ------------------------------------------------------------
  //  8. Determine the scope count & form button states
  // ------------------------------------------------------------
  const scopeCount =
    scope === 'selected' ? selectedCount :
    scope === 'filtered' ? filteredCount :
    allCount;

  // For "Generate"
  const isGenerateDisabled =
    isSubmitting ||
    (progressData?.status === 'running') ||
    (scopeCount === 0) ||
    (!selectedPromptConfig || selectedFields.length === 0);

  // For "Change Status"
  const isChangeStatusDisabled =
    isSubmitting ||
    (scopeCount === 0) ||
    (selectedFields.length === 0);

  // ------------------------------------------------------------
  //  9. Render the popup
  // ------------------------------------------------------------
  return (
    <div className={styles.popupOverlay}>
      <div className={styles.popupInner} ref={popupRef}>
        {/* Title */}
        <div className={styles.title}>Bulk Actions</div>

        {/* Tab Switcher */}
        <div className={styles.segmentedControl}>
          <button
            className={
              activeTab === 'generate'
                ? `${styles.segmentButton} ${styles.activeSegment}`
                : styles.segmentButton
            }
            onClick={() => setActiveTab('generate')}
          >
            Generate PDP Columns
          </button>
          <button
            className={
              activeTab === 'status'
                ? `${styles.segmentButton} ${styles.activeSegment}`
                : styles.segmentButton
            }
            onClick={() => setActiveTab('status')}
          >
            Change Status
          </button>
        </div>

        {/* The generation progress box appears ONLY if on "generate" tab */}
        {activeTab === 'generate' && renderProgressBox()}

        {/* Inline success messages (depends on active tab) */}
        {activeTab === 'generate' && generateStartedMsg && (
          <div className={styles.successMessage}>
            {generateStartedMsg}
          </div>
        )}
        {activeTab === 'status' && statusSuccessMsg && (
          <div className={styles.successMessage}>
            {statusSuccessMsg}
          </div>
        )}

        {/* Description below the tab */}
        <div className={styles.description}>
          {activeTab === 'generate'
            ? 'Select which PDP columns you want to generate/overwrite.'
            : 'Select which PDP columns whose status you want to change.'
          }
        </div>


        {/* Prompt Config (only for Generate) */}
        {activeTab === 'generate' && (
          <div className={styles.promptSection}>
            <div className={styles.subTitle}>Select Prompt Configuration</div>
            {isLoadingPrompts ? (
              <ClipLoader size={20} color="#4758EB" />
            ) : (
              <select
                className={styles.dropdown}
                value={selectedPromptConfig}
                onChange={(e) => setSelectedPromptConfig(e.target.value)}
              >
                <option value="">-- Select a configuration --</option>
                {promptCombinations.map((combo) => (
                  <option key={combo} value={combo}>
                    {combo}
                  </option>
                ))}
              </select>
            )}
          </div>
        )}

        {/* Field Selection */}
        <div className={styles.fieldSelectionSection}>
          <div className={styles.fieldsHeader}>
            <div className={styles.subTitle}>PDP Columns</div>
            <div className={styles.fieldsControls}>
              <button
                className={styles.selectAllButton}
                onClick={() => {
                  // If multiOutput is false, only allow 'description'
                  if (selectedPromptData && !selectedPromptData.multiOutput) {
                    setSelectedFields(['description']);
                    return;
                  }
                  
                  if (selectedFields.length < pdpColumns.length) {
                    setSelectedFields(pdpColumns);
                  } else {
                    setSelectedFields([]);
                  }
                }}
              >
                {selectedFields.length < pdpColumns.length ? 'Select All' : 'Unselect All'}
              </button>
              <div className={styles.selectedCount}>{selectedFields.length} selected</div>
            </div>
          </div>
          <div className={styles.fieldsGrid}>
            {pdpColumns.length === 0 && (
              <div className={styles.noResults}>No PDP columns available</div>
            )}
            {pdpColumns.map((col) => {
              const isDisabled = activeTab === 'generate' && 
                (!selectedPromptConfig || (selectedPromptData && !selectedPromptData.multiOutput && col !== 'description'));
              return (
                <label 
                  key={col} 
                  className={`${styles.fieldItem} ${isDisabled ? styles.disabled : ''} ${activeTab === 'generate' ? styles.generateTab : ''}`}
                >
                  <input
                    type="checkbox"
                    checked={selectedFields.includes(col)}
                    onChange={() => toggleField(col)}
                    disabled={isDisabled}
                  />
                  <div className={styles.fieldName}>{col}</div>
                </label>
              );
            })}
          </div>
        </div>

        {/* Scope Selection */}
        <div className={styles.scopeSection}>
          <div className={styles.subTitle}>Select Scope</div>
          <div className={styles.scopeSelector}>
            <button
              className={
                scope === 'selected'
                  ? `${styles.scopeButton} ${styles.activeScope}`
                  : styles.scopeButton
              }
              onClick={() => setScope('selected')}
            >
              Selected IDs ({selectedCount})
            </button>
            <button
              className={
                scope === 'filtered'
                  ? `${styles.scopeButton} ${styles.activeScope}`
                  : styles.scopeButton
              }
              onClick={() => setScope('filtered')}
            >
              Filtered IDs ({filteredCount})
            </button>
            <button
              className={
                scope === 'all'
                  ? `${styles.scopeButton} ${styles.activeScope}`
                  : styles.scopeButton
              }
              onClick={() => setScope('all')}
            >
              All IDs ({allCount})
            </button>
          </div>
        </div>



        {/* Status Toggle (only for "Change Status") */}
        {activeTab === 'status' && (
          <div className={styles.stateChoiceSection}>
            <div className={styles.subTitle}>Select State</div>
            <div className={styles.smallToggleGroup}>
              <button
                className={
                  desiredState === 'activate'
                    ? `${styles.smallToggleButton} ${styles.activeSmallToggle}`
                    : styles.smallToggleButton
                }
                onClick={() => setDesiredState('activate')}
              >
                Activate
              </button>
              <button
                className={
                  desiredState === 'deactivate'
                    ? `${styles.smallToggleButton} ${styles.activeSmallToggle}`
                    : styles.smallToggleButton
                }
                onClick={() => setDesiredState('deactivate')}
              >
                Deactivate
              </button>
            </div>
          </div>
        )}

        {/* Action Buttons */}
        <div className={styles.actionButtons}>
          <button
            className={styles.primaryActionButton}
            onClick={handleSubmit}
            disabled={
              activeTab === 'generate' ? isGenerateDisabled : isChangeStatusDisabled
            }
          >
            {isSubmitting ? (
              <>
                <ClipLoader size={14} color="#ffffff" />
                <span> Working...</span>
              </>
            ) : activeTab === 'generate' ? (
              'Generate'
            ) : (
              'Change Status'
            )}
          </button>
          <button className={styles.secondaryActionButton} onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopupPdpBulkActions;
