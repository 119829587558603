// src/App.js

import React, { useState, useEffect, createContext } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation
} from 'react-router-dom';
import axios from 'axios';

// Components
import Login from './components/Login';
import Register from './components/Register';
import AdminDashboard from './components/AdminDashboard';
import ClientDashboard from './components/ClientDashboard';
import Preview from './components/Preview';
import Config from './components/Config';
import TextGeneration from './components/TextGeneration';
import PromptBuilder from './components/PromptBuilder';
import ImageGeneration from './components/ImageGeneration';
import CustomAttributes from './components/CustomAttributes';
import PromptBuilderPdp from './components/PromptBuilderPdp';
import ImageGenerationScheduler from './components/ImageGenerationScheduler';
import PdpGeneration from './components/PdpGeneration';
import GenerationInput from './components/GenerationInput';

// Create context for PDP navigation state
export const PdpNavigationContext = createContext();

// ------------------------
// Route protection wrappers
// ------------------------
const PrivateRoute = ({ children, isAuthenticated }) => {
  return isAuthenticated ? children : <Navigate to="/login" />;
};

const AdminRoute = ({ children, isAuthenticated, isAdmin }) => {
  return isAuthenticated && isAdmin ? children : <Navigate to="/login" />;
};

function App() {
  const [token, setToken] = useState(localStorage.getItem('token') || null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [client, setClient] = useState('');
  const [user, setUser] = useState({ username: '', email: '' });
  const [loggingOut, setLoggingOut] = useState(false);

  // For selecting client in multiple routes
  const [selectedClient, setSelectedClient] = useState('');
  // For remembering prompt combination between PDP pages
  const [selectedPromptCombination, setSelectedPromptCombination] = useState('');
  
  // PDP navigation state
  const [pdpTableState, setPdpTableState] = useState(null);
  const [prevPath, setPrevPath] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  // Track navigation paths
  useEffect(() => {
    setPrevPath(location.pathname);
  }, [location.pathname]);

  // Reset prompt combination and pdp table state when client changes
  useEffect(() => {
    setSelectedPromptCombination('');
    setPdpTableState(null);
  }, [selectedClient]);

  // Helper to set/unset token
  const handleSetToken = (newToken) => {
    setToken(newToken);
    if (newToken) {
      localStorage.setItem('token', newToken);
    } else {
      localStorage.removeItem('token');
    }
  };

  // ------------------------
  // Token validation & redirect logic
  // ------------------------
  useEffect(() => {
    // If we're in the process of logging out, skip validation.
    if (loggingOut) {
      console.log('Logging out, skipping validation...');
      return;
    }

    // Allow public access to /login and /preview without token.
    const publicPaths = ['/login', '/preview'];

    // If no token AND we are not on a public page, go to /login
    if (!token && !publicPaths.includes(location.pathname)) {
      console.log('No token found, navigating to login');
      navigate('/login');
      return;
    }

    // If a token exists, validate it
    if (token) {
      const validateToken = async () => {
        try {
          console.log('Validating token...');
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/validate-token`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          console.log('Token validation successful');
          setIsAdmin(response.data.is_admin);
          setUser({ username: response.data.username, email: response.data.email });
        } catch (error) {
          console.error('Token validation error:', error);
          handleSetToken(null);
          navigate('/login');
        }
      };

      validateToken();
    }
  }, [token, loggingOut, location, navigate]);

  // ------------------------
  // Heartbeat effect
  // ------------------------
  useEffect(() => {
    const heartbeat = async () => {
      try {
        await axios.get(`${process.env.REACT_APP_API_URL}/heartbeat`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        console.log('Heartbeat successful');
      } catch (error) {
        console.error('Heartbeat error:', error);
        handleSetToken(null);
        navigate('/login');
      }
    };

    // Send heartbeat every 5.5 minutes if token exists
    const intervalId = setInterval(() => {
      if (token) {
        heartbeat();
      }
    }, 5.5 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [token, navigate]);

  // Used by PrivateRoute & AdminRoute
  const isAuthenticated = !!token;
  console.log('isAuthenticated:', isAuthenticated);

  return (
    <PdpNavigationContext.Provider value={{ pdpTableState, setPdpTableState, prevPath }}>
      <Routes>
        {/* Public route for /preview */}
        <Route
          path="/preview"
          element={<Preview /* no auth required */ />}
        />

        {/* Public route for /login */}
        <Route
          path="/login"
          element={
            <Login
              setToken={handleSetToken}
              setIsAdmin={setIsAdmin}
              setUser={setUser}
            />
          }
        />

        {/* Admin-only route for /register */}
        <Route
          path="/register"
          element={
            <AdminRoute isAuthenticated={isAuthenticated} isAdmin={isAdmin}>
              <Register />
            </AdminRoute>
          }
        />

        {/* Protected route: Admin dashboard */}
        <Route
          path="/admin"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated && isAdmin}>
              <AdminDashboard
                token={token}
                isAdmin={isAdmin}
                setClient={setClient}
              />
            </PrivateRoute>
          }
        />

        {/* Protected route: Client dashboard */}
        <Route
          path="/client"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated && !isAdmin}>
              <ClientDashboard token={token} isAdmin={isAdmin} />
            </PrivateRoute>
          }
        />

        {/* Protected route: Config */}
        <Route
          path="/config"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated}>
              <Config token={token} client={client} isAdmin={isAdmin} />
            </PrivateRoute>
          }
        />

        {/* Protected route: Text generation */}
        <Route
          path="/text-generation"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated}>
              <TextGeneration token={token} isAdmin={isAdmin} />
            </PrivateRoute>
          }
        />

        {/* Protected route: PDP generation */}
        <Route
          path="/pdp-generation"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated}>
              <PdpGeneration
                token={token}
                selectedClient={selectedClient}
                setSelectedClient={setSelectedClient}
                isAdmin={isAdmin}
              />
            </PrivateRoute>
          }
        />

        {/* Protected route: Prompt Builder */}
        <Route
          path="/prompt-builder"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated}>
              <PromptBuilder token={token} />
            </PrivateRoute>
          }
        />

        {/* Protected route: Image Generation */}
        <Route
          path="/image-generation"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated}>
              <ImageGeneration
                isAdmin={isAdmin}
                token={token}
                selectedClient={selectedClient}
                setSelectedClient={setSelectedClient}
              />
            </PrivateRoute>
          }
        />

        {/* Protected route: Prompt Builder PDP */}
        <Route
          path="/prompt-builder-pdp"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated}>
              <PromptBuilderPdp 
                token={token} 
                isAdmin={isAdmin} 
                selectedClient={selectedClient}
                setSelectedClient={setSelectedClient}
                selectedPromptCombination={selectedPromptCombination}
                setSelectedPromptCombination={setSelectedPromptCombination}
              />
            </PrivateRoute>
          }
        />

        {/* Protected route: Image Generation Scheduler */}
        <Route
          path="/image-generation-scheduler"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated}>
              <ImageGenerationScheduler
                isAdmin={isAdmin}
                token={token}
                selectedClient={selectedClient}
                setSelectedClient={setSelectedClient}
              />
            </PrivateRoute>
          }
        />

        {/* Protected route: Generation Input */}
        <Route
          path="/generation-input"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated}>
              <GenerationInput
                token={token}
                selectedClient={selectedClient}
                setSelectedClient={setSelectedClient}
                isAdmin={isAdmin}
              />
            </PrivateRoute>
          }
        />

        {/* Protected route: CustomAttributes */}
        <Route
          path="/custom-attributes"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated}>
              <CustomAttributes token={token} isAdmin={isAdmin} />
            </PrivateRoute>
          }
        />

        {/* Fallback: redirect root to /login (could also redirect to /preview if desired) */}
        <Route path="/" element={<Navigate to="/login" />} />
      </Routes>
    </PdpNavigationContext.Provider>
  );
}

export default App;
