import React, { useEffect, useState, useRef } from 'react'; 
import Select from 'react-select';
import { FaTimes, FaPlus } from 'react-icons/fa';
import styles from './ManageProjectModal.module.css';

const ManageProjectModal = ({ projectName, token, onClose }) => {
  const modalRef = useRef(null);
  const [allUsers, setAllUsers] = useState([]);       // Full list of users in your system
  const [projectUsers, setProjectUsers] = useState([]); // Users who already have access
  const [currentUser, setCurrentUser] = useState('');   // JWT identity from backend
  const [loading, setLoading] = useState(false);
  const [selectedUserToAdd, setSelectedUserToAdd] = useState(null);

  // Fetch both sets of data on mount
  useEffect(() => {
    if (!projectName || !token) return;

    const fetchData = async () => {
      setLoading(true);
      try {
        const resAll = await fetch(
          `${process.env.REACT_APP_API_URL_CORE_VARIANTS}/list_all_users`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        const dataAll = await resAll.json();

        const resProj = await fetch(
          `${process.env.REACT_APP_API_URL_CORE_VARIANTS}/get_project_users?projectName=${encodeURIComponent(
            projectName
          )}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        const dataProj = await resProj.json();

        setAllUsers(dataAll.users || []);
        setProjectUsers(dataProj.users || []);
        setCurrentUser(dataProj.currentUser || '');
      } catch (err) {
        console.error('Error loading user data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [projectName, token]);

  // Close modal on clicks outside the modal content or React‑Select dropdown
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(e.target) &&
        !e.target.closest('.custom-select__menu')
      ) {
        onClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  // Build options excluding users already having access
  const userOptions = allUsers
    .filter((u) => !projectUsers.includes(u))
    .map((u) => ({ value: u, label: u }));

  // Handler to add (share) access
  const handleAddUser = async () => {
    if (!selectedUserToAdd) return;
    const confirmMsg = `Add user "${selectedUserToAdd.value}" to project "${projectName}"?`;
    if (!window.confirm(confirmMsg)) return;

    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL_CORE_VARIANTS}/share_project`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            projectName,
            targetUser: selectedUserToAdd.value,
          }),
        }
      );
      if (!res.ok) {
        const errData = await res.json();
        alert(`Error sharing project: ${errData.error}`);
        return;
      }
      alert(`Successfully added ${selectedUserToAdd.value} to the project!`);
      setProjectUsers((prev) => [...prev, selectedUserToAdd.value]);
      setSelectedUserToAdd(null);
    } catch (err) {
      console.error('Error sharing project:', err);
      alert('Error sharing project.');
    }
  };

  // Handler to remove (unshare) access
  const handleRemoveUser = async (userToRemove) => {
    const confirmMsg = `Remove user "${userToRemove}" from project "${projectName}"?`;
    if (!window.confirm(confirmMsg)) return;

    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL_CORE_VARIANTS}/unshare_project`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ projectName, targetUser: userToRemove }),
        }
      );
      if (!res.ok) {
        const errData = await res.json();
        alert(`Error removing user: ${errData.error}`);
        return;
      }
      alert(`Successfully removed ${userToRemove} from the project!`);
      setProjectUsers((prev) => prev.filter((u) => u !== userToRemove));
    } catch (err) {
      console.error('Error removing user:', err);
      alert('Error removing user.');
    }
  };

  // Custom React‑Select styles ensuring the dropdown is rendered on top
  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: '#E0E1EB',
      boxShadow: 'none',
      '&:hover': { borderColor: '#E0E1EB' },
      fontFamily: 'Lexend Deca, sans-serif',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? '#E0E1EB'
        : state.isSelected
        ? '#4758EB'
        : '#fff',
      color: state.isSelected ? '#fff' : '#292B3D',
      fontFamily: 'Lexend Deca, sans-serif',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#292B3D',
      fontFamily: 'Lexend Deca, sans-serif',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 1100,
      fontFamily: 'Lexend Deca, sans-serif',
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 1100,
    }),
  };

  // Render the user list with currentUser always first and without a remove button
  const renderUserList = () => {
    if (projectUsers.length === 0) {
      return <p>No users have access (besides you, presumably).</p>;
    }

    // Separate current user and others
    const otherUsers = projectUsers.filter((user) => user !== currentUser);

    return (
      <ul className={styles.userList}>
        {projectUsers.includes(currentUser) && (
          <li key={currentUser} className={`${styles.userItem} ${styles.currentUser}`}>
            <span>{currentUser}</span>
          </li>
        )}
        {otherUsers.map((user) => (
          <li key={user} className={styles.userItem}>
            <span>{user}</span>
            <button
              className={styles.removeButton}
              onClick={() => handleRemoveUser(user)}
              title="Remove user"
            >
              <FaTimes />
            </button>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent} ref={modalRef}>
        <div className={styles.header}>
          <h3>Manage Project Access: {projectName}</h3>
        </div>
        {loading ? (
          <div className={styles.loadingText}>Loading data...</div>
        ) : (
          <>
            <div className={styles.section}>
              <div className={styles.sectionTitle}>Current Users With Access</div>
              {renderUserList()}
            </div>
            <div className={styles.section}>
              <div className={styles.sectionTitle}>Add a New User</div>
              <div className={styles.addUserSection}>
                <div className={styles.selectContainer}>
                  <Select
                    value={selectedUserToAdd}
                    onChange={setSelectedUserToAdd}
                    options={userOptions}
                    isClearable
                    placeholder="Select a user to add..."
                    styles={customSelectStyles}
                    classNamePrefix="custom-select"
                    menuPortalTarget={document.body}
                  />
                </div>
                <button
                  className={styles.addUserButton}
                  onClick={handleAddUser}
                  disabled={!selectedUserToAdd}
                  title="Add user"
                >
                  <FaPlus />
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ManageProjectModal;
