import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { 
  FaTimes, 
  FaPlus, 
  FaDownload, 
  FaSyncAlt, 
  FaChevronRight, 
  FaChevronDown, 
  FaExclamationTriangle 
} from 'react-icons/fa';
import styles from './css/PromptBuilder.module.css';
import axios from 'axios';
import SaveOptionsModal from './SaveOptionsModal';
import ImportPromptModal from './ImportPromptModal';
import { ClipLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';

// CollapsibleSection Component
const CollapsibleSection = ({ title, children, onReset, isGenerating }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleOpen = () => setIsOpen(!isOpen);
  const handleResetClick = (event) => {
    event.stopPropagation();
    onReset();
  };

  return (
    <div className={styles.collapsibleSection}>
      <div
        className={styles.collapsibleHeader}
        onClick={toggleOpen}
        role="button"
        aria-expanded={isOpen}
        tabIndex={0}
        onKeyPress={(e) => e.key === 'Enter' && toggleOpen()}
      >
        <span className={styles.indicator}>
          {isOpen ? <FaChevronDown /> : <FaChevronRight />}
        </span>
        <h3>{title}</h3>
        <button
          className={styles.resetButton}
          onClick={handleResetClick}
          aria-label={`Reset ${title}`}
          disabled={isGenerating}
        >
          <FaSyncAlt />
        </button>
      </div>
      {isOpen && <div className={styles.collapsibleContent}>{children}</div>}
    </div>
  );
};

// PromptBuilder Component
const PromptBuilder = ({ token }) => {
  const [state, setState] = useState({
    systemPrompt: '',
    imagePrompt: '',
    instructionPrompt: '',
    fewShots: [],
    clients: [],
    selectedClient: '',
    selectedCombination: '',
    unsavedChanges: false,
    promptCombinations: [],
    showSaveOptions: false,
    currentCombinationName: '',
    showPopup: false,
    popupIndex: null,
    popupId: '',
    selectedId: '',
    productDetails: null,
    generatedAttributes: null,
    loadingProductDetails: false,
    loadingGeneratedAttributes: false,
    error: null,
    isGenerating: false,
    showImportOptions: false,
    imageWarning: null, // **Added State Property**
  });

  const navigate = useNavigate();

  const relevantAttributes = [
    'title', 'description', 'product_type', 'brand', 'color', 'gender', 'pattern',
    'material', 'age_group', 'size',
    'product_highlight1', 'product_highlight2', 'product_highlight3', 'product_highlight4', 'product_highlight5'
  ];

  // Function to fetch default prompts
  const fetchDefaultPrompts = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/default-prompts`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const data = response.data;
      return {
        systemPrompt: data.SystemPrompt || '',
        imagePrompt: data.ImagePrompt || '',
        instructionPrompt: data.InstructionPrompt || '',
        fewShots: data.FewShotsInputPrompt && data.FewShotsOutputPrompt ? [{
          input: {
            title: data.FewShotsInputPrompt.title || '',
            desc: data.FewShotsInputPrompt.desc || ''
          },
          output: {
            title: data.FewShotsOutputPrompt.title || '',
            desc: data.FewShotsOutputPrompt.desc || ''
          }
        }] : []
      };
    } catch (err) {
      console.error("Error fetching default prompts:", err);
      return {};
    }
  };

  // Function to fetch blank prompts
  const fetchBlankPrompts = async () => {
    return {
      systemPrompt: '',
      imagePrompt: '',
      instructionPrompt: '',
      fewShots: []
    };
  };

  // useEffect to fetch initial data
  useEffect(() => {
    const savedClient = localStorage.getItem('selectedClient');
    const savedCombination = localStorage.getItem('selectedCombination');

    const fetchInitialData = async () => {
      const defaultPrompts = await fetchDefaultPrompts();
      setState((prevState) => ({ ...prevState, instructionPrompt: defaultPrompts.instructionPrompt }));

      const fetchClients = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/client-data`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          console.log("Fetched clients:", response.data);
          setState((prevState) => ({ ...prevState, clients: response.data }));

          if (savedClient) {
            const clientData = response.data.find(client => client.client_name === savedClient);
            if (clientData) {
              setState((prevState) => ({
                ...prevState,
                selectedClient: savedClient,
                promptCombinations: clientData.prompt_combinations || [],
                unsavedChanges: false
              }));

              if (savedCombination) {
                const combination = clientData.prompt_combinations.find(c => c.combination_id === savedCombination);
                if (combination) {
                  setState((prevState) => ({
                    ...prevState,
                    selectedCombination: savedCombination,
                    systemPrompt: combination.system_prompt,
                    imagePrompt: combination.image_prompt,
                    instructionPrompt: combination.instruction_prompt,
                    fewShots: combination.few_shots,
                    currentCombinationName: combination.combination_name,
                  }));
                }
              }
            }
          }
        } catch (err) {
          console.error("Error fetching clients:", err);
        }
      };

      fetchClients();
    };

    fetchInitialData();
  }, [token]);

  // Handler for client change
  const handleClientChange = async (event) => {
    if (state.unsavedChanges) {
      const confirmChange = window.confirm("You have unsaved changes. Do you really want to switch clients?");
      if (!confirmChange) {
        return;
      }
    }
    const clientName = event.target.value;
    setState((prevState) => ({
      ...prevState,
      selectedClient: clientName,
      selectedCombination: '',
      promptCombinations: [],
      showSaveOptions: false,
      productDetails: null,
      generatedAttributes: null,
      selectedId: '',
      imageWarning: null, // **Reset Image Warning**
    }));

    localStorage.setItem('selectedClient', clientName); // Save to localStorage

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/client-data`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const clientData = response.data.find(client => client.client_name === clientName);
      if (clientData) {
        setState((prevState) => ({
          ...prevState,
          promptCombinations: clientData.prompt_combinations || []
        }));
      } else {
        const defaultPrompts = await fetchDefaultPrompts();
        setState((prevState) => ({
          ...prevState,
          systemPrompt: defaultPrompts.systemPrompt,
          imagePrompt: defaultPrompts.imagePrompt,
          instructionPrompt: defaultPrompts.instructionPrompt,
          fewShots: defaultPrompts.fewShots
        }));
      }
      setState((prevState) => ({ ...prevState, unsavedChanges: false }));
    } catch (err) {
      console.error("Error fetching client data:", err);
    }
  };


// Creates a new few shot from the currently displayed productDetails & generatedAttributes
const handleAddAsFewShot = () => {
  if (!state.productDetails || !state.generatedAttributes) return;

  const { productDetails, generatedAttributes } = state;

  const newFewShot = {
    input: {
      title: productDetails.title || "",
      desc: productDetails.description || "",
      product_type: productDetails.product_type || "",
      brand: productDetails.brand || "",
      color: productDetails.color || "",
      gender: productDetails.gender || "",
      pattern: productDetails.pattern || "",
      material: productDetails.material || "",
      age_group: productDetails.age_group || "",
      size: productDetails.size || "",
      product_highlight1: productDetails.product_highlight1 || "",
      product_highlight2: productDetails.product_highlight2 || "",
      product_highlight3: productDetails.product_highlight3 || "",
      product_highlight4: productDetails.product_highlight4 || "",
      product_highlight5: productDetails.product_highlight5 || "",
    },
    output: {
      title: generatedAttributes.qfe_title || "",
      desc: generatedAttributes.qfe_description || "",
      product_type: generatedAttributes.qfe_product_type || "",
      brand: generatedAttributes.qfe_brand || "",
      color: generatedAttributes.qfe_color || "",
      gender: generatedAttributes.qfe_gender || "",
      pattern: generatedAttributes.qfe_pattern || "",
      material: generatedAttributes.qfe_material || "",
      age_group: generatedAttributes.qfe_age_group || "",
      size: generatedAttributes.qfe_size || "",
      product_highlight1: generatedAttributes.qfe_product_highlight1 || "",
      product_highlight2: generatedAttributes.qfe_product_highlight2 || "",
      product_highlight3: generatedAttributes.qfe_product_highlight3 || "",
      product_highlight4: generatedAttributes.qfe_product_highlight4 || "",
      product_highlight5: generatedAttributes.qfe_product_highlight5 || "",
    },
  };

  // Append this new few shot to the existing list
  setState((prevState) => ({
    ...prevState,
    fewShots: [...prevState.fewShots, newFewShot],
    unsavedChanges: true,
  }));
};


  // Handler for combination change
  const handleCombinationChange = async (event) => {
    if (state.unsavedChanges) {
      const confirmChange = window.confirm("You have unsaved changes. Do you really want to switch prompt combinations?");
      if (!confirmChange) {
        return;
      }
    }
    const combinationId = event.target.value;
    setState((prevState) => ({
      ...prevState,
      selectedCombination: combinationId,
      productDetails: null,
      generatedAttributes: null,
      selectedId: '',
      imageWarning: null, // **Reset Image Warning**
    }));

    localStorage.setItem('selectedCombination', combinationId); // Save to localStorage

    if (combinationId) {
      const combination = state.promptCombinations.find(c => c.combination_id === combinationId);
      if (combination) {
        setState((prevState) => ({
          ...prevState,
          systemPrompt: combination.system_prompt,
          imagePrompt: combination.image_prompt,
          instructionPrompt: combination.instruction_prompt,
          fewShots: combination.few_shots,
          currentCombinationName: combination.combination_name,
        }));
      }
    } else {
      const defaultPrompts = await fetchDefaultPrompts();
      setState((prevState) => ({
        ...prevState,
        systemPrompt: defaultPrompts.systemPrompt,
        imagePrompt: defaultPrompts.imagePrompt,
        instructionPrompt: defaultPrompts.instructionPrompt,
        fewShots: defaultPrompts.fewShots
      }));
    }
    setState((prevState) => ({ ...prevState, unsavedChanges: false }));
  };

  // Handler to delete a combination
  const handleDeleteCombination = async (combinationId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this prompt combination?");
    if (!confirmDelete) {
      return;
    }

    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/delete-prompt-combination/${combinationId}?client_name=${state.selectedClient}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setState((prevState) => ({
        ...prevState,
        promptCombinations: prevState.promptCombinations.filter(c => c.combination_id !== combinationId),
        selectedCombination: '',
        systemPrompt: '',
        imagePrompt: '',
        instructionPrompt: '',
        fewShots: [],
        imageWarning: null, // **Reset Image Warning**
      }));
      alert('Prompt combination deleted successfully');
    } catch (err) {
      console.error("Error deleting prompt combination:", err);
      alert('Error deleting prompt combination');
    }
  };

  // Function to fetch product details
  const fetchProductDetails = async (id) => {
    setState((prevState) => ({ ...prevState, loadingProductDetails: true, error: null, imageWarning: null })); // **Reset Image Warning**
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/fetch-product-details`, {
        selected_id: id,
        client_name: state.selectedClient
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setState((prevState) => ({ ...prevState, productDetails: response.data.data }));
    } catch (error) {
      console.error('Error fetching product details:', error);
      setState((prevState) => ({ ...prevState, error: 'Error fetching product details: ' + error.message }));
    } finally {
      setState((prevState) => ({ ...prevState, loadingProductDetails: false }));
    }
  };

  // Handler to generate preview
  const handleGeneratePreview = async () => {
    setState((prevState) => ({
      ...prevState,
      productDetails: null,
      generatedAttributes: null,
      loadingProductDetails: true,
      loadingGeneratedAttributes: true,
      error: null,
      isGenerating: true,
      imageWarning: null, // Reset Image Warning
    }));
  
    let idToUse = state.selectedId;
    if (!state.selectedId) {
      idToUse = await fetchFirstIdForClient(state.selectedClient);
      if (!idToUse) {
        setState((prevState) => ({
          ...prevState,
          loadingProductDetails: false,
          loadingGeneratedAttributes: false,
          isGenerating: false,
        }));
        return;
      }
    }
  
    try {
      await fetchProductDetails(idToUse);
      setState((prevState) => ({ ...prevState, loadingProductDetails: false }));
  
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/generate-qfe-preview`,
        {
          selected_id: idToUse,
          instruction_prompt: state.instructionPrompt,
          system_prompt: state.systemPrompt,
          image_prompt: state.imagePrompt,
          few_shots: state.fewShots,
          client_name: state.selectedClient,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
  
      console.log('Preview generated:', response.data);
  
      // Capture image_warning from the response
      const { result } = response.data;
      const { data, message: imageWarning } = result; // Extract 'message' as 'imageWarning'
  
      setState((prevState) => ({
        ...prevState,
        generatedAttributes: data,
        loadingGeneratedAttributes: false,
        isGenerating: false,
        imageWarning: imageWarning || null, // Update Image Warning
      }));
    } catch (error) {
      console.error('Error generating preview:', error);
      const errorMessage = error.response?.data?.message || 'Error generating preview';
      setState((prevState) => ({
        ...prevState,
        error: errorMessage,
        loadingGeneratedAttributes: false,
        isGenerating: false,
        imageWarning: null, // Reset Image Warning on Error
      }));
    }
  };

  // Handler to create a new prompt
  const handleCreateNewPrompt = async () => {
    if (state.unsavedChanges) {
      const confirmChange = window.confirm("You have unsaved changes. Do you really want to create a new prompt?");
      if (!confirmChange) {
        return;
      }
    }
    setState((prevState) => ({ ...prevState, selectedCombination: '' }));
    const blankPrompts = await fetchBlankPrompts();
    setState((prevState) => ({
      ...prevState,
      systemPrompt: blankPrompts.systemPrompt,
      imagePrompt: blankPrompts.imagePrompt,
      instructionPrompt: blankPrompts.instructionPrompt,
      fewShots: blankPrompts.fewShots,
      unsavedChanges: false,
      showSaveOptions: true,
      currentCombinationName: '',
      imageWarning: null, // **Reset Image Warning**
    }));
  };

  // Handler to save prompt combination
  const handleSave = async (overwrite, newCombinationName) => {
    if (!state.selectedClient) {
      alert('Please select a client.');
      return;
    }

    const combinationName = overwrite ? state.currentCombinationName : newCombinationName;

    if (!combinationName) {
      alert('Please provide a name for the new prompt combination.');
      return;
    }

    const combinationId = overwrite ? state.selectedCombination : null;

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/save-prompt-combination`, {
        client_name: state.selectedClient,
        combination_id: combinationId,
        combination_name: combinationName,
        system_prompt: state.systemPrompt,
        image_prompt: state.imagePrompt,
        instruction_prompt: state.instructionPrompt,
        few_shots: state.fewShots
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });

      if (overwrite) {
        const updatedCombinations = state.promptCombinations.map(c =>
          c.combination_id === combinationId ? {
            ...c, 
            system_prompt: state.systemPrompt,
            image_prompt: state.imagePrompt,
            instruction_prompt: state.instructionPrompt,
            few_shots: state.fewShots
          } : c
        );
        setState((prevState) => ({ ...prevState, promptCombinations: updatedCombinations }));
      } else {
        const newCombination = {
          combination_id: response.data.combination_id,
          combination_name: combinationName,
          system_prompt: state.systemPrompt,
          image_prompt: state.imagePrompt,
          instruction_prompt: state.instructionPrompt,
          few_shots: state.fewShots
        };
        setState((prevState) => ({
          ...prevState,
          promptCombinations: [...prevState.promptCombinations, newCombination],
          selectedCombination: newCombination.combination_id,
          currentCombinationName: combinationName,
        }));
      }

      alert('Prompt combination saved successfully');
      setState((prevState) => ({ ...prevState, unsavedChanges: false, showSaveOptions: false }));
    } catch (err) {
      console.error("Error saving prompt combination:", err);
      alert('Error saving prompt combination');
    }
  };

  // Handler for prompt changes
  const handlePromptChange = (type) => (value) => {
    setState((prevState) => ({ ...prevState, [type]: value, unsavedChanges: true }));
  };

  // Handler for few-shot prompt changes
  const handleFewShotChange = (index, field, subfield, value) => {
    const newFewShots = [...state.fewShots];
    newFewShots[index] = {
      ...newFewShots[index],
      [field]: {
        ...newFewShots[index][field],
        [subfield]: value
      }
    };
    setState((prevState) => ({ ...prevState, fewShots: newFewShots, unsavedChanges: true }));
  };

  // Function to fetch the first ID for a client
  const fetchFirstIdForClient = async (clientName) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/first-id/${clientName}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      return response.data.firstId;
    } catch (error) {
      console.error('Error fetching first ID for client:', error);
      setState((prevState) => ({ ...prevState, error: 'Error fetching first ID for client: ' + error.message }));
      return null;
    }
  };

  // Handler to add a few-shot prompt
  const handleAddFewShot = () => {
    setState((prevState) => ({
      ...prevState,
      fewShots: [...prevState.fewShots, {
        input: {
          title: '',
          desc: '',
          product_type: '',
          brand: '',
          color: '',
          gender: '',
          pattern: '',
          material: '',
          age_group: '',
          size: '',
          product_highlight1: '',
          product_highlight2: '',
          product_highlight3: '',
          product_highlight4: '',
          product_highlight5: ''
        },
        output: {
          title: '',
          desc: ''
        }
      }],
      unsavedChanges: true
    }));
  };

  // Handler to remove a few-shot prompt
  const handleRemoveFewShot = (index) => {
    const newFewShots = state.fewShots.filter((_, i) => i !== index);
    setState((prevState) => ({ ...prevState, fewShots: newFewShots, unsavedChanges: true }));
  };

  // Handler to reset a prompt type
  const handleResetPrompt = (type) => async () => {
    const defaultPrompts = await fetchDefaultPrompts();
    setState((prevState) => ({ 
      ...prevState, 
      [type]: defaultPrompts[type], 
      unsavedChanges: true, 
      imageWarning: null 
    }));
  };

  // Handler to import few-shot input
  const handleImport = async (index, id, clientName) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/few-shot-input/${id}?client_name=${clientName}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const data = response.data;
      const newFewShots = [...state.fewShots];
      newFewShots[index] = {
        input: {
          title: data.title,
          desc: data.description,
          product_type: data.product_type,
          brand: data.brand,
          color: data.color,
          gender: data.gender,
          pattern: data.pattern,
          material: data.material,
          age_group: data.age_group,
          size: data.size,
          product_highlight1: data.product_highlight1,
          product_highlight2: data.product_highlight2,
          product_highlight3: data.product_highlight3,
          product_highlight4: data.product_highlight4,
          product_highlight5: data.product_highlight5
        },
        output: {
          title: data.output_title,
          desc: data.output_desc
        }
      };
      setState((prevState) => ({ ...prevState, fewShots: newFewShots }));
    } catch (err) {
      console.error("Error fetching few shot input by ID:", err);
      alert('Error fetching data');
    }
  };

  // Handler to import a prompt combination
  const handleImportPromptCombination = async (sourceClientName, combinationId, newCombinationName) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/import-prompt-combination`, {
        client_name: state.selectedClient,
        source_client_name: sourceClientName,
        combination_id: combinationId,
        new_combination_name: newCombinationName
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
  
      const newCombination = {
        combination_id: response.data.combination_id,
        combination_name: newCombinationName,
        ...response.data.prompt_combination_data
      };
  
      setState((prevState) => ({
        ...prevState,
        promptCombinations: [...prevState.promptCombinations, newCombination],
        selectedCombination: newCombination.combination_id,
        currentCombinationName: newCombinationName,
        showImportOptions: false,
      }));
  
      // Fetch the prompts from the newly imported data
      setState((prevState) => ({
        ...prevState,
        systemPrompt: newCombination.system_prompt,
        imagePrompt: newCombination.image_prompt,
        instructionPrompt: newCombination.instruction_prompt,
        fewShots: newCombination.few_shots,
      }));
  
      alert('Prompt combination imported successfully');
    } catch (err) {
      console.error("Error importing prompt combination:", err);
      alert('Error importing prompt combination');
    }
  };

  const existingCombinationNames = state.promptCombinations.map(c => c.combination_name);

  return (
    <div className={styles.promptBuilderContainer}>
      {/* Back Button */}
      <button onClick={() => navigate(-1)} className={styles.backButton}>
        <i className="fas fa-arrow-left"></i>
      </button>

      {/* Prompt Builder Options */}
      <div className={styles.promptBuilderOptions}>
        <h2>Prompt Builder</h2>

        {/* Client Selector */}
        <div className={styles.clientSelector}>
          <label htmlFor="client">Select Client:</label>
          <select 
            id="client" 
            value={state.selectedClient} 
            onChange={handleClientChange} 
            disabled={state.isGenerating}
          >
            <option value="">Select a client</option>
            {state.clients.map(client => (
              <option key={client.client_name} value={client.client_name}>
                {client.client_name}
              </option>
            ))}
          </select>
        </div>

        {/* Prompt Combination Selector */}
        {state.selectedClient && (
          <div className={styles.clientSelector}>
            <label htmlFor="combination">Select Prompt Combination:</label>
            <div className={styles.combinationSelector}>
              <select 
                id="combination" 
                value={state.selectedCombination} 
                onChange={handleCombinationChange} 
                disabled={state.isGenerating}
              >
                <option value="">Select a combination</option>
                {state.promptCombinations.map(combination => (
                  <option key={combination.combination_id} value={combination.combination_id}>
                    {combination.combination_name}
                  </option>
                ))}
              </select>
              {state.selectedCombination && (
                <button 
                  className={styles.deleteButton} 
                  onClick={() => handleDeleteCombination(state.selectedCombination)} 
                  disabled={state.isGenerating}
                  title="Delete Prompt Combination"
                >
                  <FaTimes />
                </button>
              )}
              <button 
                className={styles.importButton} 
                onClick={() => setState((prevState) => ({ ...prevState, showImportOptions: true }))} 
                disabled={state.isGenerating}
                title="Import Prompt Combination"
              >
                <FaDownload />
              </button>
              <button 
                className={styles.addButton} 
                onClick={handleCreateNewPrompt} 
                disabled={state.isGenerating}
                title="Create New Prompt Combination"
              >
                <FaPlus />
                <span>Create new Prompt</span>
              </button>
            </div>
          </div>
        )}

        {/* Collapsible Sections for Prompts */}
        {state.selectedClient && state.selectedCombination && (
          <>
            {/* System Prompt Section */}
            <CollapsibleSection 
              title="System Prompt" 
              onReset={handleResetPrompt('systemPrompt')} 
              isGenerating={state.isGenerating}
            >
              <div className={styles.reactQuillContainer}>
                <ReactQuill
                  value={state.systemPrompt}
                  onChange={handlePromptChange('systemPrompt')}
                  className={styles.reactQuillEditor}
                  modules={{ toolbar: false }}
                  readOnly={state.isGenerating}
                />
              </div>
            </CollapsibleSection>

            {/* Image Prompt Section */}
            <CollapsibleSection 
              title="Image Prompt" 
              onReset={handleResetPrompt('imagePrompt')} 
              isGenerating={state.isGenerating}
            >
              <div className={styles.reactQuillContainer}>
                <ReactQuill
                  value={state.imagePrompt}
                  onChange={handlePromptChange('imagePrompt')}
                  className={styles.reactQuillEditor}
                  modules={{ toolbar: false }}
                  readOnly={state.isGenerating}
                />
              </div>
            </CollapsibleSection>

            {/* Instruction Text Prompt Section */}
            <CollapsibleSection 
              title="Instruction Text Prompt" 
              onReset={handleResetPrompt('instructionPrompt')} 
              isGenerating={state.isGenerating}
            >
              <div className={styles.reactQuillContainer}>
                <ReactQuill
                  value={state.instructionPrompt}
                  onChange={handlePromptChange('instructionPrompt')}
                  className={styles.reactQuillEditor}
                  modules={{ toolbar: false }}
                  readOnly={state.isGenerating}
                />
              </div>
            </CollapsibleSection>

            {/* Few Shot Prompts Section */}
            <CollapsibleSection 
              title="Few Shot Prompts" 
              onReset={handleResetPrompt('fewShots')} 
              isGenerating={state.isGenerating}
            >
              {state.fewShots.map((fewShot, index) => (
                <div key={index} className={styles.fewShotPrompt}>
                  <button 
                    className={styles.removeButton} 
                    onClick={() => handleRemoveFewShot(index)} 
                    disabled={state.isGenerating}
                    title="Remove Few Shot Prompt"
                  >
                    <FaTimes />
                  </button>
                  <div className={styles.fewShotRow}>
                    <div className={styles.headerContainer}>
                      <div className={styles.fewShotHeader}>
                        <button 
                          className={styles.addButton} 
                          onClick={() => setState((prevState) => ({
                            ...prevState,
                            popupIndex: index,
                            showPopup: true
                          }))} 
                          disabled={state.isGenerating}
                          title="Import Few Shot Input"
                        >
                          <FaPlus />
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* Few Shot Prompt Fields */}
                  <div className={styles.fewShotRow}>
                    <div className={styles.fewShotAttribute}>
                      <label>Title</label>
                    </div>
                    <div className={styles.fewShotField}>
                      <input
                        type="text"
                        value={fewShot.input.title}
                        onChange={(e) => handleFewShotChange(index, 'input', 'title', e.target.value)}
                        disabled={state.isGenerating}
                        placeholder="Input Title"
                      />
                    </div>
                    <div className={styles.fewShotField}>
                      <input
                        type="text"
                        value={fewShot.output.title}
                        onChange={(e) => handleFewShotChange(index, 'output', 'title', e.target.value)}
                        disabled={state.isGenerating}
                        placeholder="Output Title"
                      />
                    </div>
                  </div>

                  <div className={styles.fewShotRow}>
                    <div className={styles.fewShotAttribute}>
                      <label>Desc</label>
                    </div>
                    <div className={styles.fewShotField}>
                      <input
                        type="text"
                        value={fewShot.input.desc}
                        onChange={(e) => handleFewShotChange(index, 'input', 'desc', e.target.value)}
                        disabled={state.isGenerating}
                        placeholder="Input Description"
                      />
                    </div>
                    <div className={styles.fewShotField}>
                      <input
                        type="text"
                        value={fewShot.output.desc}
                        onChange={(e) => handleFewShotChange(index, 'output', 'desc', e.target.value)}
                        disabled={state.isGenerating}
                        placeholder="Output Description"
                      />
                    </div>
                  </div>

                  {/* Additional Fields */}
                  {[
                    'product_type', 'brand', 'color', 'gender', 'pattern', 'material',
                    'age_group', 'size',
                    'product_highlight1', 'product_highlight2', 'product_highlight3',
                    'product_highlight4', 'product_highlight5'
                  ].map((field) => (
                    <div className={styles.fewShotRow} key={field}>
                      <div className={styles.fewShotAttribute}>
                        <label>{field.replace(/_/g, ' ')}</label>
                      </div>
                      <div className={styles.fewShotField}>
                        <input
                          type="text"
                          value={fewShot.input[field]}
                          onChange={(e) => handleFewShotChange(index, 'input', field, e.target.value)}
                          disabled={state.isGenerating}
                          placeholder={`Input ${field.replace(/_/g, ' ')}`}
                        />
                      </div>
                      <div className={styles.fewShotField}>
                        <input
                          type="text"
                          value={fewShot.output[field]}
                          onChange={(e) => handleFewShotChange(index, 'output', field, e.target.value)}
                          disabled={state.isGenerating}
                          placeholder={`Output ${field.replace(/_/g, ' ')}`}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              ))}
              <button 
                className={styles.addButton} 
                onClick={handleAddFewShot} 
                disabled={state.isGenerating}
                title="Add Few Shot Prompt"
              >
                <FaPlus />
                Add Few Shot Prompt
              </button>
            </CollapsibleSection>
          </>
        )}

        {/* Save Button for Unsaved Changes */}
        {state.unsavedChanges && state.selectedClient && state.selectedCombination && (
          <button
            className={styles.saveButtonUnsaved}
            onClick={() => setState((prevState) => ({ ...prevState, showSaveOptions: true }))}
            disabled={!state.selectedClient || state.isGenerating}
            title="Save Current Work"
          >
            Save Current Work
          </button>
        )}
      </div>

      {/* Prompt Preview Section */}
      <div className={styles.promptPreview}>
        <h2>Prompt Preview</h2>
        
        {/* Prompt Preview Controls */}
        {state.selectedClient && state.selectedCombination ? (
          <>
            <input
              type="text"
              placeholder="Enter ID or leave empty for the first item"
              value={state.selectedId}
              onChange={(e) => setState((prevState) => ({ ...prevState, selectedId: e.target.value }))}
              disabled={state.isGenerating}
              className={styles.idInput}
            />
            <button 
              onClick={handleGeneratePreview} 
              disabled={state.isGenerating} 
              className={styles.generateButton}
              title="Generate Preview"
            >
              Generate Preview
            </button>
          </>
        ) : (
          <p className={styles.selectPromptMessage}>Select client and prompt combination to use the prompt previewer</p>
        )}
        {state.error && <p className={styles.error}>{state.error}</p>}  {/* Existing Error Display */}

        {/* Loading Product Details */}
        {state.loadingProductDetails ? (
          <div className={styles.loadingContainer}>
            <ClipLoader />
            <p>Loading product details...</p>
          </div>
        ) : (
          state.productDetails && (
            <div className={styles.productDetailsBox}>
              <img 
                src={state.productDetails.image_link} 
                alt={state.productDetails.title} 
                className={styles.productImage} 
              />
              <div className={styles.productInfo}>
                <p><strong>Item Group ID:</strong> {state.productDetails.item_group_id}</p>
                <p><strong>ID:</strong> {state.productDetails.id}</p>
                <p><strong>Title:</strong> {state.productDetails.title}</p>
                <p><strong>Brand:</strong> {state.productDetails.brand}</p>
                {/* Add more product details as needed */}
              </div>
            </div>
          )
        )}

        {/* Image Warning Message Positioned Here */}
        {state.imageWarning && (
          <div className={styles.imageWarning}>
            <FaExclamationTriangle className={styles.warningIcon} />
            <span>
              {state.imageWarning} It's recommended to test prompts with IDs that have valid image links to ensure optimal results.
            </span>
          </div>
        )}

        {/* Loading Generated Attributes */}
        {state.loadingGeneratedAttributes ? (
          <div className={styles.loadingContainer}>
            <ClipLoader />
            <p>Generating attributes...</p>
          </div>
        ) : (
          state.productDetails && state.generatedAttributes && (
            <div className={styles.attributeComparison}>
            {/* Header row that holds the section title and the button */}
            <div className={styles.attributeHeader}>
              <h3>Attributes Comparison</h3>
              <button
                onClick={handleAddAsFewShot}
                disabled={state.isGenerating}
                className={styles.addFewShotButton}
                title="Add as Few Shot"
              >
                <FaPlus />
                Add as Few Shot
              </button>
            </div>
          
            {/* Sub-row for Original vs. Generated headings */}
            <div className={styles.attributeRow}>
              <div className={styles.attributeColumn}>
                <h4>Original Attributes</h4>
              </div>
              <div className={styles.attributeColumn}>
                <h4>Generated Attributes</h4>
              </div>
            </div>
          
            {/* Rows showing the attribute pairs */}
            {relevantAttributes.map((attr) => (
              <div className={styles.attributePair} key={attr}>
                <p className={styles.attributeOriginal}>
                  <strong>{attr.replace(/_/g, ' ')}:</strong> {state.productDetails[attr] || 'No data'}
                </p>
                <p className={styles.attributeGenerated}>
                  <strong>{attr.replace(/_/g, ' ')}:</strong> {state.generatedAttributes[`qfe_${attr}`] || 'No data'}
                </p>
              </div>
            ))}
          </div>
          
          )
        )}
      </div>

      {/* Save Options Modal */}
      <SaveOptionsModal
        isOpen={state.showSaveOptions}
        onClose={() => setState((prevState) => ({ ...prevState, showSaveOptions: false }))}
        onSave={handleSave}
        hasExistingCombination={!!state.selectedCombination}
        existingCombinationNames={existingCombinationNames}
        currentCombinationName={state.currentCombinationName}
      />

      {/* Import Prompt Modal */}
      <ImportPromptModal
        isOpen={state.showImportOptions}
        onClose={() => setState((prevState) => ({ ...prevState, showImportOptions: false }))}
        token={token}
        onImport={handleImportPromptCombination}
      />

      {/* Popup for Importing Few Shot Input */}
      {state.showPopup && (
        <div className={styles.popupOverlay}>
          <div className={styles.popupContent}>
            <h3>Import Few Shot Input</h3>
            <input
              type="text"
              placeholder="Enter ID"
              onChange={(e) => setState((prevState) => ({ ...prevState, popupId: e.target.value }))}
              className={styles.popupInput}
            />
            <div className={styles.popupButtons}>
              <button 
                onClick={() => handleImport(state.popupIndex, state.popupId, state.selectedClient)}
                className={styles.popupImportButton}
                disabled={!state.popupId}
                title="Import Few Shot Input"
              >
                Import
              </button>
              <button 
                onClick={() => setState((prevState) => ({ ...prevState, showPopup: false }))} 
                className={styles.popupCloseButton}
                title="Close"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PromptBuilder;
