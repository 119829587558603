import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import {
  FaPlus,
  FaTrash,
  FaUserFriends,
  FaExclamationCircle,
  FaTimes,
} from 'react-icons/fa';
import ClipLoader from 'react-spinners/ClipLoader';
import styles from './CoreVariants.module.css';
import CreateProjectWizard from './CreateProjectWizard/index';
import ManageProjectModal from './ManageProjectModal';

const CoreVariants = ({ token }) => {
  // Projects from Firestore for the current user
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);

  // State to track if the project options are loading
  const [isProjectsLoading, setIsProjectsLoading] = useState(true);

  // Loading and error states for the selected project
  const [isProjectLoading, setIsProjectLoading] = useState(false);
  const [projectError, setProjectError] = useState(null);

  // Score Card States
  const [projectState, setProjectState] = useState(''); // "Active"/"Inactive"
  const [currentStatus, setCurrentStatus] = useState('');
  const [gaStatus, setGaStatus] = useState(''); // "Connected"/"Not Connected"
  const [mcStatus, setMcStatus] = useState(''); // "Connected"/"Not Connected"
  const [errorCount, setErrorCount] = useState(3);

  // Timestamps for "last check"
  const [gaLastCheck, setGaLastCheck] = useState('');
  const [mcLastCheck, setMcLastCheck] = useState('');

  // Metrics (cards 6-10)
  const [totalProducts] = useState(1234);
  const [viableProducts] = useState(900);
  const [unavailableProducts] = useState(334);
  const [unavailablePercentage] = useState(27);
  const [spend] = useState('$5,200');

  // Additional dummy metric placeholders (cards 11-15)
  const [additionalMetric1] = useState(1111);
  const [additionalMetric2] = useState(2222);
  const [additionalMetric3] = useState(3333);
  const [additionalMetric4] = useState(4444);
  const [additionalMetric5] = useState(5555);

  // Modal states
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isAddWizardOpen, setIsAddWizardOpen] = useState(false);
  const [isManageModalOpen, setIsManageModalOpen] = useState(false);

  // State to track if the toggle (activate/deactivate) is in progress
  const [isToggling, setIsToggling] = useState(false);

  // State to control which tab is shown: "status", "metrics", or "settings"
  const [viewMode, setViewMode] = useState('status');

  // Core Variant Settings
  const defaultSettings = {
    min_biggest_variant_pct: 20.0,
    min_variant_pct: 0.01,
    core_variant_sum_threshold: 65.0,
    ratio_threshold_1: 70.0,
    ratio_threshold_2: 50.0,
    ratio_threshold_3: 60.0,
    ratio_threshold_4: 85.0,
    summarized_pct_threshold_1: 50.0,
    summarized_pct_threshold_2: 60.0,
  };
  const [coreVariantSettings, setCoreVariantSettings] = useState(null);
  const [isSavingSettings, setIsSavingSettings] = useState(false);

  // Helper function to format timestamp
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return 'N/A';
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}/${month}-${year} at ${hours}:${minutes}`;
  };

  // 1. Fetch user projects on mount
  useEffect(() => {
    if (!token) return;

    const fetchProjects = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL_CORE_VARIANTS}/list_user_projects`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error(`Error fetching user projects: ${response.statusText}`);
        }
        const data = await response.json();

        // Convert to react-select format
        const projectOptions = data.map((proj) => ({
          value: proj.id,
          label: proj.label,
        }));
        setProjects(projectOptions);
      } catch (err) {
        console.error('Error loading projects:', err);
      } finally {
        setIsProjectsLoading(false);
      }
    };

    fetchProjects();
  }, [token]);

  // 2. Fetch project data when a project is selected
  useEffect(() => {
    if (!selectedProject) {
      // Clear old data if no project is selected
      setProjectState('');
      setCurrentStatus('');
      setGaStatus('');
      setMcStatus('');
      setProjectError(null);
      setGaLastCheck('');
      setMcLastCheck('');
      setCoreVariantSettings(null);
      return;
    }

    const loadProjectData = async () => {
      setIsProjectLoading(true);
      setProjectError(null);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL_CORE_VARIANTS}/get_project_data?projectName=${encodeURIComponent(
            selectedProject.value
          )}`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          const errData = await response.json();
          throw new Error(errData.error || 'Error fetching project data');
        }

        const docData = await response.json();

        // Convert Firestore booleans into user-friendly strings
        const computedState =
          typeof docData.state === 'boolean'
            ? docData.state
              ? 'Active'
              : 'Inactive'
            : 'Inactive';

        const computedGaStatus =
          typeof docData.google_ads_status === 'boolean'
            ? docData.google_ads_status
              ? 'Connected'
              : 'Not Connected'
            : 'Unknown';

        const computedMcStatus =
          typeof docData.merchant_center_status === 'boolean'
            ? docData.merchant_center_status
              ? 'Connected'
              : 'Not Connected'
            : 'Not Connected';

        setProjectState(computedState);
        setGaStatus(computedGaStatus);
        setMcStatus(computedMcStatus);
        setCurrentStatus(docData.status || '');
        setGaLastCheck(docData.google_ads_last_check || '');
        setMcLastCheck(docData.merchant_center_last_check || '');

        // Load settings or fall back to defaults
        if (docData.core_variant_settings) {
          setCoreVariantSettings(docData.core_variant_settings);
        } else {
          setCoreVariantSettings(defaultSettings);
        }
      } catch (err) {
        console.error('Error loading project data:', err);
        setProjectError(err.message);
      } finally {
        setIsProjectLoading(false);
      }
    };

    loadProjectData();
  }, [selectedProject, token]);

  // Custom styles for react-select
  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: '#E0E1EB',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#E0E1EB',
      },
      fontFamily: 'Lexend Deca, sans-serif',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? '#E0E1EB'
        : state.isSelected
        ? '#4758EB'
        : '#fff',
      color: state.isSelected ? '#fff' : '#292B3D',
      fontFamily: 'Lexend Deca, sans-serif',
      '&:hover': {
        backgroundColor: state.isSelected ? '#4758EB' : '#E0E1EB',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#292B3D',
      fontFamily: 'Lexend Deca', // same font
    }),
    menu: (provided) => ({
      ...provided,
      fontFamily: 'Lexend Deca, sans-serif',
      zIndex: 1000,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 1000 }),
  };

  // Handlers for project actions
  const handleAddProject = () => setIsAddWizardOpen(true);
  const closeAddWizard = () => setIsAddWizardOpen(false);

  const handleDeleteProject = async () => {
    if (!selectedProject) return;
    const confirmed = window.confirm(
      `Are you sure you want to delete the project "${selectedProject.label}"?`
    );
    if (!confirmed) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL_CORE_VARIANTS}/delete_project?projectName=${encodeURIComponent(
          selectedProject.value
        )}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        const errorData = await response.json();
        alert(`Error deleting project: ${errorData.error}`);
        return;
      }
      alert(`Project "${selectedProject.label}" deleted successfully.`);
      setProjects((prev) => prev.filter((p) => p.value !== selectedProject.value));
      setSelectedProject(null);
    } catch (error) {
      console.error('Error deleting project:', error);
      alert(`Error deleting project: ${error.message}`);
    }
  };

  // Manage Project Access
  const handleManageAccess = () => {
    if (!selectedProject) return;
    setIsManageModalOpen(true);
  };

  const handleProjectCreated = (newProj) => {
    // Insert this new project into the dropdown array
    setProjects((prev) => [...prev, newProj]);
    // Select it immediately
    setSelectedProject(newProj);
  };

  const closeManageModal = () => setIsManageModalOpen(false);

  // Toggle project state (Activate/Deactivate)
  const toggleActiveStatus = async () => {
    if (!selectedProject) return;
    const newState = projectState === 'Active' ? 'Inactive' : 'Active';
    setIsToggling(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL_CORE_VARIANTS}/update_project_state`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ projectName: selectedProject.value, state: newState }),
        }
      );
      if (!response.ok) {
        const errorData = await response.json();
        alert(`Error updating state: ${errorData.error}`);
        return;
      }
      const result = await response.json();
      const updatedState = result.state === true ? 'Active' : 'Inactive';
      setProjectState(updatedState);
    } catch (err) {
      console.error('Error updating state:', err);
      alert('Error updating project state');
    } finally {
      setIsToggling(false);
    }
  };

  // Error Log Modal
  const openErrorModal = () => setIsErrorModalOpen(true);
  const closeErrorModal = () => setIsErrorModalOpen(false);

  // Handle Settings changes with input validation & clamping [0..100]
  const handleSettingChange = (field, inputValue) => {
    const numericValue = parseFloat(inputValue);
    if (isNaN(numericValue)) {
      // Fallback to 0 if user clears or enters invalid text
      setCoreVariantSettings((prev) => ({
        ...prev,
        [field]: 0,
      }));
      return;
    }
    // Clamp between 0 and 100
    const clampedValue = Math.max(0, Math.min(100, numericValue));
    setCoreVariantSettings((prev) => ({
      ...prev,
      [field]: clampedValue,
    }));
  };

  // Save the updated settings
  const saveSettings = async () => {
    if (!selectedProject || !coreVariantSettings) return;
    setIsSavingSettings(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL_CORE_VARIANTS}/update_core_variant_settings`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            projectName: selectedProject.value,
            settings: coreVariantSettings,
          }),
        }
      );
      if (!response.ok) {
        const errorData = await response.json();
        alert(`Error saving settings: ${errorData.error}`);
        return;
      }
      alert('Settings updated successfully!');
    } catch (err) {
      console.error('Error saving settings:', err);
      alert('Error saving settings');
    } finally {
      setIsSavingSettings(false);
    }
  };

  // Reset to recommended defaults
  const resetToDefaults = () => {
    setCoreVariantSettings(defaultSettings);
  };

  return (
    <div className={styles.coreVariantsContainer}>
      {/* TOP BAR */}
      <div className={styles.topBar}>
        <div className={styles.leftSideContainer}>
          <div className={styles.dropdownWrapper}>
            <Select
              options={projects}
              value={selectedProject}
              onChange={setSelectedProject}
              styles={customSelectStyles}
              placeholder="Select Core Variants Project"
              isClearable
              menuPortalTarget={document.body}
              isDisabled={isProjectsLoading}
              isLoading={isProjectsLoading}
            />
          </div>
          <div className={styles.iconButtons}>
            <button
              className={styles.addIconBtn}
              onClick={handleAddProject}
              title="Add New Project"
            >
              <FaPlus />
            </button>
            <button
              className={styles.trashIconBtn}
              onClick={handleDeleteProject}
              disabled={!selectedProject}
              title="Delete Project"
            >
              <FaTrash />
            </button>
            <button
              className={styles.sendIconBtn}
              onClick={handleManageAccess}
              disabled={!selectedProject}
              title="Manage Project Access"
            >
              <FaUserFriends />
            </button>
          </div>
        </div>
        <div className={styles.rightSideContainer}>
          {/* Toggle Switch for Status / Metrics / Settings */}
          <div className={styles.toggleSwitch}>
            <div
              className={`${styles.toggleOption} ${
                viewMode === 'status' ? styles.active : ''
              }`}
              onClick={() => setViewMode('status')}
            >
              Status
            </div>
            <div
              className={`${styles.toggleOption} ${
                viewMode === 'metrics' ? styles.active : ''
              }`}
              onClick={() => setViewMode('metrics')}
            >
              Metrics
            </div>
            <div
              className={`${styles.toggleOption} ${
                viewMode === 'settings' ? styles.active : ''
              }`}
              onClick={() => setViewMode('settings')}
            >
              Settings
            </div>
          </div>
        </div>
      </div>

      {/* Loading and error states */}
      {selectedProject && isProjectLoading && (
        <div className={styles.loadingSpinner}>
          <ClipLoader size={35} color="#4758EB" />
          <p>Loading project data...</p>
        </div>
      )}
      {selectedProject && projectError && (
        <div className={styles.errorMessage}>
          <p>Error: {projectError}</p>
        </div>
      )}

      {/* SCORE CARDS: STATUS VIEW */}
      {selectedProject && !isProjectLoading && !projectError && viewMode === 'status' && (
        <div className={styles.scoreCardsContainer}>
          {/* Card 1: State */}
          <div className={styles.scoreCard}>
            <div className={styles.cardHeader}>
              <h3>State</h3>
            </div>
            <div className={styles.centeredMessage}>
              <p
                className={styles.messageValue}
                style={{ color: projectState === 'Active' ? '#5CD685' : '#d9534f' }}
              >
                {isToggling ? (
                  <ClipLoader
                    size={20}
                    color={projectState === 'Active' ? '#5CD685' : '#d9534f'}
                  />
                ) : (
                  projectState
                )}
              </p>
            </div>
            <div className={styles.cardFooter}>
              {projectState === 'Active' ? (
                <button
                  className={styles.deactivateButton}
                  onClick={toggleActiveStatus}
                  disabled={isToggling}
                  title="Deactivate"
                >
                  Deactivate
                </button>
              ) : (
                <button
                  className={styles.activateButton}
                  onClick={toggleActiveStatus}
                  disabled={isToggling}
                  title="Activate"
                >
                  Activate
                </button>
              )}
            </div>
          </div>

          {/* Card 2: Current Status */}
          <div className={styles.scoreCard}>
            <div className={styles.cardHeader}>
              <h3>Current Status</h3>
            </div>
            <div className={styles.centeredMessage}>
              <p className={styles.messageValue}>{currentStatus}</p>
            </div>
            <div className={styles.cardFooter} />
          </div>

          {/* Card 3: Google Ads */}
          <div className={styles.scoreCard}>
            <div className={styles.cardHeader}>
              <h3>Google Ads</h3>
            </div>
            <div className={styles.centeredMessage}>
              <p
                className={styles.messageValue}
                style={{ color: gaStatus === 'Connected' ? '#5CD685' : '#EB4755' }}
              >
                {gaStatus}
              </p>
            </div>
            <div className={styles.cardFooter}>
              <div className={styles.timestamp}>
                Updated: {formatTimestamp(gaLastCheck)}
              </div>
            </div>
          </div>

          {/* Card 4: Merchant Center */}
          <div className={styles.scoreCard}>
            <div className={styles.cardHeader}>
              <h3>Merchant Center</h3>
            </div>
            <div className={styles.centeredMessage}>
              <p
                className={styles.messageValue}
                style={{ color: mcStatus === 'Connected' ? '#5CD685' : '#EB4755' }}
              >
                {mcStatus}
              </p>
            </div>
            <div className={styles.cardFooter}>
              <div className={styles.timestamp}>
                Updated: {formatTimestamp(mcLastCheck)}
              </div>
            </div>
          </div>

          {/* Card 5: Errors */}
          <div className={styles.scoreCard}>
            <div className={styles.cardHeader}>
              <h3>Errors</h3>
            </div>
            <div className={styles.centeredMessage}>
              <p className={styles.messageValue}>
                <FaExclamationCircle className={styles.errorIcon} />
                <span className={styles.errorCount}>#{errorCount}</span>
              </p>
            </div>
            <div className={styles.cardFooter}>
              <button
                className={styles.viewLogsButton}
                onClick={openErrorModal}
                title="View Error Logs"
              >
                View Logs
              </button>
            </div>
          </div>
        </div>
      )}

      {/* SCORE CARDS: METRICS VIEW */}
      {selectedProject && !isProjectLoading && !projectError && viewMode === 'metrics' && (
        <div className={styles.scoreCardsContainer}>
          {/* Metric Cards 6-10 */}
          <div className={styles.scoreCard}>
            <div className={styles.cardHeader}>
              <h3>Total Products</h3>
            </div>
            <div className={styles.centeredMessage}>
              <p className={styles.metricValue}>{totalProducts}</p>
            </div>
            <div className={styles.cardFooter}>
              <div className={styles.timestamp}>Updated: 2025-02-09</div>
            </div>
          </div>

          <div className={styles.scoreCard}>
            <div className={styles.cardHeader}>
              <h3>Viable Products</h3>
            </div>
            <div className={styles.centeredMessage}>
              <p className={styles.metricValue}>{viableProducts}</p>
            </div>
            <div className={styles.cardFooter}>
              <div className={styles.timestamp}>Updated: 2025-02-09</div>
            </div>
          </div>

          <div className={styles.scoreCard}>
            <div className={styles.cardHeader}>
              <h3>Unavailable Products</h3>
            </div>
            <div className={styles.centeredMessage}>
              <p className={styles.metricValue}>{unavailableProducts}</p>
            </div>
            <div className={styles.cardFooter}>
              <div className={styles.timestamp}>Updated: 2025-02-09</div>
            </div>
          </div>

          <div className={styles.scoreCard}>
            <div className={styles.cardHeader}>
              <h3>Unavailable %</h3>
            </div>
            <div className={styles.centeredMessage}>
              <p className={styles.metricValue}>{unavailablePercentage}%</p>
            </div>
            <div className={styles.cardFooter}>
              <div className={styles.timestamp}>Updated: 2025-02-09</div>
            </div>
          </div>

          <div className={styles.scoreCard}>
            <div className={styles.cardHeader}>
              <h3>Spend</h3>
            </div>
            <div className={styles.centeredMessage}>
              <p className={styles.metricValue}>{spend}</p>
            </div>
            <div className={styles.cardFooter}>
              <div className={styles.timestamp}>Updated: 2025-02-09</div>
            </div>
          </div>

          {/* Additional Dummy Metric Cards 11-15 */}
          <div className={styles.scoreCard}>
            <div className={styles.cardHeader}>
              <h3>Additional Metric #1</h3>
            </div>
            <div className={styles.centeredMessage}>
              <p className={styles.metricValue}>{additionalMetric1}</p>
            </div>
            <div className={styles.cardFooter}>
              <div className={styles.timestamp}>Updated: 2025-02-09</div>
            </div>
          </div>

          <div className={styles.scoreCard}>
            <div className={styles.cardHeader}>
              <h3>Additional Metric #2</h3>
            </div>
            <div className={styles.centeredMessage}>
              <p className={styles.metricValue}>{additionalMetric2}</p>
            </div>
            <div className={styles.cardFooter}>
              <div className={styles.timestamp}>Updated: 2025-02-09</div>
            </div>
          </div>

          <div className={styles.scoreCard}>
            <div className={styles.cardHeader}>
              <h3>Additional Metric #3</h3>
            </div>
            <div className={styles.centeredMessage}>
              <p className={styles.metricValue}>{additionalMetric3}</p>
            </div>
            <div className={styles.cardFooter}>
              <div className={styles.timestamp}>Updated: 2025-02-09</div>
            </div>
          </div>

          <div className={styles.scoreCard}>
            <div className={styles.cardHeader}>
              <h3>Additional Metric #4</h3>
            </div>
            <div className={styles.centeredMessage}>
              <p className={styles.metricValue}>{additionalMetric4}</p>
            </div>
            <div className={styles.cardFooter}>
              <div className={styles.timestamp}>Updated: 2025-02-09</div>
            </div>
          </div>

          <div className={styles.scoreCard}>
            <div className={styles.cardHeader}>
              <h3>Additional Metric #5</h3>
            </div>
            <div className={styles.centeredMessage}>
              <p className={styles.metricValue}>{additionalMetric5}</p>
            </div>
            <div className={styles.cardFooter}>
              <div className={styles.timestamp}>Updated: 2025-02-09</div>
            </div>
          </div>
        </div>
      )}

      {/* SETTINGS VIEW */}
      {selectedProject && !isProjectLoading && !projectError && viewMode === 'settings' && (
        <div className={styles.settingsContainer}>
          <h2>Core Variant Settings</h2>
          <p className={styles.settingsDescription}>
            Below are the thresholds we use to decide which product <strong>variants</strong> are 
            “core,” and whether a product is viable overall. 
            <br />
            <strong>Tip:</strong> Start with recommended defaults and fine-tune over time.
          </p>

          {coreVariantSettings && (
            <div className={styles.settingsForm}>
              {/* 1) BASIC FILTERS */}
              <div className={styles.settingsGroup}>
                <h3 className={styles.settingsGroupTitle}>1. Basic Filters</h3>
                <p className={styles.groupSubtitle}>
                  If a product or variant fails these checks, it’s skipped entirely.
                </p>

                {/* Top Variant Minimum % */}
                <div className={styles.settingsRow}>
                  <label className={styles.settingsLabel}>
                    <Tippy
                      content={(
                        <span>
                          If the #1 (biggest) variant's % is below this threshold, 
                          we skip the entire product.
                          <br />
                          <em>Range: 0–100</em>
                        </span>
                      )}
                      arrow={true}
                    >
                      <span className={styles.tooltipLabel}>
                        Top Variant Minimum %
                      </span>
                    </Tippy>
                  </label>
                  <input
                    className={styles.settingsInput}
                    type="number"
                    step="0.01"
                    max="100"
                    min="0"
                    value={coreVariantSettings.min_biggest_variant_pct}
                    onChange={(e) =>
                      handleSettingChange('min_biggest_variant_pct', e.target.value)
                    }
                  />
                </div>

                {/* Variant Minimum % */}
                <div className={styles.settingsRow}>
                  <label className={styles.settingsLabel}>
                    <Tippy
                      content={(
                        <span>
                          Any variant with a % below this is automatically not core.
                          <br />
                          <em>Range: 0–100</em>
                        </span>
                      )}
                      arrow={true}
                    >
                      <span className={styles.tooltipLabel}>
                        Variant Minimum %
                      </span>
                    </Tippy>
                  </label>
                  <input
                    className={styles.settingsInput}
                    type="number"
                    step="0.01"
                    max="100"
                    min="0"
                    value={coreVariantSettings.min_variant_pct}
                    onChange={(e) =>
                      handleSettingChange('min_variant_pct', e.target.value)
                    }
                  />
                </div>
              </div>

              {/* 2) CORE COVERAGE */}
              <div className={styles.settingsGroup}>
                <h3 className={styles.settingsGroupTitle}>2. Core Coverage</h3>
                <p className={styles.groupSubtitle}>
                  After identifying core variants, they must collectively reach 
                  this coverage for the product to be viable.
                </p>

                {/* Core Variant Coverage % */}
                <div className={styles.settingsRow}>
                  <label className={styles.settingsLabel}>
                    <Tippy
                      content={(
                        <span>
                          If total % of identified core variants is below this, 
                          the product is excluded.
                          <br />
                          <em>Range: 0–100</em>
                        </span>
                      )}
                      arrow={true}
                    >
                      <span className={styles.tooltipLabel}>
                        Core Variant Coverage %
                      </span>
                    </Tippy>
                  </label>
                  <input
                    className={styles.settingsInput}
                    type="number"
                    step="0.01"
                    max="100"
                    min="0"
                    value={coreVariantSettings.core_variant_sum_threshold}
                    onChange={(e) =>
                      handleSettingChange('core_variant_sum_threshold', e.target.value)
                    }
                  />
                </div>
              </div>

              {/* 3) ADVANCED RATIO LOGIC */}
              <div className={styles.settingsGroup}>
                <h3 className={styles.settingsGroupTitle}>3. Advanced Ratio Logic</h3>
                <p className={styles.groupSubtitle}>
                  Variants can qualify as core if they meet certain ratio thresholds
                  compared to the top variant or a previously identified core variant.
                </p>

                {/* Ratio to Top Variant (ratio_threshold_1) */}
                <div className={styles.settingsRow}>
                  <label className={styles.settingsLabel}>
                    <Tippy
                      content={(
                        <span>
                          Unconditional check: if a variant’s % is at least this ratio 
                          of the top variant, it qualifies.
                          <br />
                          Example: If top variant is 60%, a ratio of 70 means 42% required.
                          <br />
                          <em>Range: 0–100</em>
                        </span>
                      )}
                      arrow={true}
                    >
                      <span className={styles.tooltipLabel}>
                        Ratio to Top Variant (%)
                      </span>
                    </Tippy>
                  </label>
                  <input
                    className={styles.settingsInput}
                    type="number"
                    step="0.01"
                    max="100"
                    min="0"
                    value={coreVariantSettings.ratio_threshold_1}
                    onChange={(e) =>
                      handleSettingChange('ratio_threshold_1', e.target.value)
                    }
                  />
                </div>

                {/* Coverage Breakpoint 1 (summarized_pct_threshold_1) */}
                <div className={styles.settingsRow}>
                  <label className={styles.settingsLabel}>
                    <Tippy
                      content={(
                        <span>
                          If the total coverage of currently core variants is 
                          below this %, we allow a <em>Secondary Ratio</em> check (below).
                          <br />
                          <em>Range: 0–100</em>
                        </span>
                      )}
                      arrow={true}
                    >
                      <span className={styles.tooltipLabel}>
                        Coverage Breakpoint 1 (%)
                      </span>
                    </Tippy>
                  </label>
                  <input
                    className={styles.settingsInput}
                    type="number"
                    step="0.01"
                    max="100"
                    min="0"
                    value={coreVariantSettings.summarized_pct_threshold_1}
                    onChange={(e) =>
                      handleSettingChange('summarized_pct_threshold_1', e.target.value)
                    }
                  />
                </div>

                {/* Secondary Ratio (ratio_threshold_2) */}
                <div className={styles.settingsRow}>
                  <label className={styles.settingsLabel}>
                    <Tippy
                      content={(
                        <span>
                          If coverage is below Breakpoint 1, a variant qualifies 
                          if it meets this ratio vs. the top variant.
                          <br />
                          <em>Range: 0–100</em>
                        </span>
                      )}
                      arrow={true}
                    >
                      <span className={styles.tooltipLabel}>
                        Secondary Ratio (%)
                      </span>
                    </Tippy>
                  </label>
                  <input
                    className={styles.settingsInput}
                    type="number"
                    step="0.01"
                    max="100"
                    min="0"
                    value={coreVariantSettings.ratio_threshold_2}
                    onChange={(e) =>
                      handleSettingChange('ratio_threshold_2', e.target.value)
                    }
                  />
                </div>

                {/* Coverage Breakpoint 2 (summarized_pct_threshold_2) */}
                <div className={styles.settingsRow}>
                  <label className={styles.settingsLabel}>
                    <Tippy
                      content={(
                        <span>
                          If coverage is still below this second breakpoint, 
                          we check the <em>Third Ratio</em>.
                          <br />
                          <em>Range: 0–100</em>
                        </span>
                      )}
                      arrow={true}
                    >
                      <span className={styles.tooltipLabel}>
                        Coverage Breakpoint 2 (%)
                      </span>
                    </Tippy>
                  </label>
                  <input
                    className={styles.settingsInput}
                    type="number"
                    step="0.01"
                    max="100"
                    min="0"
                    value={coreVariantSettings.summarized_pct_threshold_2}
                    onChange={(e) =>
                      handleSettingChange('summarized_pct_threshold_2', e.target.value)
                    }
                  />
                </div>

                {/* Third Ratio (ratio_threshold_3) */}
                <div className={styles.settingsRow}>
                  <label className={styles.settingsLabel}>
                    <Tippy
                      content={(
                        <span>
                          If coverage is below Breakpoint 2, a variant qualifies 
                          if it meets this ratio vs. the top variant.
                          <br />
                          <em>Range: 0–100</em>
                        </span>
                      )}
                      arrow={true}
                    >
                      <span className={styles.tooltipLabel}>
                        Third Ratio (%)
                      </span>
                    </Tippy>
                  </label>
                  <input
                    className={styles.settingsInput}
                    type="number"
                    step="0.01"
                    max="100"
                    min="0"
                    value={coreVariantSettings.ratio_threshold_3}
                    onChange={(e) =>
                      handleSettingChange('ratio_threshold_3', e.target.value)
                    }
                  />
                </div>

                {/* Ratio to Previous Core (ratio_threshold_4) */}
                <div className={styles.settingsRow}>
                  <label className={styles.settingsLabel}>
                    <Tippy
                      content={(
                        <span>
                          If the previous variant is already core, 
                          we compare this new variant’s % directly to that previous core 
                          at this ratio threshold.
                          <br />
                          <em>Range: 0–100</em>
                        </span>
                      )}
                      arrow={true}
                    >
                      <span className={styles.tooltipLabel}>
                        Ratio to Previous Core (%)
                      </span>
                    </Tippy>
                  </label>
                  <input
                    className={styles.settingsInput}
                    type="number"
                    step="0.01"
                    max="100"
                    min="0"
                    value={coreVariantSettings.ratio_threshold_4}
                    onChange={(e) =>
                      handleSettingChange('ratio_threshold_4', e.target.value)
                    }
                  />
                </div>
              </div>

              {/* EXPLANATORY NOTE (BULLET POINTS) */}
              <div className={styles.explanatoryNote}>
                <p>
                <strong>How these thresholds work together:</strong>
                    <br></br>
                    1. We skip products if the top variant’s % is below <em>Top Variant Minimum %</em>.
                    <br></br>
                    2. We skip any other variant below <em>Variant Minimum %</em>.
                    <br></br>
                    3. For variants above those minimums, we compare them to the top variant and any summarized core coverage (the “coverage breakpoints”) to see if they also qualify.
                    <br></br>
                    4. If <em>all</em> core variants combined meet or exceed the <em>Core Variant Coverage %</em>, we consider the product viable.
                </p>
              </div>

              {/* SAVE / RESET BUTTONS */}
              <div className={styles.saveSettingsRow}>
                <button
                  className={styles.resetSettingsButton}
                  onClick={resetToDefaults}
                  disabled={isSavingSettings}
                >
                  Reset to Defaults
                </button>
                <button
                  className={styles.saveSettingsButton}
                  onClick={saveSettings}
                  disabled={isSavingSettings}
                >
                  {isSavingSettings ? 'Saving...' : 'Save Settings'}
                </button>
              </div>
            </div>
          )}
        </div>
      )}

      {/* ERROR LOGS MODAL */}
      {isErrorModalOpen && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            <button
              className={styles.closeModalBtn}
              onClick={closeErrorModal}
              title="Close Modal"
            >
              <FaTimes />
            </button>
            <h3>Error Logs</h3>
            <div className={styles.errorLogs}>
              <p>• Error #1: Some error detail goes here.</p>
              <p>• Error #2: Another error detail goes here.</p>
              <p>• Error #3: Third error detail goes here.</p>
            </div>
          </div>
        </div>
      )}

      {/* MANAGE PROJECT ACCESS MODAL */}
      {isManageModalOpen && (
        <ManageProjectModal
          projectName={selectedProject?.value}
          token={token}
          onClose={closeManageModal}
        />
      )}

      {/* CREATE PROJECT WIZARD */}
      <CreateProjectWizard
        isOpen={isAddWizardOpen}
        onClose={closeAddWizard}
        token={token}
        apiUrl={`${process.env.REACT_APP_API_URL_CORE_VARIANTS}`}
        onProjectCreated={handleProjectCreated}
      />
    </div>
  );
};

export default CoreVariants;
