// src/components/CustomAttributesPages/AggregatedValues.js

import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import axios from 'axios';
import styles from './AggregatedValues.module.css';
import {
  FaPlus,
  FaTimes,
  FaTrash,
  FaChevronDown,
  FaChevronUp,
  FaEye,
} from 'react-icons/fa';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import { ClipLoader } from 'react-spinners';
import { v4 as uuidv4 } from 'uuid';

const API_URL_AGG_FUNCTIONS = process.env.REACT_APP_API_URL_AGG_FUNCTIONS;

const AggregatedValues = () => {
  // ----------------------------
  // 1. Client Selection States
  // ----------------------------
  const [linkedClients, setLinkedClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isLoadingClients, setIsLoadingClients] = useState(false);

  // Additional states already existing for the aggregator
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newProjectName, setNewProjectName] = useState('');
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [loadingColumns, setLoadingColumns] = useState(false);
  const [columns, setColumns] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccessMessage, setSaveSuccessMessage] = useState('');
  const [updatingAll, setUpdatingAll] = useState(false);
  const [aggregations, setAggregations] = useState({
    aggregate_function: '',
    column1: '',
    column2: '',
    separator: '',
    level: '',
    attribute_name: '',
    ignore_previous_levels: false,
  });
  const [filters, setFilters] = useState([]);
  const [error, setError] = useState(null);
  const [filtersExpanded, setFiltersExpanded] = useState(false);
  const [previewData, setPreviewData] = useState([]);
  const [examplePreviewData, setExamplePreviewData] = useState([]);
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [previewError, setPreviewError] = useState(null);
  const [activeStatus, setActiveStatus] = useState(false);
  const [updatingActive, setUpdatingActive] = useState(false);
  const columnsCache = useRef({});
  const [advancedMode, setAdvancedMode] = useState(false);
  const [sampleValues, setSampleValues] = useState([]);
  const [exampleGroupValue, setExampleGroupValue] = useState('');

  const currentRequestId = useRef(0);

  // --------------------------------
  // 2. Fetch Linked Clients on mount
  // --------------------------------
  useEffect(() => {
    const fetchLinkedClients = async () => {
      setIsLoadingClients(true);
      try {
        const response = await axios.get(
          `${API_URL_AGG_FUNCTIONS}/client-data`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
        if (response.status === 200) {
          const data = response.data;
          const clientOptions = data.linked_clients.map((client) => ({
            value: client,
            label: client,
          }));
          setLinkedClients(clientOptions);
        }
      } catch (err) {
        console.error('Error fetching linked clients:', err);
        setError('Failed to fetch linked clients');
      } finally {
        setIsLoadingClients(false);
      }
    };

    fetchLinkedClients();
  }, []);

  // ---------------
  // React-Select CSS
  // ---------------
  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: '#E0E1EB',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#E0E1EB',
      },
      fontFamily: 'Lexend Deca, sans-serif',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? '#E0E1EB'
        : state.isSelected
        ? '#4758EB'
        : '#fff',
      color: state.isSelected ? '#fff' : '#292B3D',
      fontFamily: 'Lexend Deca, sans-serif',
      '&:hover': {
        backgroundColor: state.isSelected ? '#4758EB' : '#E0E1EB',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#292B3D',
      fontFamily: 'Lexend Deca, sans-serif',
    }),
    menu: (provided) => ({
      ...provided,
      fontFamily: 'Lexend Deca, sans-serif',
      zIndex: 1000,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 1000 }),
  };

  // If you want a dot or styling in the dropdown:
  const formatOptionLabel = ({ label, active }) => (
    <div className={styles.optionLabel}>
      {/* You can style a dot or something here if needed */}
      <span className={styles.optionText}>{label}</span>
    </div>
  );

  // -------------------------------------
  // 3. Once we have a selectedClient, 
  //    load projects & do everything else
  // -------------------------------------

  // Reset aggregator state if client changes
  const resetStates = () => {
    setProjects([]);
    setSelectedProject(null);
    setColumns([]);
    setAggregations({
      aggregate_function: '',
      column1: '',
      column2: '',
      separator: '',
      level: '',
      attribute_name: '',
      ignore_previous_levels: false,
    });
    setFilters([]);
    setFiltersExpanded(false);
    setActiveStatus(false);
    setPreviewData([]);
    setExamplePreviewData([]);
    setAdvancedMode(false);
    setSampleValues([]);
    setExampleGroupValue('');
    setError(null);
    setPreviewError(null);
    setHasUnsavedChanges(false);
    setSaveSuccessMessage('');
  };

  useEffect(() => {
    // If no client is selected, reset everything
    if (!selectedClient) {
      resetStates();
      return;
    }
    resetStates(); 
    // Then fetch the projects for this client
    fetchProjects();
    // eslint-disable-next-line
  }, [selectedClient]);

  // --------------
  // 4. Fetch logic
  // --------------
  const fetchProjects = async () => {
    setLoadingProjects(true);
    try {
      const response = await axios.get(
        `${API_URL_AGG_FUNCTIONS}/client-feeds/${encodeURIComponent(
          selectedClient.value
        )}/aggregated-value-projects`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      let projectOptions = response.data.projects.map((proj) => {
        let lastUpdatedAt = null;
        if (proj.last_updated_at) {
          lastUpdatedAt = new Date(proj.last_updated_at);
        }
        return {
          value: proj.id,
          label: proj.aggregated_value_project_name,
          aggregations: proj.aggregations || {},
          active: proj.active || false,
          last_updated_at: lastUpdatedAt,
        };
      });

      projectOptions.sort((a, b) => a.label.localeCompare(b.label));
      setProjects(projectOptions);
      setSelectedProject(projectOptions[0] || null);
    } catch (err) {
      console.error('Error fetching projects:', err);
      setError('Failed to load projects. Please try again.');
    } finally {
      setLoadingProjects(false);
    }
  };

  // If success message is showing, hide after 2s
  useEffect(() => {
    if (saveSuccessMessage) {
      const timer = setTimeout(() => {
        setSaveSuccessMessage('');
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [saveSuccessMessage]);

  // Warn user on page unload if unsaved changes
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [hasUnsavedChanges]);

  // ---------------------
  // 5. Project & Columns
  // ---------------------
  useEffect(() => {
    if (!selectedProject || !selectedClient) {
      return;
    }

    // Abort logic if needed
    currentRequestId.current += 1;
    const requestId = currentRequestId.current;
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchColumns = async () => {
      setLoadingColumns(true);
      setError(null);
      try {
        // Check cache first
        if (columnsCache.current[selectedClient.value]) {
          if (requestId === currentRequestId.current) {
            setColumns(columnsCache.current[selectedClient.value]);
            // Pre-fill aggregations
            prefillAggregations(selectedProject);
          }
          return;
        }

        const response = await axios.get(
          `${API_URL_AGG_FUNCTIONS}/client-feeds/${encodeURIComponent(
            selectedClient.value
          )}/columns`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            signal,
          }
        );

        if (requestId === currentRequestId.current) {
          const fetchedColumns = response.data.columns;
          setColumns(fetchedColumns);
          columnsCache.current[selectedClient.value] = fetchedColumns;
          prefillAggregations(selectedProject);
        }
      } catch (err) {
        if (!axios.isCancel(err) && requestId === currentRequestId.current) {
          console.error('Error fetching columns:', err);
          setError('Failed to load columns. Please try again.');
        }
      } finally {
        if (requestId === currentRequestId.current) {
          setLoadingColumns(false);
        }
      }
    };

    const prefillAggregations = (project) => {
      setAggregations((prev) => ({
        ...prev,
        aggregate_function: project.aggregations.aggregate_function || '',
        column1: project.aggregations.column1 || '',
        column2: project.aggregations.column2 || '',
        separator: project.aggregations.separator || '',
        level: project.aggregations.level || '',
        attribute_name: project.aggregations.attribute_name || '',
        ignore_previous_levels:
          project.aggregations.ignore_previous_levels || false,
      }));
      setFilters(project.aggregations.filters || []);
      setFiltersExpanded((project.aggregations.filters || []).length > 0);
      setActiveStatus(project.active || false);
      setAdvancedMode(
        !!(project.aggregations.separator && project.aggregations.level)
      );

      if (project.aggregations.column2) {
        fetchSampleValues(project.aggregations.column2, requestId);
      }
    };

    fetchColumns();

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line
  }, [selectedProject]);

  // -----------------
  // 6. Sample Values
  // -----------------
  const fetchSampleValues = async (column, requestId) => {
    try {
      const response = await axios.get(
        `${API_URL_AGG_FUNCTIONS}/client-feeds/${encodeURIComponent(
          selectedClient.value
        )}/sample-values`,
        {
          params: { column },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (requestId === currentRequestId.current) {
        setSampleValues(response.data.sample_values);
      }
    } catch (err) {
      console.error('Error fetching sample values:', err);
    }
  };

  // Whenever separator/level change, show example
  useEffect(() => {
    if (
      aggregations.separator &&
      aggregations.level &&
      sampleValues.length > 0
    ) {
      const firstValue = sampleValues[0];
      const parts = firstValue.split(aggregations.separator);
      const lvl = parseInt(aggregations.level, 10);
      if (isNaN(lvl) || lvl <= 0) {
        setExampleGroupValue('Invalid level');
      } else {
        if (aggregations.ignore_previous_levels) {
          // Show only that level
          const groupValue = parts[lvl - 1] ? parts[lvl - 1].trim() : 'Invalid level';
          setExampleGroupValue(groupValue);
        } else {
          // Show up to that level
          const groupParts = parts.slice(0, lvl);
          const groupValue = groupParts.join(` ${aggregations.separator} `);
          setExampleGroupValue(groupValue);
        }
      }
    } else {
      setExampleGroupValue('');
    }
  }, [
    aggregations.separator,
    aggregations.level,
    aggregations.ignore_previous_levels,
    sampleValues,
  ]);

  // -------------------------
  // 7. Handlers & Utilities
  // -------------------------
  const handleProjectChange = (selectedOption) => {
    if (hasUnsavedChanges) {
      const confirmDiscard = window.confirm(
        'You have unsaved changes. Discard them and switch projects?'
      );
      if (!confirmDiscard) {
        return;
      }
    }
    setSelectedProject(selectedOption || null);
    setPreviewData([]);
    setExamplePreviewData([]);
    setPreviewError(null);
    setAdvancedMode(false);
    setSampleValues([]);
    setExampleGroupValue('');
    setHasUnsavedChanges(false);
    setSaveSuccessMessage('');

    if (selectedOption?.aggregations.column2) {
      fetchSampleValues(
        selectedOption.aggregations.column2,
        currentRequestId.current
      );
    }
  };

  const handleAggregationChange = (type, value) => {
    setAggregations((prev) => ({ ...prev, [type]: value }));
    setHasUnsavedChanges(true);

    // If column2 changes
    if (type === 'column2') {
      if (value) {
        fetchSampleValues(value, currentRequestId.current);
      } else {
        setSampleValues([]);
        setExampleGroupValue('');
      }
    }
  };

  const handleAddProject = async (e) => {
    e.preventDefault();
    if (!newProjectName.trim()) {
      setError('Project name cannot be empty.');
      return;
    }
    try {
      const response = await axios.post(
        `${API_URL_AGG_FUNCTIONS}/client-feeds/${encodeURIComponent(
          selectedClient.value
        )}/aggregated-value-projects`,
        { aggregated_value_project_name: newProjectName },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const newProj = {
        value: response.data.project.id,
        label: response.data.project.aggregated_value_project_name,
        aggregations: response.data.project.aggregations || {},
        active: response.data.project.active || false,
      };

      setProjects((prev) => [...prev, newProj]);
      setSelectedProject(newProj);
      setActiveStatus(newProj.active);
      resetStates();
      setIsModalOpen(false);
    } catch (err) {
      console.error('Error adding project:', err);
      setError(
        err.response?.data?.message || 'Failed to add project. Please try again.'
      );
    }
  };

  const handleDeleteProject = async (projectId) => {
    confirmAlert({
      title: 'Confirm Deletion',
      message: 'Are you sure you want to delete this project?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              await axios.delete(
                `${API_URL_AGG_FUNCTIONS}/client-feeds/${encodeURIComponent(
                  selectedClient.value
                )}/aggregated-value-projects/${projectId}`,
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                  },
                }
              );

              setProjects((prev) => prev.filter((p) => p.value !== projectId));
              if (selectedProject?.value === projectId) {
                setSelectedProject(null);
                resetStates();
              }
            } catch (err) {
              console.error('Error deleting project:', err);
              setError(
                err.response?.data?.message ||
                  'Failed to delete project. Please try again.'
              );
            }
          },
        },
        {
          label: 'No',
        },
      ],
    });
  };

  const handleView = () => {
    if (!selectedClient) return;
    const encodedClient = encodeURIComponent(selectedClient.value);
    const url = `https://storage.googleapis.com/quantum-feed-engine/${encodedClient}/aggregated_values_feed.xml`;
    window.open(url, '_blank');
  };

  const handleUpdateAll = async () => {
    setUpdatingAll(true);
    setError(null);
    try {
      await axios.post(
        `${API_URL_AGG_FUNCTIONS}/client-feeds/${encodeURIComponent(
          selectedClient.value
        )}/aggregated-value-projects/full-data`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      await fetchProjects();
    } catch (err) {
      console.error('Error updating all data:', err);
      let msg = err.response?.data?.message || 'Failed to update data.';
      const details = err.response?.data?.details;
      if (details && Array.isArray(details)) {
        msg += '\n' + details.join('\n');
      }
      setError(msg);
    } finally {
      setUpdatingAll(false);
    }
  };

  const handleToggleActive = async () => {
    if (!selectedProject) return;
    const newActiveStatus = !activeStatus;
    setUpdatingActive(true);
    setError(null);
    try {
      await axios.patch(
        `${API_URL_AGG_FUNCTIONS}/client-feeds/${encodeURIComponent(
          selectedClient.value
        )}/aggregated-value-projects/${selectedProject.value}/active`,
        { active: newActiveStatus },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setActiveStatus(newActiveStatus);
      setSelectedProject((prev) =>
        prev ? { ...prev, active: newActiveStatus } : prev
      );
      setProjects((prev) =>
        prev.map((proj) =>
          proj.value === selectedProject.value
            ? { ...proj, active: newActiveStatus }
            : proj
        )
      );
    } catch (err) {
      console.error('Error updating active status:', err);
      setError(
        err.response?.data?.message ||
          'Failed to update active status. Please try again.'
      );
    } finally {
      setUpdatingActive(false);
    }
  };

  // Save Aggregations
  const handleSaveAggregations = async () => {
    if (!selectedProject || !selectedClient) return;
    try {
      const updatedAggregations = {
        ...aggregations,
        filters: filters,
        separator: advancedMode ? aggregations.separator : null,
        level: advancedMode ? aggregations.level : null,
        ignore_previous_levels: advancedMode
          ? aggregations.ignore_previous_levels
          : false,
      };

      await axios.put(
        `${API_URL_AGG_FUNCTIONS}/client-feeds/${encodeURIComponent(
          selectedClient.value
        )}/aggregated-value-projects/${selectedProject.value}/aggregations`,
        {
          aggregations: updatedAggregations,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setProjects((prev) =>
        prev.map((proj) =>
          proj.value === selectedProject.value
            ? { ...proj, aggregations: updatedAggregations }
            : proj
        )
      );
      setHasUnsavedChanges(false);
      setSaveSuccessMessage('Aggregations saved successfully!');
    } catch (err) {
      console.error('Error saving aggregations:', err);
      setError(
        err.response?.data?.message ||
          'Failed to save aggregations. Please try again.'
      );
    }
  };

  // Preview
  const handlePreview = async () => {
    if (!selectedProject || !selectedClient) return;
    const { aggregate_function, column1, column2, separator, level } =
      aggregations;

    if (!aggregate_function || !column1 || !column2) {
      setPreviewError(
        'Please select aggregate function and both columns before previewing.'
      );
      return;
    }

    setLoadingPreview(true);
    setPreviewError(null);
    setPreviewData([]);
    setExamplePreviewData([]);

    try {
      const response = await axios.post(
        `${API_URL_AGG_FUNCTIONS}/client-feeds/${encodeURIComponent(
          selectedClient.value
        )}/aggregated-value-projects/${selectedProject.value}/preview`,
        {
          aggregate_function,
          column1,
          column2,
          separator: advancedMode ? separator : null,
          level: advancedMode ? level : null,
          ignore_previous_levels: advancedMode
            ? aggregations.ignore_previous_levels
            : false,
          filters,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setPreviewData(response.data.preview_data);
      setExamplePreviewData(response.data.example_preview_data);
    } catch (err) {
      console.error('Error fetching preview data:', err);
      setPreviewError(
        err.response?.data?.message ||
          'Failed to fetch preview data. Please try again.'
      );
    } finally {
      setLoadingPreview(false);
    }
  };

  const formatDateTime = (date) => {
    return date.toLocaleString();
  };

  // Filter Helpers
  const logicalOperators = [
    { value: 'AND', label: 'AND' },
    { value: 'OR', label: 'OR' },
  ];
  const filterOperators = [
    { value: '=', label: '=' },
    { value: '!=', label: '!=' },
    { value: '>', label: '>' },
    { value: '<', label: '<' },
    { value: '>=', label: '>=' },
    { value: '<=', label: '<=' },
    { value: 'LIKE', label: 'LIKE' },
    { value: 'IN', label: 'IN' },
  ];

  const handleAddFilter = () => {
    const newFilter = {
      id: uuidv4(),
      field: '',
      operator: '',
      value: '',
      connector: 'AND',
    };
    setFilters((prev) => {
      const updated = [...prev, newFilter];
      if (updated.length === 1) {
        setFiltersExpanded(true);
      }
      return updated;
    });
    setHasUnsavedChanges(true);
  };

  const handleRemoveFilter = (id) => {
    setFilters((prev) => {
      const updated = prev.filter((f) => f.id !== id);
      if (updated.length === 0) {
        setFiltersExpanded(false);
      }
      return updated;
    });
    setHasUnsavedChanges(true);
  };

  const handleFilterChange = (id, type, value) => {
    setFilters((prev) =>
      prev.map((f) => (f.id === id ? { ...f, [type]: value } : f))
    );
    setHasUnsavedChanges(true);
  };

  // Render example group value (highlighting level)
  const renderExampleGroupValue = () => {
    if (!aggregations.separator || !aggregations.level || !exampleGroupValue) {
      return <p>{exampleGroupValue}</p>;
    }
    const parts = exampleGroupValue
      .split(aggregations.separator)
      .map((p) => p.trim());
    const lvl = parseInt(aggregations.level, 10);
    if (isNaN(lvl) || lvl <= 0) {
      return <p>Invalid level</p>;
    }
    return (
      <p>
        {parts.map((part, index) => {
          const shouldHighlight = aggregations.ignore_previous_levels
            ? true
            : index === lvl - 1;
          return (
            <span key={index}>
              {shouldHighlight ? (
                <span className={styles.highlightedLevel}>{part}</span>
              ) : (
                <span>{part}</span>
              )}
              {index < parts.length - 1 && ` ${aggregations.separator} `}
            </span>
          );
        })}
      </p>
    );
  };

  // -------------------------------------------------------------
  // 8. Render: If no client selected, show a "Please select" UI
  // -------------------------------------------------------------
  return (
    <div className={styles.container}>
      {/* Global Spinner Overlay for client loading */}
      {isLoadingClients && (
        <div className={styles.globalSpinner}>
          <ClipLoader color="#4758EB" size={50} />
        </div>
      )}

      <h2>Aggregated Values</h2>
      {error && <p className={styles.error}>{error}</p>}

      {/* Client Selection at top */}
      <div className={styles.dropdownContainer}>
        <label htmlFor="clientSelect"><b>Select Client:</b></label>
        <Select
          id="clientSelect"
          options={linkedClients}
          value={selectedClient}
          onChange={(option) => setSelectedClient(option)}
          styles={customSelectStyles}
          placeholder={
            isLoadingClients
              ? 'Loading clients...'
              : linkedClients.length === 0
              ? 'No linked clients available'
              : 'Select Client'
          }
          isSearchable
          isLoading={isLoadingClients}
          menuPortalTarget={document.body}
          menuPosition="fixed"
          formatOptionLabel={formatOptionLabel}
          isClearable
        />
      </div>
      

      {/* If client is selected, show aggregator UI */}
      {selectedClient && (
        <>
          {/* Project Selection */}
          <div className={styles.projectSelection}>
            <div className={styles.selectContainer}>
            
              <Select
                options={projects}
                value={selectedProject}
                onChange={handleProjectChange}
                styles={customSelectStyles}
                formatOptionLabel={formatOptionLabel}
                placeholder="Select Aggregated Value Project"
                isLoading={loadingProjects}
                isClearable
                menuPortalTarget={document.body}
                className={styles.selectDropdown}
                isDisabled={loadingColumns}
              />
            </div>

            {/* Add and Remove Buttons */}
            <div className={styles.buttonGroup}>
              <button
                className={styles.addButton}
                onClick={() => {
                  if (hasUnsavedChanges) {
                    const confirmDiscard = window.confirm(
                      'You have unsaved changes. Discard and add a new project?'
                    );
                    if (!confirmDiscard) return;
                    setHasUnsavedChanges(false);
                    setSaveSuccessMessage('');
                  }
                  setNewProjectName('');
                  setIsModalOpen(true);
                  setError(null);
                }}
                title="Add New Project"
                aria-label="Add New Project"
                disabled={loadingProjects || loadingColumns}
              >
                <FaPlus />
              </button>

              {selectedProject && (
                <button
                  className={styles.removeButton}
                  onClick={() => handleDeleteProject(selectedProject.value)}
                  title="Delete Project"
                  aria-label="Delete Project"
                  disabled={loadingProjects || loadingColumns}
                >
                  <FaTrash />
                </button>
              )}
            </div>
          </div>

          {/* Active Toggle, Update All, and View Buttons */}
          {selectedProject && (
            <div className={styles.toggleContainer}>
              <button
                className={styles.updateAllButton}
                onClick={handleUpdateAll}
                title="Update All"
                aria-label="Update All"
                disabled={loadingColumns || updatingActive || updatingAll}
              >
                {updatingAll ? (
                  <ClipLoader color="#fff" size={15} />
                ) : (
                  'Update All'
                )}
              </button>

              <button
                className={styles.updateAllButton}
                onClick={handleView}
                title="View Details"
                aria-label="View Details"
                disabled={loadingColumns || updatingActive || updatingAll}
              >
                <FaEye />
              </button>

              {selectedProject.last_updated_at && (
                <span className={styles.lastUpdatedAt}>
                  Last updated at {formatDateTime(selectedProject.last_updated_at)}
                </span>
              )}
            </div>
          )}

          <div className={styles.infoBox}>
            All active feeds are scheduled to be updated every 4 hours.
          </div>

          {/* Aggregation Config */}
          {selectedProject && (
            <div className={styles.aggregationSection}>
              <h3>Configure Aggregation</h3>

              {/* Attribute Name & Active Toggle */}
              <div className={styles.inlineInputContainer}>
                <label htmlFor="attributeName"><b>Attribute Name:</b></label>
                <input
                  type="text"
                  id="attributeName"
                  value={aggregations.attribute_name}
                  onChange={(e) =>
                    handleAggregationChange('attribute_name', e.target.value)
                  }
                  placeholder="Enter attribute name"
                  className={styles.attributeInput}
                />
                <div className={styles.toggleContainerInline}>
                  <label htmlFor="activeToggle">Include in XML feed:</label>
                  <Toggle
                    id="activeToggle"
                    checked={activeStatus}
                    onChange={handleToggleActive}
                    aria-label="Toggle project active status"
                    disabled={updatingActive || loadingColumns || updatingAll}
                  />
                </div>
              </div>

              {loadingColumns ? (
                <div className={styles.sectionSpinner}>
                  <ClipLoader color="#4758EB" size={30} />
                </div>
              ) : (
                <>
                  {/* Aggregate Function / Column1 / Column2 */}
                  <div className={styles.aggregationDropdowns}>
                    {/* Aggregate Function */}
                    <div className={styles.dropdownContainer}>
                      <label htmlFor="aggregateFunction">Aggregate Function</label>
                      <Select
                        id="aggregateFunction"
                        options={[
                          { value: 'COUNT', label: 'COUNT' },
                          { value: 'COUNT UNIQUE', label: 'COUNT UNIQUE' },
                          { value: 'SUM', label: 'SUM' },
                          { value: 'AVERAGE', label: 'AVERAGE' },
                          { value: 'MAX', label: 'MAX' },
                          { value: 'MIN', label: 'MIN' },
                        ]}
                        value={
                          aggregations.aggregate_function
                            ? {
                                value: aggregations.aggregate_function,
                                label: aggregations.aggregate_function,
                              }
                            : null
                        }
                        onChange={(option) =>
                          handleAggregationChange(
                            'aggregate_function',
                            option ? option.value : ''
                          )
                        }
                        styles={customSelectStyles}
                        placeholder="Please Select an Aggregate Function"
                        isClearable
                        className={styles.selectDropdown}
                      />
                    </div>

                    {/* Column1 */}
                    <div className={styles.dropdownContainer}>
                      <label htmlFor="column1">Column to Aggregate</label>
                      <Select
                        id="column1"
                        options={columns.map((col) => ({
                          value: col,
                          label: col,
                        }))}
                        value={
                          aggregations.column1
                            ? {
                                value: aggregations.column1,
                                label: aggregations.column1,
                              }
                            : null
                        }
                        onChange={(option) =>
                          handleAggregationChange(
                            'column1',
                            option ? option.value : ''
                          )
                        }
                        styles={customSelectStyles}
                        placeholder="Please Select Column 1"
                        isClearable
                        className={styles.selectDropdown}
                      />
                    </div>

                    {/* Column2 + Advanced Options */}
                    <div className={styles.dropdownContainer}>
                      <label htmlFor="column2">Column to Group By</label>
                      <Select
                        id="column2"
                        options={columns.map((col) => ({
                          value: col,
                          label: col,
                        }))}
                        value={
                          aggregations.column2
                            ? {
                                value: aggregations.column2,
                                label: aggregations.column2,
                              }
                            : null
                        }
                        onChange={(option) =>
                          handleAggregationChange(
                            'column2',
                            option ? option.value : ''
                          )
                        }
                        styles={customSelectStyles}
                        placeholder="Please Select Column 2"
                        isClearable
                        className={styles.selectDropdown}
                      />

                      {/* Advanced Options Toggle */}
                      <div className={styles.advancedToggle}>
                        <button
                          type="button"
                          className={styles.advancedToggleButton}
                          onClick={() => setAdvancedMode(!advancedMode)}
                          aria-expanded={advancedMode}
                          aria-controls="advanced-options-panel"
                          title="Toggle Advanced Options"
                          disabled={loadingColumns}
                        >
                          {advancedMode ? <FaChevronUp /> : <FaChevronDown />}{' '}
                          Advanced Options
                        </button>
                      </div>

                      {/* Advanced Options Panel */}
                      {advancedMode && (
                        <div
                          id="advanced-options-panel"
                          className={styles.advancedOptionsPanel}
                        >
                          <div className={styles.advancedOptionsRow}>
                            {/* Separator */}
                            <div className={styles.inputContainer}>
                              <label htmlFor="separator">Separator</label>
                              <input
                                type="text"
                                id="separator"
                                value={aggregations.separator}
                                onChange={(e) =>
                                  handleAggregationChange('separator', e.target.value)
                                }
                                placeholder="Enter separator (e.g., >)"
                              />
                            </div>
                            {/* Level */}
                            <div className={styles.inputContainer}>
                              <label htmlFor="level">Level</label>
                              <input
                                type="number"
                                id="level"
                                value={aggregations.level}
                                onChange={(e) =>
                                  handleAggregationChange('level', e.target.value)
                                }
                                placeholder="Enter level (e.g., 2)"
                                min="1"
                              />
                            </div>
                          </div>
                          {/* Ignore Previous Levels */}
                          <div className={styles.advancedOptionRow}>
                            <div className={styles.checkboxContainer}>
                              <input
                                type="checkbox"
                                id="ignorePreviousLevels"
                                checked={aggregations.ignore_previous_levels}
                                onChange={(e) =>
                                  handleAggregationChange(
                                    'ignore_previous_levels',
                                    e.target.checked
                                  )
                                }
                              />
                              <label htmlFor="ignorePreviousLevels">
                                Ignore Previous Levels
                              </label>
                            </div>
                          </div>
                          {/* Example Group Value */}
                          <div className={styles.exampleContainer}>
                            {renderExampleGroupValue() && (
                              <>
                                <label>Example Group Value:</label>
                                {renderExampleGroupValue()}
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Filters Section */}
                  <div className={styles.filtersSection}>
                    <div className={styles.filtersHeader}>
                      <h3>Filters</h3>
                      <button
                        type="button"
                        className={styles.filtersToggleButton}
                        onClick={() => setFiltersExpanded(!filtersExpanded)}
                        aria-expanded={filtersExpanded}
                        aria-controls="filters-panel"
                        title="Toggle Filters"
                        disabled={loadingColumns}
                      >
                        {filtersExpanded ? <FaChevronUp /> : <FaChevronDown />}{' '}
                        {filtersExpanded ? 'Hide Filters' : 'Show Filters'}
                      </button>
                    </div>

                    {filtersExpanded && (
                      <div id="filters-panel" className={styles.filtersPanel}>
                        {filters.map((filter, index) => (
                          <div key={filter.id} className={styles.filterRow}>
                            {index > 0 && (
                              <div className={styles.connector}>
                                <Select
                                  options={logicalOperators}
                                  value={logicalOperators.find(
                                    (op) => op.value === filter.connector
                                  )}
                                  onChange={(option) =>
                                    handleFilterChange(
                                      filter.id,
                                      'connector',
                                      option ? option.value : 'AND'
                                    )
                                  }
                                  styles={customSelectStyles}
                                  placeholder="AND/OR"
                                  isClearable={false}
                                  className={styles.selectDropdown}
                                />
                              </div>
                            )}
                            <div className={styles.filterFields}>
                              <Select
                                options={columns.map((col) => ({
                                  value: col,
                                  label: col,
                                }))}
                                value={
                                  filter.field
                                    ? { value: filter.field, label: filter.field }
                                    : null
                                }
                                onChange={(option) =>
                                  handleFilterChange(
                                    filter.id,
                                    'field',
                                    option ? option.value : ''
                                  )
                                }
                                styles={customSelectStyles}
                                placeholder="Select Field"
                                isClearable
                                className={styles.selectDropdown}
                              />
                              <Select
                                options={filterOperators}
                                value={
                                  filter.operator
                                    ? filterOperators.find(
                                        (op) => op.value === filter.operator
                                      )
                                    : null
                                }
                                onChange={(option) =>
                                  handleFilterChange(
                                    filter.id,
                                    'operator',
                                    option ? option.value : ''
                                  )
                                }
                                styles={customSelectStyles}
                                placeholder="Select Operator"
                                isClearable
                                className={styles.selectDropdown}
                              />
                              {filter.operator === 'IN' ? (
                                <input
                                  type="text"
                                  value={filter.value}
                                  onChange={(e) =>
                                    handleFilterChange(
                                      filter.id,
                                      'value',
                                      e.target.value
                                    )
                                  }
                                  placeholder="Comma-separated values"
                                  className={styles.filterInput}
                                />
                              ) : (
                                <input
                                  type="text"
                                  value={filter.value}
                                  onChange={(e) =>
                                    handleFilterChange(
                                      filter.id,
                                      'value',
                                      e.target.value
                                    )
                                  }
                                  placeholder="Enter value"
                                  className={styles.filterInput}
                                />
                              )}
                            </div>
                            <button
                              type="button"
                              className={styles.removeFilterButton}
                              onClick={() => handleRemoveFilter(filter.id)}
                              title="Remove Filter"
                              aria-label="Remove Filter"
                            >
                              <FaTimes />
                            </button>
                          </div>
                        ))}
                        <button
                          type="button"
                          className={styles.addFilterButton}
                          onClick={handleAddFilter}
                          title="Add Filter"
                          aria-label="Add Filter"
                          disabled={loadingColumns}
                        >
                          <FaPlus />
                        </button>
                      </div>
                    )}
                  </div>

                  {/* Action Buttons */}
                  <div className={styles.actionButtons}>
                    <button
                      type="button"
                      className={styles.saveButton}
                      onClick={handleSaveAggregations}
                      disabled={
                        !aggregations.aggregate_function ||
                        !aggregations.column1 ||
                        !aggregations.column2 ||
                        loadingColumns ||
                        !hasUnsavedChanges
                      }
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className={styles.previewButton}
                      onClick={handlePreview}
                      disabled={
                        !aggregations.aggregate_function ||
                        !aggregations.column1 ||
                        !aggregations.column2 ||
                        loadingPreview ||
                        loadingColumns
                      }
                      title="Preview Aggregated Data"
                      aria-label="Preview Data"
                    >
                      {loadingPreview ? (
                        <ClipLoader color="#fff" size={15} />
                      ) : (
                        'Preview'
                      )}
                    </button>
                  </div>

                  {/* Success or Unsaved Messages */}
                  {saveSuccessMessage && (
                    <p className={styles.successMessage}>{saveSuccessMessage}</p>
                  )}
                  {hasUnsavedChanges && !saveSuccessMessage && (
                    <p className={styles.unsavedChangesMessage}>
                      You have unsaved changes.
                    </p>
                  )}

                  {/* Preview Results */}
                  {(previewData.length > 0 || loadingPreview || examplePreviewData.length > 0) && (
                    <>
                      {previewData.length > 0 && (
                        <div className={styles.previewSection}>
                          <h3>Preview Group</h3>
                          <table className={styles.previewTable}>
                            <thead>
                              <tr>
                                <th>Group Value</th>
                                <th>{`${aggregations.aggregate_function} (${aggregations.column1})`}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {previewData.map((row, index) => (
                                <tr key={index}>
                                  <td>{row.group_value}</td>
                                  <td>{row.aggregated_value}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}

                      {examplePreviewData.length > 0 && (
                        <div className={styles.examplePreviewSection}>
                          <h3>Final Output</h3>
                          <table className={styles.previewTable}>
                            <thead>
                              <tr>
                                <th>ID</th>
                                <th>Title</th>
                                <th>Group Value</th>
                                <th>
                                  {aggregations.attribute_name ||
                                    'Counted Group Value'}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {examplePreviewData.map((row, index) => (
                                <tr key={index}>
                                  <td>{row.example_id}</td>
                                  <td>{row.title}</td>
                                  <td>{row.group_value}</td>
                                  <td>{row.counted_group_value}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                      {loadingPreview && (
                        <div className={styles.sectionSpinner}>
                          <ClipLoader color="#4758EB" size={30} />
                        </div>
                      )}
                      {previewError && <p className={styles.error}>{previewError}</p>}
                    </>
                  )}
                </>
              )}
            </div>
          )}

          {/* Modal for adding a new project */}
          {isModalOpen && (
            <div className={styles.modalOverlay}>
              <div className={styles.modalContent}>
                <button
                  className={styles.closeButton}
                  onClick={() => setIsModalOpen(false)}
                  title="Close"
                  aria-label="Close Modal"
                >
                  <FaTimes />
                </button>
                <h3>Add New Aggregated Value Project</h3>
                <form onSubmit={handleAddProject} className={styles.form}>
                  <label htmlFor="projectName">Project Name</label>
                  <input
                    type="text"
                    id="projectName"
                    value={newProjectName}
                    onChange={(e) => setNewProjectName(e.target.value)}
                    placeholder="Enter project name"
                    required
                  />
                  {error && <p className={styles.error}>{error}</p>}
                  <button type="submit" className={styles.submitButton}>
                    Add Project
                  </button>
                </form>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AggregatedValues;
