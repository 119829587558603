import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaExclamationTriangle, FaInfo, FaArrowLeft, FaTimes } from 'react-icons/fa';
import { ClipLoader } from 'react-spinners';
import Select from 'react-select';
import styles from './css/Preview.module.css';

const Preview = ({ token }) => {
  const navigate = useNavigate();

  const [selectedId, setSelectedId] = useState('');
  const [generatedAttributes, setGeneratedAttributes] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [loadingGeneratedAttributes, setLoadingGeneratedAttributes] = useState(false);
  const [error, setError] = useState(null);
  const [imageWarning, setImageWarning] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);

  // State for controlling the popup
  const [showOriginalDataPopup, setShowOriginalDataPopup] = useState(false);

  // Geolocation options for react-select
  const geolocationOptions = [
    { value: 'DK', label: 'Denmark (DK)' },
    { value: 'AU', label: 'Australia (AU)' },
    { value: 'AT', label: 'Austria (AT)' },
    { value: 'BE', label: 'Belgium (BE)' },
    { value: 'BG', label: 'Bulgaria (BG)' },
    { value: 'CA', label: 'Canada (CA)' },
    { value: 'CH', label: 'Switzerland (CH)' },
    { value: 'CN', label: 'China (CN)' },
    { value: 'CY', label: 'Cyprus (CY)' },
    { value: 'CZ', label: 'Czechia (CZ)' },
    { value: 'DE', label: 'Germany (DE)' },
    { value: 'DK', label: 'Denmark (DK)' },
    { value: 'ES', label: 'Spain (ES)' },
    { value: 'EE', label: 'Estonia (EE)' },
    { value: 'FI', label: 'Finland (FI)' },
    { value: 'FR', label: 'France (FR)' },
    { value: 'FO', label: 'Faroe Islands (FO)' },
    { value: 'GB', label: 'United Kingdom (GB)' },
    { value: 'GE', label: 'Georgia (GE)' },
    { value: 'GI', label: 'Gibraltar (GI)' },
    { value: 'GR', label: 'Greece (GR)' },
    { value: 'GL', label: 'Greenland (GL)' },
    { value: 'HK', label: 'Hong Kong (HK)' },
    { value: 'HR', label: 'Croatia (HR)' },
    { value: 'HU', label: 'Hungary (HU)' },
    { value: 'IN', label: 'India (IN)' },
    { value: 'IE', label: 'Ireland (IE)' },
    { value: 'IS', label: 'Iceland (IS)' },
    { value: 'IT', label: 'Italy (IT)' },
    { value: 'JP', label: 'Japan (JP)' },
    { value: 'KR', label: 'South Korea (KR)' },
    { value: 'LI', label: 'Liechtenstein (LI)' },
    { value: 'LT', label: 'Lithuania (LT)' },
    { value: 'LU', label: 'Luxembourg (LU)' },
    { value: 'LV', label: 'Latvia (LV)' },
    { value: 'MD', label: 'Moldova (MD)' },
    { value: 'MX', label: 'Mexico (MX)' },
    { value: 'MT', label: 'Malta (MT)' },
    { value: 'ME', label: 'Montenegro (ME)' },
    { value: 'NL', label: 'Netherlands (NL)' },
    { value: 'NO', label: 'Norway (NO)' },
    { value: 'NZ', label: 'New Zealand (NZ)' },
    { value: 'PL', label: 'Poland (PL)' },
    { value: 'PT', label: 'Portugal (PT)' },
    { value: 'RO', label: 'Romania (RO)' },
    { value: 'SG', label: 'Singapore (SG)' },
    { value: 'RS', label: 'Serbia (RS)' },
    { value: 'SK', label: 'Slovakia (SK)' },
    { value: 'SI', label: 'Slovenia (SI)' },
    { value: 'SE', label: 'Sweden (SE)' },
    { value: 'TH', label: 'Thailand (TH)' },
    { value: 'TR', label: 'Turkey (TR)' },
    { value: 'TW', label: 'Taiwan (TW)' },
    { value: 'UA', label: 'Ukraine (UA)' },
    { value: 'UY', label: 'Uruguay (UY)' },
    { value: 'US', label: 'United States (US)' },
    { value: 'VN', label: 'Vietnam (VN)' },
    { value: 'ZA', label: 'South Africa (ZA)' },
  ];

  const [selectedGeolocation, setSelectedGeolocation] = useState(geolocationOptions[0]);

  // Attributes we compare
  const relevantAttributes = [
    'title',
    'description',
    'product_type',
    'brand',
    'color',
    'gender',
    'pattern',
    'material',
    'age_group',
    'size',
    'product_highlight1',
    'product_highlight2',
    'product_highlight3',
    'product_highlight4',
    'product_highlight5',
  ];

  // Helper to calculate optimization stats (added, optimized, unchanged)
  const calculateOptimizationStats = () => {
    if (!originalData || !generatedAttributes) {
      return { addedCount: 0, optimizedCount: 0, unchangedCount: 0 };
    }

    let addedCount = 0;
    let optimizedCount = 0;
    let unchangedCount = 0;

    relevantAttributes.forEach((attr) => {
      const beforeVal = originalData[attr] || '—';
      const afterVal = generatedAttributes[`qfe_${attr}`] || '—';

      if (beforeVal === afterVal) {
        // Exactly the same, count it as unchanged
        unchangedCount++;
      } else {
        const beforeWasEmpty = beforeVal === '—';
        const afterIsEmpty = afterVal === '—';

        if (beforeWasEmpty && !afterIsEmpty) {
          addedCount++;
        } else if (!beforeWasEmpty && !afterIsEmpty) {
          optimizedCount++;
        }
      }
    });

    return { addedCount, optimizedCount, unchangedCount };
  };

  // Custom styles for react-select
  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: '38px',
      borderRadius: '4px',
      border: state.isFocused ? '1px solid #999' : '1px solid #ccc',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#999',
      },
      fontSize: '14px',
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  // Handle preview generation
  const handleGeneratePreview = async () => {
    if (!selectedId) {
      setError('Product page URL is required.');
      return;
    }
    setGeneratedAttributes(null);
    setOriginalData(null);
    setLoadingGeneratedAttributes(true);
    setError(null);
    setIsGenerating(true);
    setImageWarning(null);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/generate-qfe-preview`,
        {
          product_page_url: selectedId,
          preview: true,
          geolocation: selectedGeolocation.value,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const { status, result } = response.data;

      if (status === 'error') {
        setError(result?.message || 'Error generating preview');
        setLoadingGeneratedAttributes(false);
        setIsGenerating(false);
        return;
      }

      if (result?.message && result.message.toLowerCase().includes('cannot be empty')) {
        setError(result.message);
        setLoadingGeneratedAttributes(false);
        setIsGenerating(false);
        return;
      }

      const { data, original_data, message: imageWarn } = result || {};
      setGeneratedAttributes(data || {});
      setOriginalData(original_data || {});
      setLoadingGeneratedAttributes(false);
      setIsGenerating(false);

      if (imageWarn) {
        setImageWarning(imageWarn);
      }
    } catch (err) {
      console.error('Error generating preview:', err);
      const errorMessage = err.response?.data?.message || 'Error generating preview';
      setError(errorMessage);
      setLoadingGeneratedAttributes(false);
      setIsGenerating(false);
      setImageWarning(null);
    }
  };

  // Render the popup's content (original data in a table)
  const renderOriginalDataTable = () => {
    if (!originalData) return null;

    const excludeKeys = [
      'product_highlight1',
      'product_highlight2',
      'product_highlight3',
      'product_highlight4',
      'product_highlight5',
      'all_highlights',
    ];

    const filteredKeys = Object.keys(originalData).filter(
      (key) => !excludeKeys.includes(key)
    );

    let allHighlights = originalData.all_highlights;
    if (allHighlights) {
      if (typeof allHighlights === 'string') {
        try {
          const parsed = JSON.parse(allHighlights);
          if (Array.isArray(parsed)) {
            allHighlights = parsed;
          } else {
            allHighlights = allHighlights.split(',').map((item) => item.trim());
          }
        } catch (e) {
          allHighlights = allHighlights.split(',').map((item) => item.trim());
        }
      }
    }

    return (
      <table className={styles.popupDataTable}>
        <thead>
          <tr>
            <th>Field</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {filteredKeys.map((key) => (
            <tr key={key}>
              <td className={styles.dataKey}>{key}</td>
              <td className={styles.dataValue}>{String(originalData[key])}</td>
            </tr>
          ))}
          {allHighlights && Array.isArray(allHighlights) && allHighlights.length > 0 && (
            <tr>
              <td className={styles.dataKey}>All Highlights</td>
              <td className={styles.dataValue}>
                <ul style={{ paddingLeft: '20px', margin: 0 }}>
                  {allHighlights.map((highlight, idx) => (
                    <li key={idx}>{highlight}</li>
                  ))}
                </ul>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  // Circle chart + stats
  const renderOptimizationScore = () => {
    const totalAttrs = relevantAttributes.length;
    const { addedCount, optimizedCount, unchangedCount } = calculateOptimizationStats();
    const totalOptimized = addedCount + optimizedCount;
    const fraction = totalAttrs > 0 ? totalOptimized / totalAttrs : 0;

    // Circle chart calculations
    const radius = 40;
    const circumference = 2 * Math.PI * radius;
    const progressOffset = circumference - circumference * fraction;

    return (
      <div className={styles.optimizationScoreContainer}>
        {/* Circular chart */}
        <svg
          className={styles.optimizationScoreSvg}
          width="100"
          height="100"
          viewBox="0 0 100 100"
        >
          {/* Circle background */}
          <circle
            className={styles.optimizationScoreBg}
            cx="50"
            cy="50"
            r={radius}
          />
          {/* Progress circle */}
          <circle
            className={styles.optimizationScoreProgress}
            cx="50"
            cy="50"
            r={radius}
            strokeDasharray={circumference}
            strokeDashoffset={progressOffset}
          />
          {/* Center text: X/Y */}
          <text
            x="50%"
            y="50%"
            dy=".3em"
            textAnchor="middle"
            className={styles.optimizationScoreText}
          >
            {totalOptimized}/{totalAttrs}
          </text>
        </svg>

        {/* Breakdown of changes */}
        <div className={styles.optimizationScoreTextContainer}>
          <p className={styles.optimizationBreakdown}>
            <strong>{addedCount}</strong> added
          </p>
          <p className={styles.optimizationBreakdown}>
            <strong>{optimizedCount}</strong> optimized
          </p>
          <p className={styles.optimizationBreakdown}>
            <strong>{unchangedCount}</strong> unchanged
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.previewContainer}>
      {/* Header row: back button + title */}
      <div className={styles.headerContainer}>
        <button onClick={() => navigate(-1)} className={styles.backButton}>
          <FaArrowLeft />
        </button>
        <h2 className={styles.title}>QFE Preview</h2>
      </div>

      {/* Row for geolocation select + product URL input */}
      <div className={styles.inputRow}>
        <div className={styles.selectContainer}>
          <Select
            options={geolocationOptions}
            value={selectedGeolocation}
            onChange={setSelectedGeolocation}
            isDisabled={isGenerating}
            styles={customSelectStyles}
            menuPortalTarget={document.body}
          />
        </div>
        <input
          type="text"
          placeholder="Enter product page URL"
          value={selectedId}
          onChange={(e) => setSelectedId(e.target.value)}
          disabled={isGenerating}
          className={styles.idInput}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleGeneratePreview();
            }
          }}
        />
      </div>

      {/* Button to generate preview */}
      <button
        onClick={handleGeneratePreview}
        disabled={isGenerating}
        className={styles.generateButton}
      >
        Generate Preview
      </button>

      {error && <p className={styles.error}>{error}</p>}

      {imageWarning && (
        <div className={styles.imageWarning}>
          <FaExclamationTriangle className={styles.warningIcon} />
          <span>
            {imageWarning} It’s recommended to test prompts with URLs that have valid image links
            to ensure optimal results.
          </span>
        </div>
      )}

      {loadingGeneratedAttributes && (
        <div className={styles.loadingContainer}>
          <ClipLoader />
          <p>Generating attributes...</p>
        </div>
      )}

      {/* Only show the result if we have generated data */}
      {generatedAttributes && originalData && !loadingGeneratedAttributes && !error && (
        <>
          <div className={styles.previewAndOptimizationRow}>
            {/* Product Preview Section */}
            <div className={styles.productPreviewContainer}>
              <div className={styles.productImageWrapper}>
                {originalData.image_link ? (
                  <img
                    src={originalData.image_link}
                    alt={originalData.title || 'Product Image'}
                    className={styles.productPreviewImage}
                  />
                ) : (
                  <div className={styles.noImagePlaceholder}>No Image</div>
                )}
              </div>

              <div className={styles.productInfo}>
                <h3 className={styles.productTitle}>
                  {originalData.title || 'No Title'}
                </h3>
                {originalData.brand && (
                  <p className={styles.productBrand}>{originalData.brand}</p>
                )}
              </div>

              {/* Info button to show the enhanced original data popup */}
              <button
                className={styles.infoButton}
                onClick={() => setShowOriginalDataPopup(true)}
                title="View Original Data"
              >
                <FaInfo />
              </button>
            </div>

            {/* Optimization Overview */}
            <div className={styles.optimizationSummary}>
              <h4>Optimizations Overview</h4>
              {renderOptimizationScore()}
            </div>
          </div>

          <div className={styles.attributeComparison}>
            <h4>Before / After Comparison</h4>

            {/* Desktop Version: Side-by-side table */}
            <table className={`${styles.comparisonTable} ${styles.desktopOnly}`}>
              <thead>
                <tr>
                  <th style={{ width: '20%' }}>Attribute</th>
                  <th style={{ width: '40%' }}>Before</th>
                  <th style={{ width: '40%' }}>After</th>
                </tr>
              </thead>
              <tbody>
                {relevantAttributes.map((attr) => {
                  const originalValue = originalData[attr] || '—';
                  const generatedValue = generatedAttributes[`qfe_${attr}`] || '—';
                  const isChanged = originalValue !== generatedValue;
                  return (
                    <tr
                      key={attr}
                      className={isChanged ? styles.changedRow : undefined}
                    >
                      <td className={styles.attrName}>
                        {attr.replace(/_/g, ' ')}
                      </td>
                      <td className={styles.attributeOriginal}>{originalValue}</td>
                      <td className={styles.attributeGenerated}>{generatedValue}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {/* Mobile Version: Vertically stacked cards */}
            <div className={`${styles.mobileComparison} ${styles.mobileOnly}`}>
              {relevantAttributes.map((attr) => {
                const originalValue = originalData[attr] || '—';
                const generatedValue = generatedAttributes[`qfe_${attr}`] || '—';
                const isChanged = originalValue !== generatedValue;
                return (
                  <div
                    key={attr}
                    className={`${styles.comparisonCard} ${
                      isChanged ? styles.changedCard : ''
                    }`}
                  >
                    <div className={styles.cardHeader}>
                      {attr.replace(/_/g, ' ')}
                    </div>
                    <div className={styles.cardContent}>
                      <div className={`${styles.cardSection} ${styles.beforeSection}`}>
                        <div className={styles.cardSectionTitle}>Before</div>
                        <div className={styles.cardSectionValue}>{originalValue}</div>
                      </div>
                      <div className={`${styles.cardSection} ${styles.afterSection}`}>
                        <div className={styles.cardSectionTitle}>After</div>
                        <div className={styles.cardSectionValue}>{generatedValue}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.comparisonInfo}>
              <p>
              The optimizations are based on the product page and focused on improving the Google-facing attributes, not the Product Details Page (PDP). Since we can’t see the underlying Google feed using the product URL, the optimization overview rating might not be completely accurate. However, this is our best estimate based on the information available from the product page.              </p>
              <p>
                We are not responsible for the content, and it is the user's own responsibility to use the tool correctly.
              </p>
            </div>
        </>
      )}

      {/* Enhanced Popup Modal for Original Data */}
      {showOriginalDataPopup && (
        <div
          className={styles.popupOverlay}
          onClick={() => setShowOriginalDataPopup(false)}
        >
          <div
            className={styles.popupContent}
            onClick={(e) => {
              // Prevent clicks inside from closing the popup
              e.stopPropagation();
            }}
          >
            <div className={styles.popupHeader}>
              <h3 className={styles.popupTitle}>Original Data</h3>
              <button
                className={styles.closePopupButton}
                onClick={() => setShowOriginalDataPopup(false)}
              >
                <FaTimes />
              </button>
            </div>
            <div className={styles.popupBody}>{renderOriginalDataTable()}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Preview;
