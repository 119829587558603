import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import ClipLoader from 'react-spinners/ClipLoader';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ReactMarkdown from 'react-markdown';
import {
  FaArrowLeft,
  FaPlus,
  FaTimes,
  FaUpload,
  FaDownload,
  FaChevronDown,
  FaBan,
  FaCheckCircle,
  FaChevronRight,
  FaTag,
  FaDatabase,
  FaEye,
  FaTable,
  FaSearch,
  FaFilePdf,
  FaLayerGroup
} from 'react-icons/fa';

import styles from './css/PromptBuilderPdp.module.css';
import PopupCreatePromptCombination from '../popups/PopupCreatePromptCombinationPdp';
import PopupImportDefaultPromptPdp from '../popups/PopupImportDefaultPromptPdp';
import PopupImportPromptCombinationPdp from '../popups/PopupImportPromptCombinationPdp';
import { PdpNavigationContext } from '../App';

const API_URL_PDP = process.env.REACT_APP_API_URL_PDP;

// Base columns
const baseColumns = [
  'meta_title',
  'meta_description',
  'h1',
  'h2',
  'intro',
  'description',
  'usp',
  'faq',
  'custom_field_1',
  'custom_field_2',
  'custom_field_3',
  'custom_field_4',
  'custom_field_5',
  'custom_field_6',
  'custom_field_7',
  'custom_field_8',
  'custom_field_9',
  'custom_field_10',
];

// Create empty object with all baseColumns as keys, each = ''
function createEmptyBaseColumnContents() {
  const obj = {};
  baseColumns.forEach((col) => {
    obj[col] = '';
  });
  return obj;
}

function createEmptyBaseColumnFormats() {
  const obj = {};
  baseColumns.forEach((col) => {
    obj[col] = true; // default = rich text
  });
  return obj;
}

function createDefaultColumnFormatsPlain() {
  const obj = {};
  baseColumns.forEach((col) => {
    obj[col] = false; // default = plain text
  });
  return obj;
}

// [Single Output] – We'll define a helper to identify "non-description" columns:
const NON_DESCRIPTION_COLUMNS = baseColumns.filter((c) => c !== 'description');

function safeQuillValue(val) {
  return val ?? '';
}

function getPreviewValue(previewData, col) {
  if (!previewData) return undefined;
  if (col.toLowerCase() === 'id') {
    return previewData.id;
  }
  const sourceKey = `source_${col}`;
  return previewData[sourceKey];
}

// UPDATE: Fix the ColumnTagger component to allow free typing with dropdown suggestions
const ColumnTagger = ({ value, onChange, columns, placeholder }) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(0);
  const textAreaRef = useRef(null);
  const suggestionBoxRef = useRef(null);
  const containerRef = useRef(null);
  
  // Force dropdown to show when @ is typed
  useEffect(() => {
    // Check if there's an active @ mention being typed
    const lastAtIndex = value.substring(0, cursorPosition).lastIndexOf('@');
    if (lastAtIndex !== -1) {
      const textAfterAt = value.substring(lastAtIndex + 1, cursorPosition);
      if (!textAfterAt.includes(' ') && !textAfterAt.includes('\n')) {
        const searchText = textAfterAt.trim();
        // Filter or show initial suggestions
        const filtered = searchText.length === 0 
          ? columns.slice(0, 10) 
          : columns.filter(col => col.toLowerCase().includes(searchText.toLowerCase()));
        
        setFilteredSuggestions(filtered);
        setShowSuggestions(true);
      }
    }
  }, [value, cursorPosition, columns]);
  
  // Handle clicks outside the suggestion box to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (suggestionBoxRef.current && !suggestionBoxRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  // Update the text and handle @ character
  const handleTextChange = (e) => {
    const newText = e.target.value;
    const position = e.target.selectionStart;
    
    // Always update the text immediately - allow free typing
    onChange(newText);
    setCursorPosition(position);
    
    // Check if we should show suggestions
    const textUntilCursor = newText.substring(0, position);
    const lastAtIndex = textUntilCursor.lastIndexOf('@');
    
    if (lastAtIndex !== -1) {
      const textAfterAt = textUntilCursor.substring(lastAtIndex + 1);
      // If there's no space or line break after @, we're in a potential column name
      if (!textAfterAt.includes(' ') && !textAfterAt.includes('\n')) {
        // Show suggestions immediately when @ is typed, even if no search text yet
        const searchText = textAfterAt.trim();
        
        // Filter suggestions
        const filtered = searchText.length === 0 
          ? columns.slice(0, 10) // Show first 10 columns if @ is just typed
          : columns.filter(col => col.toLowerCase().includes(searchText.toLowerCase()));
        
        setFilteredSuggestions(filtered);
        setSelectedSuggestionIndex(0);
        setShowSuggestions(true); // Always show dropdown when @ is active
        return;
      }
    }
    
    // Close suggestions if not in @ context
    setShowSuggestions(false);
  };
  
  // Position the dropdown using fixed positioning to prevent clipping
  const getDropdownPosition = () => {
    if (!textAreaRef.current) {
      return {}; 
    }
    
    // Get absolute position in the viewport
    const textareaRect = textAreaRef.current.getBoundingClientRect();
    
    return {
      position: 'fixed', // Use fixed position relative to viewport
      top: `${textareaRect.bottom + 5}px`, // Position below textarea
      left: `${textareaRect.left}px`,
      width: `${textareaRect.width}px`,
      maxHeight: '300px', // Increase max height
      zIndex: 10000 // Ensure it's above everything
    };
  };
  
  // Insert the selected column at cursor position with an added space afterward
  const insertColumn = (column) => {
    const textUntilCursor = value.substring(0, cursorPosition);
    const lastAtIndex = textUntilCursor.lastIndexOf('@');
    
    if (lastAtIndex !== -1) {
      // Replace text from @ to cursor with the selected column
      const textBeforeAt = value.substring(0, lastAtIndex);
      const textAfterCursor = value.substring(cursorPosition);
      
      // Add a space after the column name
      const newText = `${textBeforeAt}@${column} ${textAfterCursor}`;
      
      onChange(newText);
      setShowSuggestions(false);
      
      // Set focus back to textarea and position cursor after the inserted column name + space
      setTimeout(() => {
        if (textAreaRef.current) {
          textAreaRef.current.focus();
          const newPosition = lastAtIndex + column.length + 2; // +1 for @ and +1 for space
          textAreaRef.current.setSelectionRange(newPosition, newPosition);
        }
      }, 0);
    }
  };
  
  const handleKeyDown = (e) => {
    // Allow navigating suggestions with arrow keys
    if (showSuggestions && filteredSuggestions.length > 0) {
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        setSelectedSuggestionIndex(prev => 
          prev > 0 ? prev - 1 : filteredSuggestions.length - 1
        );
      }
      
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        setSelectedSuggestionIndex(prev => 
          prev < filteredSuggestions.length - 1 ? prev + 1 : 0
        );
      }
      
      // Select suggestion with Enter or Tab
      if ((e.key === 'Enter' || e.key === 'Tab') && showSuggestions) {
        e.preventDefault();
        insertColumn(filteredSuggestions[selectedSuggestionIndex]);
      }
      
      // Close suggestions with Escape
      if (e.key === 'Escape') {
        e.preventDefault();
        setShowSuggestions(false);
      }
    }
  };
  
  // Render styled mentions in preview
  const renderTextWithMentions = () => {
    const parts = [];
    const regex = /@([a-zA-Z0-9_]+)/g;
    let lastIndex = 0;
    let match;
    
    while ((match = regex.exec(value)) !== null) {
      // Add text before the match
      if (match.index > lastIndex) {
        parts.push(value.substring(lastIndex, match.index));
      }
      
      // Check if this is a valid column
      const columnName = match[1];
      const isValidColumn = columns.includes(columnName);
      
      // Add the styled mention with different style based on validity
      parts.push(
        <span 
          key={`mention-${match.index}`} 
          className={isValidColumn ? styles.insertedMention : styles.invalidMention}
          title={isValidColumn ? `Column: ${columnName}` : `Warning: '${columnName}' is not a valid column`}
        >
          @{columnName}
        </span>
      );
      
      lastIndex = match.index + match[0].length;
    }
    
    // Add remaining text
    if (lastIndex < value.length) {
      parts.push(value.substring(lastIndex));
    }
    
    return parts;
  };
  
  return (
    <div className={styles.columnTaggerContainer} ref={containerRef}>
      <div className={styles.columnTaggerInputWrapper}>
        <textarea
          ref={textAreaRef}
          value={value}
          onChange={handleTextChange}
          onKeyDown={handleKeyDown}
          placeholder={placeholder || "Type '@' to mention columns..."}
          className={styles.columnTaggerTextarea}
        />
        
        {/* Used for accessibility and explaining the component */}
        <div className={styles.columnTaggerHint}>
          <FaTag size={14} />
          <span>Type @ to see available columns. Use ↑/↓ and Enter to select.</span>
        </div>
      </div>
      
      {/* Styled preview of the text with highlighted mentions */}
      <div className={styles.columnTaggerPreview}>
        {renderTextWithMentions()}
      </div>
      
      {/* Suggestions dropdown */}
      {showSuggestions && (
        <div 
          ref={suggestionBoxRef}
          className={styles.columnTaggerSuggestions}
          style={getDropdownPosition()}
        >
          <div className={styles.suggestionHeader}>
            Available Columns
          </div>
          {filteredSuggestions.length > 0 ? (
            <ul>
              {filteredSuggestions.slice(0, 10).map((suggestion, index) => (
                <li 
                  key={suggestion} 
                  onClick={() => insertColumn(suggestion)}
                  className={index === selectedSuggestionIndex ? styles.activeColumnSuggestion : ''}
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          ) : (
            <div className={styles.noSuggestions}>No matching columns</div>
          )}
        </div>
      )}
    </div>
  );
};

function PromptBuilderPdp({
  token,
  isAdmin,
  selectedClient,
  setSelectedClient,
  selectedPromptCombination,
  setSelectedPromptCombination,
}) {
  const navigate = useNavigate();
  const { pdpTableState } = useContext(PdpNavigationContext);

  // --- Clients ---
  const [linkedClients, setLinkedClients] = useState([]);
  const [isClientsLoading, setIsClientsLoading] = useState(false);
  const [clientError, setClientError] = useState(null);

  // --- Prompt combos ---
  const [promptCombinations, setPromptCombinations] = useState([]);
  const [isPromptLoading, setIsPromptLoading] = useState(false);
  const [promptError, setPromptError] = useState(null);

  // --- Importing prompts ---
  const [isImportPopupOpen, setIsImportPopupOpen] = useState(false);
  const [importPromptType, setImportPromptType] = useState(null);
  const [isImportComboPopupOpen, setIsImportComboPopupOpen] = useState(false);

  // --- Preview Generation ---
  const [previewRowId, setPreviewRowId] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [generationError, setGenerationError] = useState(null);
  const [generatedPreview, setGeneratedPreview] = useState(null);
  const [previewRichTextMode, setPreviewRichTextMode] = useState(true); // New state for preview rich text mode

  // --- Columns + JSON Keys ---
  const [allColumns, setAllColumns] = useState([]);

  // --- Prompt Combination Data ---
  const [promptCombinationData, setPromptCombinationData] = useState(null);

  // --- Loading states ---
  const [isLoadingAll, setIsLoadingAll] = useState(false);

  // --- Collapsible text fields ---
  const [systemPromptExpanded, setSystemPromptExpanded] = useState(true);
  const [imagePromptExpanded, setImagePromptExpanded] = useState(false);
  const [instructionPromptExpanded, setInstructionPromptExpanded] = useState(false);
  const [auditPromptExpanded, setAuditPromptExpanded] = useState(false);

  // ----------------------------
  // New state for Research Prompt
  // ----------------------------
  const [researchPromptExpanded, setResearchPromptExpanded] = useState(false);

  // ----------------------------
  // Add an explicit toggle for "researchMode"
  // ----------------------------
  const [researchMode, setResearchMode] = useState(false);

  // ----------------------------
  // Add a toggle for "includePdfs" and selected PDF mapping
  // ----------------------------
  const [includePdfs, setIncludePdfs] = useState(false);
  const [selectedPdfMapping, setSelectedPdfMapping] = useState(null);
  const [pdfMappings, setPdfMappings] = useState([]);
  const [isPdfMappingsLoading, setIsPdfMappingsLoading] = useState(false);
  const [pdfMappingsError, setPdfMappingsError] = useState(null);

  // ----------------------------
  // Add a toggle for "includeFeedData" (default to true)
  // ----------------------------
  const [includeFeedData, setIncludeFeedData] = useState(true);

  // --- Popup (create combo) ---
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  // --- Deletion states ---
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState(null);

  // --- Status / message bar ---
  const [statusMessage, setStatusMessage] = useState(null);
  const [statusType, setStatusType] = useState(null); // 'success' or 'error'

  // --- Unsaved changes ---
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  // Add window beforeunload event listener to warn when navigating away with unsaved changes
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasUnsavedChanges) {
        // Standard beforeunload message - browser will show generic message
        e.preventDefault();
        const message = 'You have unsaved changes. Are you sure you want to leave?';
        e.returnValue = message;
        return message;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [hasUnsavedChanges]);

  // Function to check for unsaved changes before navigation
  const confirmNavigation = (callback) => {
    if (hasUnsavedChanges) {
      const confirmLeave = window.confirm(
        'You have unsaved changes. Are you sure you want to proceed without saving?'
      );
      if (confirmLeave) {
        callback();
      }
    } else {
      callback();
    }
  };

  // --- Few Shot Prompts ---
  const [fewShotPrompts, setFewShotPrompts] = useState([]);
  const debounceTimeoutsRef = useRef({});

  // ===============================
  // Toggle for multiOutput
  // ===============================
  const [multiOutput, setMultiOutput] = useState(false);

  const handleToggleMultiOutput = () => {
    setMultiOutput((prev) => {
      const newVal = !prev;
      if (newVal === false) {
        // For each few-shot, empty all base columns except "description"
        setFewShotPrompts((oldShots) =>
          oldShots.map((shot) => {
            const updatedContents = { ...shot.baseColumnContents };
            NON_DESCRIPTION_COLUMNS.forEach((col) => {
              updatedContents[col] = '';
            });
            return {
              ...shot,
              baseColumnContents: updatedContents,
            };
          })
        );
      }
      return newVal;
    });
    setHasUnsavedChanges(true);
  };

  // 1) FETCH LINKED CLIENTS
  useEffect(() => {
    const fetchLinkedClients = async () => {
      try {
        setIsClientsLoading(true);
        setClientError(null);
        const response = await axios.get(`${API_URL_PDP}/client-data`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status === 200) {
          const clientOptions = response.data.linked_clients.map((client) => ({
            label: client,
            value: client,
          }));
          setLinkedClients(clientOptions);
        } else {
          setClientError('Failed to load clients');
        }
      } catch (error) {
        console.error('Error fetching clients:', error);
        setClientError('An error occurred while loading clients.');
      } finally {
        setIsClientsLoading(false);
      }
    };
    fetchLinkedClients();
  }, [token]);

  // 2) FETCH PROMPT COMBINATIONS
  useEffect(() => {
    if (!selectedClient) {
      setPromptCombinations([]);
      setSelectedPromptCombination(null);
      return;
    }

    const fetchPromptCombinations = async () => {
      try {
        setIsPromptLoading(true);
        setPromptError(null);
        const response = await axios.get(`${API_URL_PDP}/get-prompt-combinations`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { client_name: selectedClient },
        });

        if (response.status === 200) {
          const combinations = response.data.prompt_combinations.map((comb) => ({
            label: comb,
            value: comb,
          }));
          setPromptCombinations(combinations);
        } else {
          setPromptError('Failed to load prompt combinations');
        }
      } catch (error) {
        console.error('Error fetching prompt combinations:', error);
        setPromptError('An error occurred while loading prompt combinations.');
      } finally {
        setIsPromptLoading(false);
      }
    };

    fetchPromptCombinations();
  }, [selectedClient, token]);

  // 2.5) FETCH PDF MAPPINGS
  useEffect(() => {
    if (!selectedClient) {
      setPdfMappings([]);
      setSelectedPdfMapping(null);
      return;
    }

    const fetchPdfMappings = async () => {
      try {
        setIsPdfMappingsLoading(true);
        setPdfMappingsError(null);
        const response = await axios.get(`${API_URL_PDP}/get-pdf-mappings`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { client_name: selectedClient },
        });

        if (response.status === 200) {
          const mappings = response.data.mappings.map((mapping) => ({
            label: mapping.mapping_name,
            value: mapping.mapping_name,
          }));
          setPdfMappings(mappings);
        } else {
          setPdfMappingsError('Failed to load PDF mappings');
        }
      } catch (error) {
        console.error('Error fetching PDF mappings:', error);
        setPdfMappingsError('An error occurred while loading PDF mappings.');
      } finally {
        setIsPdfMappingsLoading(false);
      }
    };

    fetchPdfMappings();
  }, [selectedClient, token]);

  // 3) FETCH COLUMNS + PROMPT COMBINATION DATA
  useEffect(() => {
    if (!selectedClient) {
      setAllColumns([]);
      setPromptCombinationData(null);
      setFewShotPrompts([]);
      setMultiOutput(false);
      setResearchMode(false);
      return;
    }

    let cancel = false;
    setIsLoadingAll(true);

    (async () => {
      try {
        const columnsPromise = axios.get(`${API_URL_PDP}/column-data`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { client_name: selectedClient },
        });

        let comboPromise;
        if (selectedPromptCombination) {
          comboPromise = axios.get(`${API_URL_PDP}/get-prompt-combination-data`, {
            headers: { Authorization: `Bearer ${token}` },
            params: {
              client_name: selectedClient,
              prompt_combination_name: selectedPromptCombination,
            },
          });
        } else {
          comboPromise = Promise.resolve({ data: null });
        }

        const [columnsRes, comboRes] = await Promise.all([columnsPromise, comboPromise]);
        if (cancel) return;

        // Process columns
        let mergedColumns = [];
        if (columnsRes.status === 200) {
          const { columns, json_keys } = columnsRes.data;

          // Force "id" first if it exists
          const idColumn = columns.find((col) => col.toLowerCase() === 'id');
          const filteredColumns = idColumn ? [idColumn] : [];
          mergedColumns = Array.from(new Set([...filteredColumns, ...json_keys]));
          mergedColumns.sort();

          setAllColumns(mergedColumns);
        } else {
          setAllColumns([]);
          setStatusMessage('Failed to load columns and JSON keys.');
          setStatusType('error');
        }

        // Process prompt combination data
        const comboData = comboRes?.data;
        if (!comboData) {
          // If no combination data, set defaults
          setPromptCombinationData({
            selectedColumns: mergedColumns,
            systemPrompt: '',
            imagePrompt: '',
            instructionPrompt: '',
            auditPromptActivate: false,
            auditPromptText: '',
            researchPrompt: '', // new
          });
          setFewShotPrompts([]);
          setMultiOutput(false);
          setResearchMode(false);
          setIncludePdfs(false);
          setSelectedPdfMapping(null);
        } else {
          if (comboRes.status === 200 && comboData) {
            const data = comboData;
            const fewShotsFromBackend = data.few_shot_prompts || [];
            const newFewShots = fewShotsFromBackend.map((shot) => {
              const loadedColumnFormats = shot.column_formats || {};
              const columnFormats = {};
              baseColumns.forEach((col) => {
                columnFormats[col] =
                  loadedColumnFormats[col] !== undefined
                    ? loadedColumnFormats[col]
                    : shot.is_rich_text !== undefined
                    ? shot.is_rich_text
                    : true;
              });

              let previewData = null;
              if (shot.input) {
                const newPreviewData = {};
                if (typeof shot.input.id !== 'undefined') {
                  newPreviewData.id = shot.input.id;
                }
                Object.keys(shot.input).forEach((colKey) => {
                  if (colKey.toLowerCase() !== 'id') {
                    newPreviewData[`source_${colKey}`] = shot.input[colKey];
                  }
                });
                previewData = newPreviewData;
              }

              return {
                idValue: shot.id_value || '',
                baseColumnContents: shot.output || {},
                columnFormats,
                previewData,
                previewError: null,
                isLoadingPreview: false,
                isExpanded: true,
              };
            });

            let finalSelectedCols = [];
            if (data.selected_columns && data.selected_columns.length > 0) {
              finalSelectedCols = data.selected_columns;
            } else {
              finalSelectedCols = mergedColumns;
            }

            setMultiOutput(data.multiOutput || false);
            setResearchMode(data.research_mode || false); // new
            setPromptCombinationData({
              selectedColumns: finalSelectedCols,
              systemPrompt: data.system_prompt || '',
              imagePrompt: data.image_prompt || '',
              instructionPrompt: data.instruction_prompt || '',
              auditPromptActivate: data.audit_prompt_activate || false,
              auditPromptText: data.audit_prompt_text || '',
              researchPrompt: data.research_prompt || '', // new
            });
            setFewShotPrompts(newFewShots);
            
            // Set PDF related states
            setIncludePdfs(data.include_pdfs || false);
            if (data.pdf_mapping) {
              setSelectedPdfMapping(data.pdf_mapping);
            }
            
            // Set Feed Data state
            setIncludeFeedData(data.include_feed_data !== undefined ? data.include_feed_data : true);
          } else {
            setPromptCombinationData({
              selectedColumns: mergedColumns,
              systemPrompt: '',
              imagePrompt: '',
              instructionPrompt: '',
              auditPromptActivate: false,
              auditPromptText: '',
              researchPrompt: '', // new
            });
            setFewShotPrompts([]);
            setMultiOutput(false);
            setResearchMode(false);
            setIncludePdfs(false);
            setSelectedPdfMapping(null);
          }
        }
      } catch (err) {
        console.error('Error fetching columns/prompt combo:', err);
        setAllColumns([]);
        setPromptCombinationData(null);
        setFewShotPrompts([]);
        setStatusMessage('An error occurred while loading data.');
        setStatusType('error');
      } finally {
        if (!cancel) {
          setIsLoadingAll(false);
        }
      }
    })();

    return () => {
      cancel = true;
    };
  }, [selectedClient, selectedPromptCombination, token]);

  // After importing a Prompt Combination, refresh combos
  const handleImportedCombo = (importedName) => {
    setStatusMessage(`Prompt combination "${importedName}" imported successfully.`);
    setStatusType('success');
    setTimeout(() => {
      setStatusMessage(null);
      setStatusType(null);
    }, 5000);

    if (selectedClient) {
      axios
        .get(`${API_URL_PDP}/get-prompt-combinations`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { client_name: selectedClient },
        })
        .then((res) => {
          if (res.status === 200) {
            const combos = res.data.prompt_combinations.map((c) => ({
              label: c,
              value: c,
            }));
            setPromptCombinations(combos);

            const importedExists = combos.some((combo) => combo.value === importedName);
            if (importedExists) {
              setSelectedPromptCombination(importedName);
              console.log(`Successfully selected imported prompt: ${importedName}`);
            } else {
              console.warn(`Imported prompt "${importedName}" not found in the updated list`);
            }
          }
        })
        .catch((err) => {
          console.error('Error refreshing combos after import:', err);
          setStatusMessage('Failed to refresh prompt combinations after import.');
          setStatusType('error');
        });
    }
  };

  // CREATE new combination
  const handleAdd = () => {
    confirmNavigation(() => {
      setIsPopupOpen(true);
    });
  };

  const handleCreated = (newPromptName) => {
    console.log('New prompt combination created:', newPromptName);
    setStatusMessage(`Prompt combination "${newPromptName}" created successfully.`);
    setStatusType('success');
    setTimeout(() => {
      setStatusMessage(null);
      setStatusType(null);
    }, 5000);

    if (selectedClient) {
      axios
        .get(`${API_URL_PDP}/get-prompt-combinations`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { client_name: selectedClient },
        })
        .then((response) => {
          if (response.status === 200) {
            const combos = response.data.prompt_combinations.map((comb) => ({
              label: comb,
              value: comb,
            }));
            setPromptCombinations(combos);

            const newPromptExists = combos.some((combo) => combo.value === newPromptName);
            if (newPromptExists) {
              setSelectedPromptCombination(newPromptName);
              console.log(`Successfully selected new prompt: ${newPromptName}`);
            } else {
              console.warn(`New prompt "${newPromptName}" not found in the updated list`);
            }
          }
        })
        .catch((error) => {
          console.error('Error refreshing prompt combos:', error);
          setStatusMessage('Failed to refresh prompt combinations.');
          setStatusType('error');
          setTimeout(() => {
            setStatusMessage(null);
            setStatusType(null);
          }, 5000);
        });
    }
  };

  // DELETE combination
  const handleRemove = async () => {
    if (!selectedPromptCombination) return;
    const confirmDeletion = window.confirm(
      `Are you sure you want to delete the prompt combination "${selectedPromptCombination}"?`
    );
    if (!confirmDeletion) return;

    try {
      setIsDeleting(true);
      setDeleteError(null);

      const response = await axios.delete(`${API_URL_PDP}/delete-prompt-combination`, {
        headers: { Authorization: `Bearer ${token}` },
        data: {
          client_name: selectedClient,
          prompt_combination_name: selectedPromptCombination,
        },
      });

      if (response.status === 200) {
        setPromptCombinations((prev) =>
          prev.filter((comb) => comb.value !== selectedPromptCombination)
        );
        setSelectedPromptCombination(null);
        setPromptCombinationData(null);

        setStatusMessage(
          `Prompt combination "${selectedPromptCombination}" deleted successfully.`
        );
        setStatusType('success');
        setTimeout(() => {
          setStatusMessage(null);
          setStatusType(null);
        }, 5000);
      } else {
        setDeleteError('Failed to delete prompt combination.');
        setStatusMessage('Failed to delete prompt combination.');
        setStatusType('error');
        setTimeout(() => {
          setStatusMessage(null);
          setStatusType(null);
        }, 5000);
      }
    } catch (error) {
      console.error('Error deleting prompt combination:', error);
      const errorMsg =
        error.response?.data?.message ||
        'An error occurred while deleting the prompt combination.';
      setDeleteError(errorMsg);

      setStatusMessage(errorMsg);
      setStatusType('error');
      setTimeout(() => {
        setStatusMessage(null);
        setStatusType(null);
      }, 5000);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleImportSystem = (e) => {
    e.stopPropagation();
    confirmNavigation(() => {
      setImportPromptType('system');
      setIsImportPopupOpen(true);
    });
  };
  const handleImportImage = (e) => {
    e.stopPropagation();
    confirmNavigation(() => {
      setImportPromptType('image');
      setIsImportPopupOpen(true);
    });
  };
  const handleImportInstruction = (e) => {
    e.stopPropagation();
    confirmNavigation(() => {
      setImportPromptType('instruction');
      setIsImportPopupOpen(true);
    });
  };
  const handleImportResearch = (e) => {
    e.stopPropagation();
    confirmNavigation(() => {
      setImportPromptType('research');
      setIsImportPopupOpen(true);
    });
  };

  const handleImportPromptText = (importedText) => {
    setHasUnsavedChanges(true);

    if (importPromptType === 'system') {
      setPromptCombinationData((prev) => ({ ...prev, systemPrompt: importedText }));
    } else if (importPromptType === 'image') {
      setPromptCombinationData((prev) => ({ ...prev, imagePrompt: importedText }));
    } else if (importPromptType === 'instruction') {
      setPromptCombinationData((prev) => ({ ...prev, instructionPrompt: importedText }));
    } else if (importPromptType === 'research') {
      setPromptCombinationData((prev) => ({ ...prev, researchPrompt: importedText }));
    }
  };

  const handleBack = () => {
    confirmNavigation(() => {
      navigate('/pdp-generation');
    });
  };

  // Select Handlers
  const handleSelectClient = (option) => {
    const proceed = () => {
      setSelectedClient(option ? option.value : null);
      setAllColumns([]);
      setPromptCombinationData(null);
      setStatusMessage(null);
      setStatusType(null);
      setHasUnsavedChanges(false);
      setFewShotPrompts([]);
      setGeneratedPreview(null);
    };

    if (hasUnsavedChanges) {
      confirmNavigation(proceed);
    } else {
      proceed();
    }
  };

  const handleSelectPromptCombination = (option) => {
    const proceed = () => {
      setSelectedPromptCombination(option ? option.value : null);
      setStatusMessage(null);
      setStatusType(null);
      setFewShotPrompts([]);
      setGeneratedPreview(null);
    };

    if (hasUnsavedChanges) {
      confirmNavigation(proceed);
    } else {
      proceed();
    }
  };

  const handleMultiSelectColumns = (selectedOptions) => {
    const newValues = selectedOptions ? selectedOptions.map((opt) => opt.value) : [];
    setPromptCombinationData((prev) => ({
      ...prev,
      selectedColumns: newValues,
    }));
    setHasUnsavedChanges(true);
  };

  const [isFocused, setIsFocused] = useState(false);

  // PROMPT TEXT FIELD HANDLERS
  const handleChangeSystemPrompt = (e) => {
    setPromptCombinationData((prev) => ({
      ...prev,
      systemPrompt: e.target.value,
    }));
    setHasUnsavedChanges(true);
  };
  const handleChangeImagePrompt = (e) => {
    setPromptCombinationData((prev) => ({
      ...prev,
      imagePrompt: e.target.value,
    }));
    setHasUnsavedChanges(true);
  };
  const handleChangeInstructionPrompt = (e) => {
    setPromptCombinationData((prev) => ({
      ...prev,
      instructionPrompt: e.target.value,
    }));
    setHasUnsavedChanges(true);
  };

  const toggleSystemPrompt = () => setSystemPromptExpanded((prev) => !prev);
  const toggleImagePrompt = () => setImagePromptExpanded((prev) => !prev);
  const toggleInstructionPrompt = () => setInstructionPromptExpanded((prev) => !prev);
  const toggleAuditPrompt = () => setAuditPromptExpanded((prev) => !prev);

  // Research Prompt Collapsible
  const toggleResearchPrompt = () => {
    setResearchPromptExpanded((prev) => !prev);
  };

  // Toggle for "researchMode"
  const handleToggleResearchMode = () => {
    setResearchMode((prev) => !prev);
    setHasUnsavedChanges(true);
  };

  // Toggle for "includePdfs"
  const handleToggleIncludePdfs = () => {
    // Only allow toggling if there are PDF mappings available
    if (pdfMappings.length > 0) {
      setIncludePdfs((prev) => !prev);
      setHasUnsavedChanges(true);
    }
  };

  // Toggle for "includeFeedData"
  const handleToggleFeedData = () => {
    setIncludeFeedData((prev) => !prev);
    setHasUnsavedChanges(true);
  };

  // Handle PDF mapping selection
  const handleSelectPdfMapping = (option) => {
    setSelectedPdfMapping(option.value);
    setHasUnsavedChanges(true);
  };

  // Audit Prompt
  const handleToggleAuditActivate = () => {
    setPromptCombinationData((prev) => ({
      ...prev,
      auditPromptActivate: !prev.auditPromptActivate,
    }));
    setHasUnsavedChanges(true);
  };

  const handleChangeAuditPromptText = (e) => {
    setPromptCombinationData((prev) => ({
      ...prev,
      auditPromptText: e.target.value,
    }));
    setHasUnsavedChanges(true);
  };

  // FEW SHOT PROMPTS
  const handleAddFewShotPrompt = () => {
    const newPrompt = {
      idValue: '',
      baseColumnContents: createEmptyBaseColumnContents(),
      columnFormats: createDefaultColumnFormatsPlain(),
      previewData: null,
      previewError: null,
      isLoadingPreview: false,
      isExpanded: true,
    };
    setFewShotPrompts((prev) => [...prev, newPrompt]);
    setHasUnsavedChanges(true);
  };

  const handleRemoveFewShotPrompt = (index) => {
    if (debounceTimeoutsRef.current[index]) {
      clearTimeout(debounceTimeoutsRef.current[index]);
      delete debounceTimeoutsRef.current[index];
    }
    setFewShotPrompts((prev) => prev.filter((_, i) => i !== index));
    setHasUnsavedChanges(true);
  };

  const handleToggleColumnFormat = (promptIndex, col) => {
    setFewShotPrompts((prev) =>
      prev.map((prompt, i) => {
        if (i !== promptIndex) return prompt;
        const newFormats = { ...prompt.columnFormats };
        newFormats[col] = !newFormats[col];
        return { ...prompt, columnFormats: newFormats };
      })
    );
    setHasUnsavedChanges(true);
  };

  const handleFewShotIdChange = (index, rawVal) => {
    const newVal = rawVal.trim();
    setFewShotPrompts((prev) => {
      const updated = [...prev];
      updated[index] = {
        ...updated[index],
        idValue: newVal,
        previewData: null,
        previewError: null,
        isLoadingPreview: newVal !== '',
      };
      return updated;
    });
    setHasUnsavedChanges(true);

    if (debounceTimeoutsRef.current[index]) {
      clearTimeout(debounceTimeoutsRef.current[index]);
    }
    if (!newVal) return;

    debounceTimeoutsRef.current[index] = setTimeout(() => {
      fetchPreviewData(index, newVal);
    }, 1000);
  };

  const fetchPreviewData = async (index, idVal) => {
    try {
      setFewShotPrompts((prev) => {
        const updated = [...prev];
        if (updated[index]) {
          updated[index].isLoadingPreview = true;
          updated[index].previewError = null;
          updated[index].previewData = null;
        }
        return updated;
      });

      const res = await axios.get(`${API_URL_PDP}/fetch-pdp-row`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          client_name: selectedClient,
          row_id: idVal,
        },
      });

      if (res.status === 200 && res.data) {
        setFewShotPrompts((prev) => {
          const updated = [...prev];
          if (updated[index]) {
            updated[index].previewData = res.data;
            updated[index].previewError = null;
            updated[index].isLoadingPreview = false;
          }
          return updated;
        });
      }
    } catch (error) {
      console.error('Error fetching preview data:', error);
      let errMsg = 'Error fetching data.';
      if (error.response && error.response.status === 404) {
        errMsg = error.response.data?.message || `No data found for ID="${idVal}".`;
      }
      setFewShotPrompts((prev) => {
        const updated = [...prev];
        if (updated[index]) {
          updated[index].previewData = null;
          updated[index].previewError = errMsg;
          updated[index].isLoadingPreview = false;
        }
        return updated;
      });
    }
  };

  const handleBaseColumnChange = (promptIndex, colName, newValue) => {
    setFewShotPrompts((prev) =>
      prev.map((prompt, i) => {
        if (i !== promptIndex) return prompt;
        return {
          ...prompt,
          baseColumnContents: {
            ...prompt.baseColumnContents,
            [colName]: newValue,
          },
        };
      })
    );
    setHasUnsavedChanges(true);
  };

  const handleToggleFewShotExpand = (index, e) => {
    if (e && e.target && e.target.closest('button')) {
      return;
    }
    setFewShotPrompts((prev) =>
      prev.map((prompt, i) => {
        if (i === index) {
          return { ...prompt, isExpanded: !prompt.isExpanded };
        }
        return prompt;
      })
    );
  };

  // SAVE CHANGES
  const handleSave = async () => {
    if (!selectedClient || !selectedPromptCombination || !promptCombinationData) return;

    try {
      const fewShotDataToSave = fewShotPrompts.map((prompt) => {
        let inputObj = {};
        if (prompt.previewData && promptCombinationData.selectedColumns) {
          promptCombinationData.selectedColumns.forEach((col) => {
            if (col.toLowerCase() === 'id') {
              inputObj['id'] = prompt.previewData.id || '';
            } else {
              const val = prompt.previewData[`source_${col}`];
              inputObj[col] = val !== undefined ? val : '';
            }
          });
        }
        return {
          id_value: prompt.idValue,
          column_formats: prompt.columnFormats,
          input: inputObj,
          output: prompt.baseColumnContents,
        };
      });

      const response = await axios.put(
        `${API_URL_PDP}/update-prompt-combination`,
        {
          client_name: selectedClient,
          prompt_combination_name: selectedPromptCombination,
          selected_columns: promptCombinationData.selectedColumns,
          system_prompt: promptCombinationData.systemPrompt,
          image_prompt: promptCombinationData.imagePrompt,
          instruction_prompt: promptCombinationData.instructionPrompt,
          few_shot_prompts: fewShotDataToSave,
          audit_prompt_activate: promptCombinationData.auditPromptActivate,
          audit_prompt_text: promptCombinationData.auditPromptText,
          multiOutput: multiOutput,
          research_prompt: promptCombinationData.researchPrompt,
          research_mode: researchMode,
          include_pdfs: includePdfs,
          pdf_mapping: includePdfs ? selectedPdfMapping : null,
          include_feed_data: includeFeedData,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        setHasUnsavedChanges(false);
        setStatusMessage('Prompt configuration saved successfully.');
        setStatusType('success');
        setTimeout(() => {
          setStatusMessage(null);
          setStatusType(null);
        }, 5000);
      } else {
        throw new Error('Failed to save prompt configuration.');
      }
    } catch (err) {
      console.error('Error saving prompt configuration:', err);
      setStatusMessage('Error saving prompt configuration.');
      setStatusType('error');
      setTimeout(() => {
        setStatusMessage(null);
        setStatusType(null);
      }, 5000);
    }
  };

  // GENERATE PREVIEW
  const handleGeneratePreview = async () => {
    if (!selectedClient || !selectedPromptCombination || !promptCombinationData) return;

    try {
      setIsGenerating(true);
      setGenerationError(null);
      setGeneratedPreview(null);

      const fewShotDataToSend = fewShotPrompts.map((prompt) => {
        let inputObj = {};
        if (prompt.previewData && promptCombinationData.selectedColumns) {
          promptCombinationData.selectedColumns.forEach((col) => {
            if (col.toLowerCase() === 'id') {
              inputObj['id'] = prompt.previewData.id || '';
            } else {
              const val = prompt.previewData[`source_${col}`];
              inputObj[col] = val !== undefined ? val : '';
            }
          });
        }
        return {
          id_value: prompt.idValue,
          column_formats: prompt.columnFormats,
          input: inputObj,
          output: prompt.baseColumnContents,
        };
      });

      const selectedIdsArray = previewRowId.trim() ? [previewRowId.trim()] : [];

      const response = await axios.post(
        `${API_URL_PDP}/generate-pdp-fields`,
        {
          client_name: selectedClient,
          selected_columns: promptCombinationData.selectedColumns,
          system_prompt: promptCombinationData.systemPrompt,
          image_prompt: promptCombinationData.imagePrompt,
          instruction_prompt: promptCombinationData.instructionPrompt,
          few_shot_prompts: fewShotDataToSend,
          audit_prompt_activate: promptCombinationData.auditPromptActivate,
          audit_prompt_text: promptCombinationData.auditPromptText,
          selected_ids: selectedIdsArray,
          generation_scope: 'prompt_preview',
          multiOutput: multiOutput,
          research_mode: researchMode,
          research_prompt: promptCombinationData.researchPrompt,
          include_pdfs: includePdfs,
          pdf_mapping: includePdfs ? selectedPdfMapping : null,
          include_feed_data: includeFeedData,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setGeneratedPreview(response.data);
    } catch (err) {
      console.error('Error generating preview:', err);
      setGenerationError(err.response?.data?.message || 'Failed to generate preview.');
    } finally {
      setIsGenerating(false);
    }
  };

  // IMPORT AS FEW SHOT
  const handleImportAsNewFewShot = (item) => {
    if (!item) return;
    const rowId = item.prompt_input_data?.id || item.id || '';

    let outputObj = {};
    baseColumns.forEach((col) => {
      outputObj[col] = item.generated_data?.[col] ?? '';
    });

    const newFormats = createEmptyBaseColumnFormats();

    const newFewShotPrompt = {
      idValue: rowId,
      previewData: null,
      previewError: null,
      isLoadingPreview: false,
      isExpanded: true,
      baseColumnContents: outputObj,
      columnFormats: newFormats,
    };

    if (item.prompt_input_data) {
      const newPreviewData = { id: rowId };
      Object.keys(item.prompt_input_data).forEach((col) => {
        if (col.toLowerCase() !== 'id') {
          newPreviewData[`source_${col}`] = item.prompt_input_data[col];
        }
      });
      newFewShotPrompt.previewData = newPreviewData;
    }

    setFewShotPrompts((prev) => [...prev, newFewShotPrompt]);
    setHasUnsavedChanges(true);

    setStatusMessage(`Imported preview for ID=${rowId} as a new few-shot example.`);
    setStatusType('success');
    setTimeout(() => {
      setStatusMessage(null);
      setStatusType(null);
    }, 5000);
  };

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '34px',
      borderColor: '#e0e1eb',
      boxShadow: 'none',
      '&:hover': { borderColor: '#e0e1eb' },
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 8px',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px',
      padding: '0px',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '34px',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: '0 8px',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: '0 8px',
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#fff' : '#292B3D',
      backgroundColor: state.isSelected
        ? '#4758EB'
        : state.isFocused
        ? '#E0E1EB'
        : '#fff',
      padding: '8px 12px',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#4758EB',
      color: '#fff',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#fff',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: '#fff',
      ':hover': {
        backgroundColor: '#3947c2',
        color: '#fff',
      },
    }),
  };

  return (
    <div className={styles.container}>
      {/* Left Pane */}
      <div className={styles.leftPane}>
        <div className={styles.leftPaneContent}>
          {/* Top Row */}
          <div className={styles.topRow}>
            <button
              className={styles.backButton}
              onClick={handleBack}
              title="Back to PDP Generation"
            >
              <FaArrowLeft size={20} />
            </button>
            <h1 className={styles.pageTitle}>PDP Prompt Builder</h1>
          </div>

          {/* Client / Prompt Combination Row */}
          <div className={styles.dropdownRow}>
            <div className={styles.clientColumn}>
              <label className={styles.dropdownLabel}>Select Client</label>
              <Select
                styles={customSelectStyles}
                options={linkedClients}
                value={linkedClients.find((c) => c.value === selectedClient) || null}
                onChange={handleSelectClient}
                placeholder={isClientsLoading ? 'Loading...' : 'Choose Client'}
                isLoading={isClientsLoading}
                isClearable
                noOptionsMessage={() => clientError || 'No clients found'}
              />
            </div>

            <div className={styles.promptColumn}>
              <div className={styles.promptAndButtons}>
                <div className={styles.promptDropdownContainer}>
                  <label className={styles.dropdownLabel}>Select Prompt Combination</label>
                  <Select
                    styles={customSelectStyles}
                    options={promptCombinations}
                    value={
                      promptCombinations.find((p) => p.value === selectedPromptCombination) ||
                      null
                    }
                    onChange={handleSelectPromptCombination}
                    placeholder={
                      isPromptLoading
                        ? 'Loading...'
                        : selectedClient
                        ? 'Choose Combination'
                        : 'Select a client first'
                    }
                    isClearable
                    isDisabled={!selectedClient || isPromptLoading}
                    isLoading={isPromptLoading}
                    noOptionsMessage={() => promptError || 'No prompt combinations found'}
                  />
                </div>
                <div className={styles.iconButtons}>
                  <button
                    className={styles.addButton}
                    onClick={handleAdd}
                    title="Add"
                    disabled={!selectedClient}
                  >
                    <FaPlus size={16} />
                  </button>
                  <button
                    className={styles.importButton}
                    title="Import from another prompt combination"
                    onClick={() => confirmNavigation(() => setIsImportComboPopupOpen(true))}
                    disabled={!selectedClient}
                  >
                    <FaDownload size={16} />
                  </button>
                  <button
                    className={styles.removeButton}
                    onClick={handleRemove}
                    title="Remove"
                    disabled={!selectedPromptCombination || isDeleting}
                  >
                    {isDeleting ? <ClipLoader size={6} color="#fff" /> : <FaTimes size={16} />}
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Status/Message Bar */}
          {statusMessage && (
            <div
              className={`${styles.statusBar} ${
                statusType === 'success' ? styles.success : styles.error
              }`}
            >
              {statusMessage}
            </div>
          )}
          {(clientError || promptError || deleteError) && (
            <div className={styles.errorMessage}>
              {clientError || promptError || deleteError}
            </div>
          )}

          {selectedClient && (
            isLoadingAll ? (
              <div className={styles.loadingContainer}>
                <ClipLoader size={50} color="#4758EB" />
                <p>Loading prompt configuration...</p>
              </div>
            ) : (
              <>
                {selectedPromptCombination && promptCombinationData ? (
                  <>
                    {/* Redesigned Toggle Controls */}
                    <div className={styles.section}>
                      {/* Section Title */}
                      <h3 className={styles.sectionLabel} style={{ marginBottom: '15px', fontSize: '16px', fontWeight: '600' }}>Configuration Controls</h3>
                      
                      {/* Two-column layout for toggle groups */}
                      <div style={{ display: 'flex', gap: '30px', flexDirection: 'column' }}>
                        
                        <div style={{ 
                          border: '1px solid #e0e0e0', 
                          borderRadius: '8px', 
                          padding: '15px', 
                          backgroundColor: '#f8f9ff' 
                        }}>
                          <h4 style={{ margin: '0 0 15px 0', fontSize: '14px', fontWeight: '600', color: '#4758EB' }}>
                            Generation Output
                          </h4>
                          <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                            {/* Multi-output Toggle */}
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <span className={styles.sectionLabel} style={{ minWidth: '150px'}}>Multi Output:</span>
                              <label className={styles.toggleSwitch}>
                                <input
                                  type="checkbox"
                                  checked={multiOutput}
                                  onChange={handleToggleMultiOutput}
                                />
                                <span className={styles.slider}></span>
                              </label>
                              <span style={{ fontSize: '13px', color: '#666', marginLeft: '10px' }}>
                                {multiOutput ? 'Multiple fields' : 'Single field'}
                              </span>
                            </div>
                          </div>
                          <h4 style={{ margin: '0 0 15px 0', fontSize: '14px', fontWeight: '600', color: '#4758EB', marginTop: '32px'  }}>
                            Data Sources
                          </h4>
                          
                          <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                            {/* Feed Data - togglable but default to true */}
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <span className={styles.sectionLabel} style={{ minWidth: '150px' }}>Feed Data:</span>
                              <label className={styles.toggleSwitch}>
                                <input
                                  type="checkbox"
                                  checked={includeFeedData}
                                  onChange={handleToggleFeedData}
                                />
                                <span className={styles.slider}></span>
                              </label>
                              <span style={{ fontSize: '13px', color: '#666', marginLeft: '10px' }}>
                                {includeFeedData ? 'Enabled' : 'Disabled'}
                              </span>
                            </div>
                            
                            {/* Columns / JSON Keys multi-select - only show when Feed Data is enabled */}
                            {includeFeedData && (
                              <div style={{ marginLeft: '150px', marginBottom: '10px' }}>
                                <label className={styles.sectionLabel} style={{ marginBottom: '5px', display: 'block', fontWeight: 'normal', fontSize: '13px' }}>
                                  Feed Data Columns to Include:
                                </label>
                                <Select
                                  className={styles.columnsMultiSelect}
                                  isMulti
                                  closeMenuOnSelect={false}
                                  styles={{
                                    ...customSelectStyles,
                                    // Ensure dropdown menu has enough z-index to appear above other elements
                                    menu: (provided) => ({
                                      ...provided,
                                      zIndex: 9999,
                                      width: 'calc(100% - 20px)'  // Give some space on the right
                                    }),
                                    // Make container responsive
                                    container: (provided) => ({
                                      ...provided,
                                      width: '100%',
                                    }),
                                    // Ensure multiple values wrap nicely
                                    multiValue: (provided) => ({
                                      ...provided,
                                      backgroundColor: '#4758EB',
                                      color: '#fff',
                                      margin: '2px',
                                    })
                                  }}
                                  options={allColumns.map((c) => ({ label: c, value: c }))}
                                  value={
                                    promptCombinationData.selectedColumns
                                      .filter((c) => allColumns.includes(c))
                                      .map((c) => ({ label: c, value: c }))
                                  }
                                  onChange={handleMultiSelectColumns}
                                  placeholder="Select columns or JSON keys"
                                  noOptionsMessage={() => 'No columns/keys found'}
                                />
                              </div>
                            )}
                            
                            {/* Research Mode Toggle */}
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <span className={styles.sectionLabel} style={{ minWidth: '150px' }}>Web Search Data:</span>
                              <label className={styles.toggleSwitch}>
                                <input
                                  type="checkbox"
                                  checked={researchMode}
                                  onChange={handleToggleResearchMode}
                                />
                                <span className={styles.slider}></span>
                              </label>
                              <span style={{ fontSize: '13px', color: '#666', marginLeft: '10px' }}>
                                {researchMode ? 'Enabled' : 'Disabled'}
                              </span>
                            </div>
                            
                            {/* Include PDFs Toggle */}
                            <div style={{ 
                              display: 'flex', 
                              flexDirection: 'column', 
                              gap: '10px',
                              opacity: pdfMappings.length > 0 ? '1' : '0.5'  // Grey out when no mappings
                            }}>
                              <div style={{ 
                                display: 'flex', 
                                alignItems: 'center',
                                width: '100%'
                              }}>
                                {/* Left side - Toggle */}
                                <div style={{ 
                                  display: 'flex', 
                                  alignItems: 'center',
                                  width: '50%' 
                                }}>
                                  <span className={styles.sectionLabel} style={{ minWidth: '150px' }}>PDF Data:</span>
                                  <label className={styles.toggleSwitch}>
                                    <input
                                      type="checkbox"
                                      checked={includePdfs}
                                      onChange={handleToggleIncludePdfs}
                                      disabled={pdfMappings.length === 0}  // Disable when no mappings
                                    />
                                    <span className={styles.slider}></span>
                                  </label>
                                  <span style={{ fontSize: '13px', color: '#666', marginLeft: '10px' }}>
                                    {pdfMappings.length === 0 ? '(Client has no PDFs available)' : (includePdfs ? 'Enabled' : 'Disabled')}
                                  </span>
                                </div>

                                {/* Right side - PDF Mapping */}
                                {includePdfs && pdfMappings.length > 0 && (
                                  <div style={{ 
                                    width: '50%',
                                    paddingLeft: '20px' 
                                  }}>
                                    <Select
                                      styles={customSelectStyles}
                                      options={pdfMappings}
                                      isLoading={isPdfMappingsLoading}
                                      onChange={handleSelectPdfMapping}
                                      value={pdfMappings.find(mapping => mapping.value === selectedPdfMapping)}
                                      placeholder="Select a PDF mapping..."
                                      noOptionsMessage={() => 
                                        pdfMappingsError 
                                          ? `Error: ${pdfMappingsError}` 
                                          : 'No PDF mappings found'
                                      }
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                            
                            {/* Scrape PDP Toggle (disabled for future functionality) */}
                            <div style={{ display: 'flex', alignItems: 'center', opacity: '0.5' }}>
                              <span className={styles.sectionLabel} style={{ minWidth: '150px' }}>PDP Data:</span>
                              <label className={styles.toggleSwitch}>
                                <input
                                  type="checkbox"
                                  disabled={true}
                                />
                                <span className={styles.slider}></span>
                              </label>
                              <span style={{ fontSize: '13px', color: '#666', marginLeft: '10px' }}>
                                (Coming soon)
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Collapsible: Research Prompt */}
                    {researchMode && (
                      <div className={styles.promptContainer}>
                        <div className={styles.promptHeader} onClick={toggleResearchPrompt}>
                          <h3 className={styles.promptHeaderTitle}>
                            {researchPromptExpanded ? <FaChevronDown /> : <FaChevronRight />}
                            Search Prompt
                          </h3>

                          <div className={styles.promptHeaderActions}>
                            <button
                              className={styles.importButton}
                              onClick={handleImportResearch}
                              title="Import Research Prompt"
                            >
                              <FaDownload size={14} />
                            </button>
                          </div>
                        </div>

                        {researchPromptExpanded && (
                          <div className={styles.promptBody}>
                            {/* REPLACE: MentionsInput with ColumnTagger */}
                            <ColumnTagger
                              value={promptCombinationData.researchPrompt}
                              onChange={(newValue) => {
                                setPromptCombinationData((prev) => ({
                                  ...prev,
                                  researchPrompt: newValue,
                                }));
                                setHasUnsavedChanges(true);
                              }}
                              columns={allColumns}
                              placeholder="Type '@' to mention columns..."
                            />
                          </div>
                        )}
                      </div>
                    )}

                    {/* Collapsible: System Prompt */}
                    <div className={styles.promptContainer}>
                      <div className={styles.promptHeader} onClick={toggleSystemPrompt}>
                        <h3 className={styles.promptHeaderTitle}>
                          {systemPromptExpanded ? <FaChevronDown /> : <FaChevronRight />}
                          System Prompt
                        </h3>
                        <div className={styles.promptHeaderActions}>
                          <button
                            className={styles.importButton}
                            onClick={handleImportSystem}
                            title="Import System Prompt"
                          >
                            <FaDownload size={14} />
                          </button>
                        </div>
                      </div>
                      {systemPromptExpanded && (
                        <div className={styles.promptBody}>
                          <textarea
                            className={styles.textAreaPrompt}
                            value={promptCombinationData.systemPrompt}
                            onChange={handleChangeSystemPrompt}
                          />
                        </div>
                      )}
                    </div>

                    {/* Collapsible: Image Prompt */}
                    <div className={styles.promptContainer}>
                      <div className={styles.promptHeader} onClick={toggleImagePrompt}>
                        <h3 className={styles.promptHeaderTitle}>
                          {imagePromptExpanded ? <FaChevronDown /> : <FaChevronRight />}
                          Image Prompt
                        </h3>
                        <div className={styles.promptHeaderActions}>
                          <button
                            className={styles.importButton}
                            onClick={handleImportImage}
                            title="Import Image Prompt"
                          >
                            <FaDownload size={14} />
                          </button>
                        </div>
                      </div>
                      {imagePromptExpanded && (
                        <div className={styles.promptBody}>
                          <textarea
                            className={styles.textAreaPrompt}
                            value={promptCombinationData.imagePrompt}
                            onChange={handleChangeImagePrompt}
                          />
                        </div>
                      )}
                    </div>

                    {/* Collapsible: Instruction Prompt */}
                    <div className={styles.promptContainer}>
                      <div className={styles.promptHeader} onClick={toggleInstructionPrompt}>
                        <h3 className={styles.promptHeaderTitle}>
                          {instructionPromptExpanded ? <FaChevronDown /> : <FaChevronRight />}
                          Instruction Prompt
                        </h3>
                        <div className={styles.promptHeaderActions}>
                          <button
                            className={styles.importButton}
                            onClick={handleImportInstruction}
                            title="Import Instruction Prompt"
                          >
                            <FaDownload size={14} />
                          </button>
                        </div>
                      </div>
                      {instructionPromptExpanded && (
                        <div className={styles.promptBody}>
                          <textarea
                            className={styles.textAreaPrompt}
                            value={promptCombinationData.instructionPrompt}
                            onChange={handleChangeInstructionPrompt}
                          />
                        </div>
                      )}
                    </div>

                    {/* Few Shot Prompts */}
                    <div className={styles.fewShotSection}>
                      <div className={styles.fewShotSectionHeader}>
                        <h3 className={styles.fewShotSectionTitle}>Few Shot Prompts</h3>
                        <button
                          className={styles.addFewShotButton}
                          onClick={handleAddFewShotPrompt}
                          title="Add Few Shot Prompt"
                        >
                          <FaPlus size={16} />
                          <span>Add Few Shot</span>
                        </button>
                      </div>

                      <p className={styles.fewShotSectionDescription}>
                        Provide example data ("few shots") that QFE can learn from.
                      </p>

                      <div className={styles.fewShotList}>
                        {fewShotPrompts.map((prompt, index) => (
                          <div className={styles.fewShotPromptContainer} key={`fewshot-${index}`}>
                            <div
                              className={styles.fewShotPromptHeader}
                              onClick={(e) => handleToggleFewShotExpand(index, e)}
                            >
                              <h4 className={styles.fewShotPromptTitle}>
                                {prompt.isExpanded ? <FaChevronDown /> : <FaChevronRight />}
                                Few Shot Example #{index + 1}
                              </h4>
                              <div className={styles.fewShotPromptActions}>
                                <button
                                  className={styles.removeFewShotButton}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleRemoveFewShotPrompt(index);
                                  }}
                                  title="Remove this example"
                                >
                                  <FaTimes size={14} />
                                </button>
                              </div>
                            </div>

                            {prompt.isExpanded && (
                              <div className={styles.fewShotPromptBody}>
                                <div className={styles.fewShotPromptRow}>
                                  <div className={styles.fewShotPromptIdGroup}>
                                    <label>ID:</label>
                                    <input
                                      className={styles.fewShotPromptInput}
                                      placeholder="Insert ID"
                                      value={prompt.idValue}
                                      onChange={(e) =>
                                        handleFewShotIdChange(index, e.target.value)
                                      }
                                    />
                                  </div>
                                </div>

                                <div className={styles.fewShotPromptScrollBox}>
                                  {prompt.isLoadingPreview ? (
                                    <p>Loading data for ID: {prompt.idValue}...</p>
                                  ) : prompt.previewError ? (
                                    <p className={styles.errorPreview}>{prompt.previewError}</p>
                                  ) : prompt.previewData ? (
                                    <>
                                      <p><strong>Preview for ID: {prompt.idValue}</strong></p>
                                      {promptCombinationData.selectedColumns.length > 0 ? (
                                        <div className={styles.previewTable}>
                                          {promptCombinationData.selectedColumns.map((col) => {
                                            const val = getPreviewValue(prompt.previewData, col);
                                            return (
                                              <div className={styles.previewRow} key={col}>
                                                <div className={styles.previewLabel}>{col}</div>
                                                <div className={styles.previewValue}>
                                                  {val !== undefined && val !== null
                                                    ? String(val)
                                                    : '[empty]'}
                                                </div>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      ) : (
                                        <p>No columns selected.</p>
                                      )}
                                    </>
                                  ) : (
                                    <p>Enter an ID above to see a preview.</p>
                                  )}
                                </div>

                                <div className={styles.fewShotColumnsGrid}>
                                  {baseColumns.map((col) => {
                                    if (!multiOutput && col !== 'description') {
                                      return null;
                                    }

                                    const isRichText = prompt.columnFormats[col];
                                    const label =
                                      !multiOutput && col === 'description'
                                        ? 'Output:'
                                        : col;

                                    return (
                                      <div key={col} className={styles.fewShotColumnField}>
                                        <div className={styles.columnLabelRow}>
                                          <label className={styles.fewShotColumnLabel}>{label}</label>
                                          <div className={styles.columnFormatToggle}>
                                            <label className={styles.toggleSwitch}>
                                              <input
                                                type="checkbox"
                                                checked={isRichText}
                                                onChange={() =>
                                                  handleToggleColumnFormat(index, col)
                                                }
                                              />
                                              <span className={styles.slider}></span>
                                            </label>
                                            <span className={styles.toggleLabelText}>
                                              {isRichText ? 'Rich Text' : 'Plain Text'}
                                            </span>
                                          </div>
                                        </div>

                                        {isRichText ? (
                                          <ReactQuill
                                            theme="snow"
                                            value={safeQuillValue(prompt.baseColumnContents[col])}
                                            onChange={(html) =>
                                              handleBaseColumnChange(index, col, html)
                                            }
                                          />
                                        ) : (
                                          <textarea
                                            className={styles.textAreaFewShotPrompt}
                                            placeholder={
                                              !multiOutput && col === 'description'
                                                ? 'Output...'
                                                : `Plain text for ${col}`
                                            }
                                            value={prompt.baseColumnContents[col]}
                                            onChange={(e) =>
                                              handleBaseColumnChange(index, col, e.target.value)
                                            }
                                          />
                                        )}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* Audit Prompt */}
                    <div className={styles.auditPromptSection}>
                      <div
                        className={styles.auditPromptHeader}
                        onClick={toggleAuditPrompt}
                      >
                        <h3 className={styles.auditPromptHeaderTitle}>
                          {auditPromptExpanded ? <FaChevronDown /> : <FaChevronRight />}
                          Audit Prompt (ADVANCED)
                        </h3>
                      </div>
                      {auditPromptExpanded && (
                        <div className={styles.auditPromptBody}>
                          <p className={styles.auditPromptDescription}>
                            This prompt can be used for auditing or modifying the final output.
                          </p>

                          <div className={styles.auditPromptToggleRow}>
                            <label className={styles.toggleSwitch}>
                              <input
                                type="checkbox"
                                checked={promptCombinationData.auditPromptActivate}
                                onChange={handleToggleAuditActivate}
                                aria-label="Toggle Audit Prompt Activation"
                              />
                              <span className={styles.slider}></span>
                            </label>
                            <span className={styles.auditPromptToggleLabel}>
                              {promptCombinationData.auditPromptActivate ? 'Active' : 'Inactive'}
                            </span>
                          </div>

                          <textarea
                            className={styles.textAreaPrompt}
                            style={{ minHeight: '100px' }}
                            placeholder="Any instructions or notes for the audit?"
                            value={promptCombinationData.auditPromptText}
                            onChange={handleChangeAuditPromptText}
                          />
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <div className={styles.noCombinationSelected}>
                    <p>Please select (or create) a Prompt Combination to configure prompts.</p>
                  </div>
                )}
              </>
            )
          )}
        </div>

        {/* Sticky Save Bar */}
        {hasUnsavedChanges &&
          selectedClient &&
          selectedPromptCombination &&
          promptCombinationData && (
            <div className={styles.stickyUnsavedBar}>
              <div className={styles.stickyUnsavedText}>You have unsaved changes.</div>
              <button className={styles.saveButton} onClick={handleSave}>
                Save
              </button>
            </div>
          )}
      </div>

      {/* Right Pane */}
      <div className={styles.rightPane}>
        <h2 className={styles.rightPaneHeader}>PDP Prompt Preview</h2>

        <div className={styles.previewInputRow}>
          <input
            type="text"
            placeholder="Enter ID or leave empty for the first ID"
            value={previewRowId}
            onChange={(e) => setPreviewRowId(e.target.value)}
            className={styles.previewInput}
          />
          <button
            onClick={handleGeneratePreview}
            className={styles.generatePreviewButton}
            disabled={
              !selectedClient ||
              !selectedPromptCombination ||
              !promptCombinationData ||
              isGenerating ||
              (includePdfs && !selectedPdfMapping)
            }
          >
            {isGenerating ? 'Generating...' : 'Generate Preview'}
          </button>
        </div>

        {generationError && (
          <div className={styles.previewErrorMessage}>{generationError}</div>
        )}

        {isGenerating && (
          <div className={styles.rightPaneLoading}>
            <ClipLoader size={50} color="#4758EB" />
            <p>Generating preview...</p>
          </div>
        )}

        {generatedPreview && generatedPreview.generated_item_info && (
          <ItemPreview 
            item={generatedPreview.generated_item_info} 
            previewRichTextMode={previewRichTextMode}
            setPreviewRichTextMode={setPreviewRichTextMode}
            onImportAsNewFewShot={() => handleImportAsNewFewShot(generatedPreview.batch_updates?.[0])}
          />
        )}

        {generatedPreview && (
          <ComparisonDisplay
            batchUpdates={generatedPreview.batch_updates}
            baseColumns={baseColumns}
            onImportPreviewAsFewShot={handleImportAsNewFewShot}
            multiOutput={multiOutput}
            researchMode={researchMode}
            previewRichTextMode={previewRichTextMode} // Pass the state to ComparisonDisplay
          />
        )}
      </div>

      {/* POPUPS */}
      <PopupCreatePromptCombination
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        selectedClient={selectedClient}
        token={token}
        onCreated={handleCreated}
      />

      <PopupImportDefaultPromptPdp
        isOpen={isImportPopupOpen}
        onClose={() => setIsImportPopupOpen(false)}
        promptType={importPromptType}
        token={token}
        isAdmin={isAdmin}
        onImport={handleImportPromptText}
      />

      <PopupImportPromptCombinationPdp
        isOpen={isImportComboPopupOpen}
        onClose={() => setIsImportComboPopupOpen(false)}
        linkedClients={linkedClients}
        destinationClient={selectedClient}
        token={token}
        onImported={handleImportedCombo}
      />
    </div>
  );
}

// ComparisonDisplay + ComparisonItem
function ComparisonDisplay({ batchUpdates, baseColumns, onImportPreviewAsFewShot, multiOutput, researchMode, previewRichTextMode }) {
  if (!batchUpdates || batchUpdates.length === 0) {
    return <p>No comparison data to display.</p>;
  }

  return (
    <div style={{ width: '100%' }}>
      {batchUpdates.map((item, index) => (
        <ComparisonItem
          key={index}
          item={item}
          baseColumns={baseColumns}
          onImportPreviewAsFewShot={onImportPreviewAsFewShot}
          multiOutput={multiOutput}
          researchMode={researchMode}
          previewRichTextMode={previewRichTextMode}
        />
      ))}
    </div>
  );
}

function ComparisonItem({
  item,
  baseColumns,
  onImportPreviewAsFewShot,
  multiOutput,
  researchMode,
  previewRichTextMode,
}) {
  const {
    id,
    columns_used,
    prompt_input_data,
    prompt_excluded_data,
    generated_data,
    research_text,
    final_research_prompt,
    pdf_data,
  } = item;

  const [isExpanded, setIsExpanded] = useState(true);
  const [showFinalResearchPrompt, setShowFinalResearchPrompt] = useState(false);
  const [showPdfData, setShowPdfData] = useState(true);
  const [activeDataSource, setActiveDataSource] = useState('all'); // Can be 'feed', 'search', 'pdf', or 'all'

  const generatedKeys = generated_data ? Object.keys(generated_data) : [];
  const sortedGeneratedKeysInBaseColsOrder = baseColumns.filter((col) =>
    generatedKeys.includes(col)
  );
  const leftoverGeneratedKeys = generatedKeys.filter(
    (key) => !baseColumns.includes(key)
  );
  let finalGeneratedKeys = [
    ...sortedGeneratedKeysInBaseColsOrder,
    ...leftoverGeneratedKeys,
  ];

  if (!multiOutput) {
    finalGeneratedKeys = finalGeneratedKeys.filter((k) => k === 'description');
  }

  const toggleExpand = () => setIsExpanded((prev) => !prev);

  const safeQuillValue = (val) => val ?? '';

  // Helper to check if a data source has content
  const hasResearchData = research_text;
  const hasPdfData = pdf_data != null;
  const hasFeedData = columns_used && columns_used.length > 0;

  return (
    <div>
      {isExpanded && (
        <div className={styles.comparisonBody}>
          {/* Generated Output */}
          <div className={styles.dataCardGenerated}>
            <div className={styles.dataCardHeaderGenerated}>
              <FaCheckCircle className={styles.dataCardHeaderGeneratedIcon} />
              <span>Generated Output</span>
            </div>
            <div className={styles.dataCardScrollable}>
              <div className={styles.dataCardGeneratedContent}>
                {finalGeneratedKeys.length > 0 ? (
                  finalGeneratedKeys.map((key) => {
                    const val = generated_data?.[key] ?? '';
                    const displayKey =
                      !multiOutput && key === 'description' ? 'Output' : key;
                    return (
                      <div className={styles.dataRow} key={`generated-${key}`}>
                        <div className={styles.dataLabel}>{displayKey}</div>
                        {previewRichTextMode ? (
                          <ReactQuill
                            theme="snow"
                            value={safeQuillValue(val)}
                            readOnly
                            modules={{ toolbar: false }}
                            className={styles.richTextEditorQuill}
                          />
                        ) : (
                          <textarea
                            className={styles.dataValuePlainText}
                            value={val}
                            readOnly
                          />
                        )}
                      </div>
                    );
                  })
                ) : (
                  <p className={styles.emptyDataMessage}>
                    No generated output available.
                  </p>
                )}
              </div>
            </div>
          </div>

          {/* Data Sources Section with Tabs */}
          <div className={styles.dataSourcesSection}>
            <h4 className={styles.dataSourcesSectionTitle}>
              <FaDatabase style={{ marginRight: '8px' }} />
              Data Sources
            </h4>
            
            {/* Data Source Tabs */}
            <div className={styles.dataSourceTabs}>
              <button 
                className={`${styles.dataSourceTab} ${activeDataSource === 'all' ? styles.activeTab : ''}`}
                onClick={() => setActiveDataSource('all')}
              >
                <FaLayerGroup style={{ marginRight: '5px' }} />
                All Sources
              </button>
              {hasFeedData && (
                <button 
                  className={`${styles.dataSourceTab} ${activeDataSource === 'feed' ? styles.activeTab : ''}`}
                  onClick={() => setActiveDataSource('feed')}
                >
                  <FaTable style={{ marginRight: '5px' }} />
                  Feed Data
                </button>
              )}
              {hasResearchData && (
                <button 
                  className={`${styles.dataSourceTab} ${activeDataSource === 'search' ? styles.activeTab : ''}`}
                  onClick={() => setActiveDataSource('search')}
                >
                  <FaSearch style={{ marginRight: '5px' }} />
                  Web Search
                </button>
              )}
              {hasPdfData && (
                <button 
                  className={`${styles.dataSourceTab} ${activeDataSource === 'pdf' ? styles.activeTab : ''}`}
                  onClick={() => setActiveDataSource('pdf')}
                >
                  <FaFilePdf style={{ marginRight: '5px' }} />
                  PDF Data
                </button>
              )}
            </div>

            {/* Feed Data Display */}
            {(activeDataSource === 'all' || activeDataSource === 'feed') && hasFeedData && (
              <div className={styles.dataSourceContainer}>
                <div className={styles.dataSourceHeaderCentered}>
                  <FaTable className={styles.dataSourceIcon} />
                  <span>Feed Data</span>
                </div>
                
                <div className={styles.feedDataSection}>
                  {/* Included Data */}
                  <div className={styles.dataCard}>
                    <div className={styles.dataCardHeaderIncluded}>
                      <FaCheckCircle className={styles.dataCardHeaderIcon} />
                      <span>Included Data</span>
                    </div>
                    <div className={styles.dataCardScrollable}>
                      <div className={styles.dataCardContent}>
                        {columns_used && columns_used.length > 0 ? (
                          columns_used.map((key) => {
                            const val = prompt_input_data?.[key] ?? '';
                            return (
                              <div className={styles.dataRow} key={`included-${key}`}>
                                <div className={styles.dataLabel}>{key}</div>
                                {previewRichTextMode ? (
                                  <ReactQuill
                                    theme="snow"
                                    value={safeQuillValue(val)}
                                    readOnly
                                    modules={{ toolbar: false }}
                                    className={styles.richTextEditorQuill}
                                  />
                                ) : (
                                  <textarea
                                    className={styles.dataValuePlainText}
                                    value={val}
                                    readOnly
                                  />
                                )}
                              </div>
                            );
                          })
                        ) : (
                          <p className={styles.emptyDataMessage}>
                            No included data available.
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Excluded Data */}
                  <div className={styles.dataCard}>
                    <div className={styles.dataCardHeaderExcluded}>
                      <FaBan className={styles.dataCardHeaderIcon} />
                      <span>Excluded Data</span>
                    </div>
                    <div className={styles.dataCardScrollable}>
                      <div className={styles.dataCardContent}>
                        {prompt_excluded_data ? (
                          Object.keys(prompt_excluded_data).map((key) => {
                            const val = prompt_excluded_data[key] ?? '';
                            return (
                              <div className={styles.dataRow} key={`excluded-${key}`}>
                                <div className={styles.dataLabel}>{key}</div>
                                {previewRichTextMode ? (
                                  <ReactQuill
                                    theme="snow"
                                    value={safeQuillValue(val)}
                                    readOnly
                                    modules={{ toolbar: false }}
                                    className={styles.richTextEditorQuill}
                                  />
                                ) : (
                                  <textarea
                                    className={styles.dataValuePlainText}
                                    value={val}
                                    readOnly
                                  />
                                )}
                              </div>
                            );
                          })
                        ) : (
                          <p className={styles.emptyDataMessage}>No excluded data.</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Web Search Data Display */}
            {(activeDataSource === 'all' || activeDataSource === 'search') && hasResearchData && (
              <div className={styles.dataSourceContainer}>
                <div className={styles.dataSourceHeader}>
                  <div className={styles.dataSourceHeaderWithActions}>
                    <FaSearch className={styles.dataSourceIcon} />
                    <span>Web Search Data</span>
                  </div>
                  
                  {/* Only show the button if final_research_prompt exists */}
                  {final_research_prompt && (
                    <button
                      className={styles.pdfLinkButton}
                      onClick={() => setShowFinalResearchPrompt((prev) => !prev)}
                    >
                      <FaEye size={14} style={{ marginRight: '5px' }} />
                      {showFinalResearchPrompt ? 'Hide Prompt' : 'View Prompt'}
                    </button>
                  )}
                </div>

                {/* Conditionally show the final_research_prompt */}
                {showFinalResearchPrompt && (
                  <div className={styles.finalResearchPromptBox}>
                    <strong>Web Search Prompt:</strong>
                    <ReactMarkdown linkTarget="_blank">
                      {final_research_prompt}
                    </ReactMarkdown>
                  </div>
                )}

                <div className={styles.dataCardScrollable}>
                  <div className={`${styles.dataCardContent} ${styles.webSearchContent}`}>
                    <ReactMarkdown linkTarget="_blank">{research_text}</ReactMarkdown>
                  </div>
                </div>
              </div>
            )}

            {/* PDF Data Display */}
            {(activeDataSource === 'all' || activeDataSource === 'pdf') && hasPdfData && (
              <div className={styles.dataSourceContainer}>
                <div className={styles.dataSourceHeader}>
                  <div className={styles.dataSourceHeaderWithActions}>
                    <FaFilePdf className={styles.dataSourceIcon} />
                    <span>PDF Data</span>
                  </div>

                  {item.pdf_url && (
                    <a 
                      href={item.pdf_url} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className={styles.pdfLinkButton}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <FaDownload size={14} style={{ marginRight: '5px' }} />
                      Open PDF
                    </a>
                  )}
                </div>

                {showPdfData && (
                  <div className={styles.dataCardScrollable}>
                    <div className={`${styles.dataCardContent} ${styles.pdfContent}`}>
                      <ReactMarkdown linkTarget="_blank">{pdf_data}</ReactMarkdown>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

function ItemPreview({ item, previewRichTextMode, setPreviewRichTextMode, onImportAsNewFewShot }) {
  const { id, image_url, title, brand } = item;
  return (
    <div className={styles.itemPreviewContainer}>
      <div className={styles.itemPreviewImageContainer}>
        {image_url ? (
          <img src={image_url} alt={title || 'Preview'} className={styles.itemPreviewImage} />
        ) : (
          <div className={styles.itemPreviewImage} style={{ backgroundColor: '#ccc' }} />
        )}
      </div>
      <div className={styles.itemPreviewDetails}>
        <p className={styles.itemPreviewTitle}>{title || 'No Title'}</p>
        <p className={styles.itemPreviewBrand}>
          {brand ? `Brand: ${brand}` : 'No Brand'}
        </p>
        <p className={styles.itemPreviewId}>
          {id ? `ID: ${id}` : 'No ID'}
        </p>
      </div>

      {/* Item actions */}
      <div className={styles.itemPreviewButtons}>
        <div className={styles.richTextToggleContainer}>
          <span>Rich Text</span>
          <label className={styles.toggleSwitchComparison}>
            <input
              type="checkbox"
              checked={previewRichTextMode}
              onChange={() => setPreviewRichTextMode(prev => !prev)}
            />
            <span className={styles.sliderComparison}></span>
          </label>
        </div>
        <button
          className={styles.importFewShotButtonRightPane}
          onClick={onImportAsNewFewShot}
        >
          <FaUpload size={14} />
          Import as Few Shot
        </button>
      </div>
    </div>
  );
}

export default PromptBuilderPdp;
