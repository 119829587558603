import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './css/PdfDataMapper.module.css';
import { 
  FaSpinner, FaCheck, FaExclamationTriangle, FaSearch, FaFilePdf, 
  FaFileAlt, FaRobot, FaInfoCircle, FaTimes, FaExclamation,
  FaEdit, FaTrashAlt, FaPlus, FaArrowLeft, FaList, FaClone
} from 'react-icons/fa';
import Select from 'react-select';

const API_URL_PDP = process.env.REACT_APP_API_URL_PDP;

const PdfDataMapper = ({ token, selectedClient }) => {
  // Mode states
  const [view, setView] = useState('list'); // 'list', 'create', 'edit'
  const [existingMappings, setExistingMappings] = useState([]);
  const [selectedMapping, setSelectedMapping] = useState(null);
  const [isMappingsLoading, setIsMappingsLoading] = useState(false);
  
  // Form states
  const [step, setStep] = useState(1);
  const [mappingName, setMappingName] = useState('');
  const [fileUrl, setFileUrl] = useState('');
  const [fileType, setFileType] = useState('');
  const [headers, setHeaders] = useState([]);
  const [idColumn, setIdColumn] = useState(null);
  const [pdpUrlColumn, setPdpUrlColumn] = useState(null);
  const [extractionPrompt, setExtractionPrompt] = useState('Extract all product information including specifications, features, and descriptions.');
  const [pdfRawText, setPdfRawText] = useState(null);
  const [pdfProcessedText, setPdfProcessedText] = useState(null);
  const [pdfInfo, setPdfInfo] = useState(null);
  const [tokenUsage, setTokenUsage] = useState(null);
  const [apiMessages, setApiMessages] = useState(null);
  const [showApiInfo, setShowApiInfo] = useState(false);
  const [hasPreviewedOutput, setHasPreviewedOutput] = useState(false);
  
  // Status states
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showOverwriteConfirm, setShowOverwriteConfirm] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [mappingToDelete, setMappingToDelete] = useState(null);

  // Load existing mappings when client changes
  useEffect(() => {
    if (selectedClient) {
      loadExistingMappings();
    } else {
      setExistingMappings([]);
    }
  }, [selectedClient]);

  // Load existing mappings from the server
  const loadExistingMappings = async () => {
    if (!selectedClient || !token) return;
    
    setIsMappingsLoading(true);
    setError('');
    
    try {
      const response = await axios.get(
        `${API_URL_PDP}/get-pdf-mappings?client_name=${selectedClient}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      
      setExistingMappings(response.data.mappings || []);
    } catch (err) {
      console.error('Error loading mappings:', err);
      setError('Failed to load existing mappings');
    } finally {
      setIsMappingsLoading(false);
    }
  };

  // Custom styles for React-Select to ensure dropdowns appear above everything
  const customSelectStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      position: 'absolute',
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999
    }),
    container: (provided) => ({
      ...provided,
      zIndex: 1000,
      position: 'relative'
    })
  };

  // Dropdown options
  const headerOptions = headers.map(header => ({
    value: header,
    label: header
  }));

  // Function to handle API info popup toggle
  const toggleApiInfoPopup = () => {
    setShowApiInfo(!showApiInfo);
  };

  // Function to create a new mapping
  const handleCreateNew = () => {
    resetForm();
    setView('create');
    setStep(1);
  };

  // Function to return to the list view
  const handleBackToList = () => {
    setView('list');
    resetForm();
  };

  // Function to edit an existing mapping
  const handleEditMapping = (mapping) => {
    resetForm();
    
    // Populate form with mapping data
    setMappingName(mapping.mapping_name);
    setFileUrl(mapping.file_url);
    setFileType(mapping.file_type);
    setSelectedMapping(mapping);
    
    // Convert column names to Select format
    if (mapping.id_column) {
      setIdColumn({ value: mapping.id_column, label: mapping.id_column });
    }
    
    if (mapping.pdf_url_column) {
      setPdpUrlColumn({ value: mapping.pdf_url_column, label: mapping.pdf_url_column });
    }
    
    if (mapping.extraction_prompt) {
      setExtractionPrompt(mapping.extraction_prompt);
    }
    
    // Go to edit view and fetch headers
    setView('edit');
    setStep(1);
    
    // Fetch file headers
    fetchFileHeaders(mapping.file_url);
  };
  
  // Function to delete a mapping
  const handleDeleteClick = (mapping) => {
    setMappingToDelete(mapping);
    setShowDeleteConfirm(true);
  };
  
  const confirmDeleteMapping = async () => {
    if (!mappingToDelete) return;
    
    setIsLoading(true);
    
    try {
      await axios.delete(
        `${API_URL_PDP}/delete-pdf-mapping`,
        {
          headers: { Authorization: `Bearer ${token}` },
          data: {
            client_name: selectedClient,
            mapping_name: mappingToDelete.mapping_name
          }
        }
      );
      
      setSuccess(`Mapping "${mappingToDelete.mapping_name}" deleted successfully`);
      setShowDeleteConfirm(false);
      setMappingToDelete(null);
      
      // Reload mappings
      loadExistingMappings();
    } catch (err) {
      setError(`Failed to delete mapping: ${err.response?.data?.message || err.message}`);
    } finally {
      setIsLoading(false);
    }
  };
  
  const cancelDeleteMapping = () => {
    setShowDeleteConfirm(false);
    setMappingToDelete(null);
  };
  

  // Function to handle URL input changes
  const handleFileUrlChange = (e) => {
    const newUrl = e.target.value;
    
    // If we already have headers loaded and the URL changes, reset the state
    if (headers.length > 0 && newUrl !== fileUrl) {
      setHeaders([]);
      setIdColumn(null);
      setPdpUrlColumn(null);
      setPdfRawText(null);
      setPdfProcessedText(null);
      setPdfInfo(null);
      setTokenUsage(null);
      setApiMessages(null);
      setSuccess('');
    }
    
    setFileUrl(newUrl);
  };

  // Fetch file headers
  const fetchFileHeaders = async (url) => {
    if (!url || !selectedClient) return;
    
    setIsLoading(true);
    
    try {
      const response = await axios.post(
        `${API_URL_PDP}/fetch-file-headers`,
        {
          file_url: url,
          client_name: selectedClient
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      
      setFileType(response.data.file_type);
      setHeaders(response.data.headers);
      setStep(2);
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'Error fetching file headers';
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileUrlSubmit = async (e) => {
    e.preventDefault();
    
    if (!mappingName) {
      setError('Please enter a mapping name');
      return;
    }
    
    if (!fileUrl) {
      setError('Please enter a file URL');
      return;
    }
    
    if (!selectedClient) {
      setError('Please select a client first');
      return;
    }
    
    setIsLoading(true);
    setError('');
    setSuccess('');
    
    await fetchFileHeaders(fileUrl);
  };
  
  const handleColumnSelection = () => {
    if (!idColumn || !pdpUrlColumn) {
      setError('Please select both ID and PDF URL columns');
      return;
    }
    
    setError('');
    setSuccess('Column selection saved');
    setStep(3);
  };

  const handlePreviewExtraction = async () => {
    if (!extractionPrompt) {
      setError('Please enter an extraction prompt');
      return;
    }
    
    setIsLoading(true);
    setError('');
    setSuccess('');
    setPdfRawText(null);
    setPdfProcessedText(null);
    setPdfInfo(null);
    setTokenUsage(null);
    setApiMessages(null);
    
    try {
      const fileMappingData = {
        file_url: fileUrl,
        file_type: fileType,
        id_column: idColumn.value,
        pdf_url_column: pdpUrlColumn.value
      };
      
      const response = await axios.post(
        `${API_URL_PDP}/extract-pdf-text`,
        {
          client_name: selectedClient,
          file_mapping: fileMappingData,
          extraction_prompt: extractionPrompt
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      
      if (response.data.message) {
        // Error or warning message
        setError(response.data.message);
        setPdfInfo(response.data.pdf_info || null);
      } else {
        // Handle AI-processed text
        setPdfProcessedText(response.data.text);
        setPdfRawText(response.data.raw_text || response.data.text);
        setPdfInfo(response.data.pdf_info);
        setTokenUsage(response.data.token_usage || null);
        setApiMessages(response.data.api_messages || null);
        setSuccess('PDF content extracted and processed successfully');
        setHasPreviewedOutput(true);
      }
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'Error extracting PDF text';
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleSaveMapping = async (overwrite = false) => {
    setIsLoading(true);
    setError('');
    setSuccess('');
    
    try {
      const response = await axios.post(
        `${API_URL_PDP}/save-file-mapping`,
        {
          client_name: selectedClient,
          mapping_name: mappingName,
          file_url: fileUrl,
          file_type: fileType,
          id_column: idColumn.value,
          pdf_url_column: pdpUrlColumn.value,
          extraction_prompt: extractionPrompt,
          overwrite: overwrite || view === 'edit' // Always overwrite when in edit mode
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      
      setSuccess('Mapping configuration saved successfully');
      setShowOverwriteConfirm(false);
      
      // If successful, refresh the mappings list
      loadExistingMappings();
      
      // Go back to list view after saving
      setTimeout(() => {
        setView('list');
        resetForm();
      }, 1500);
    } catch (err) {
      if (err.response && err.response.status === 409) {
        // Mapping exists, show overwrite confirmation
        setShowOverwriteConfirm(true);
        setError('');
      } else {
        const errorMessage = err.response?.data?.message || 'Error saving mapping configuration';
        setError(errorMessage);
        setShowOverwriteConfirm(false);
      }
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleConfirmOverwrite = () => {
    handleSaveMapping(true);
  };
  
  const handleCancelOverwrite = () => {
    setShowOverwriteConfirm(false);
  };
  
  const resetForm = () => {
    setStep(1);
    setMappingName('');
    setFileUrl('');
    setFileType('');
    setHeaders([]);
    setIdColumn(null);
    setPdpUrlColumn(null);
    setExtractionPrompt('Extract all product information including specifications, features, and descriptions.');
    setPdfRawText(null);
    setPdfProcessedText(null);
    setPdfInfo(null);
    setError('');
    setSuccess('');
    setHasPreviewedOutput(false);
    setSelectedMapping(null);
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
      setError('');
      setSuccess('');
    } else {
      handleBackToList();
    }
  };

  // Format the date for display
  const formatDate = (timestamp) => {
    if (!timestamp || !timestamp.seconds) return 'N/A';
    const date = new Date(timestamp.seconds * 1000);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  };

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>PDF Data Mapper</h3>
      <p className={styles.description}>
        Configure mapping for PDF data sources. Extract and map content from your PDF files to generate high-quality PDPs.
      </p>
      
      {/* Status Messages */}
      {error && (
        <div className={styles.errorMessage}>
          <FaExclamationTriangle /> {error}
        </div>
      )}
      {success && (
        <div className={styles.successMessage}>
          <FaCheck /> {success}
        </div>
      )}
      
      {/* List View */}
      {view === 'list' && (
        <div className={styles.listView}>
          <div className={styles.horizontalHeader}>
            <div className={styles.headerActions}>
              <h4 className={styles.listTitle}>PDF Mapping Configurations</h4>
            </div>
            <div className={styles.centerContainer}>
              {/* Empty central container in list view */}
            </div>
            <div className={styles.headerActions}>
              <button 
                className={styles.createButton}
                onClick={handleCreateNew}
                disabled={!selectedClient}
              >
                <FaPlus size={14} className={styles.buttonIcon} />
                Create New Mapping
              </button>
            </div>
          </div>
          
          {!selectedClient ? (
            <div className={styles.emptyState}>
              <FaFilePdf size={32} className={styles.emptyStateIcon} />
              <p>Please select a client to view PDF mapping configurations</p>
            </div>
          ) : isMappingsLoading ? (
            <div className={styles.loadingContainer}>
              <FaSpinner className={styles.spinner} size={24} />
              <p>Loading configurations...</p>
            </div>
          ) : existingMappings.length === 0 ? (
            <div className={styles.emptyState}>
              <FaList size={32} className={styles.emptyStateIcon} />
              <p>No PDF mapping configurations found for this client</p>
              <button 
                className={styles.createButton}
                onClick={handleCreateNew}
              >
                <FaPlus size={14} className={styles.buttonIcon} />
                Create Your First Mapping
              </button>
            </div>
          ) : (
            <div className={styles.mappingsList}>
              {existingMappings.map((mapping, index) => (
                <div key={index} className={styles.mappingCard}>
                  <div className={styles.mappingCardContent}>
                    <h5 className={styles.mappingName}>{mapping.mapping_name}</h5>
                    <div className={styles.mappingDetails}>
                      <div className={styles.mappingDetail}>
                        <span className={styles.detailLabel}>File URL:</span>
                        <span className={styles.detailValue}>{mapping.file_url}</span>
                      </div>
                      <div className={styles.mappingDetail}>
                        <span className={styles.detailLabel}>ID Column:</span>
                        <span className={styles.detailValue}>{mapping.id_column}</span>
                      </div>
                      <div className={styles.mappingDetail}>
                        <span className={styles.detailLabel}>PDF URL Column:</span>
                        <span className={styles.detailValue}>{mapping.pdf_url_column}</span>
                      </div>
                    </div>
                  </div>
                  <div className={styles.mappingCardActions}>
                    <button 
                      className={styles.actionButton}
                      onClick={() => handleEditMapping(mapping)}
                      title="Edit"
                    >
                      <FaEdit />
                    </button>
                    <button 
                      className={`${styles.actionButton} ${styles.deleteButton}`}
                      onClick={() => handleDeleteClick(mapping)}
                      title="Delete"
                    >
                      <FaTrashAlt />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      
      {/* Create/Edit Form */}
      {(view === 'create' || view === 'edit') && (
        <>
          {/* Combined header with steps and navigation */}
          <div className={styles.horizontalHeader}>
            <div className={styles.headerActions}>
              <button 
                className={styles.backToListButton}
                onClick={handleBackToList}
              >
                <FaArrowLeft size={14} className={styles.buttonIcon} />
                Back to Overview
              </button>
            </div>
            <div className={styles.centerContainer}>
              {/* Progress Steps */}
              <div className={styles.progressSteps}>
                <div className={`${styles.progressStep} ${step >= 1 ? styles.activeStep : ''}`}>
                  <div className={styles.stepNumber}>1</div>
                  <span>Source</span>
                </div>
                <div className={styles.stepConnector}></div>
                <div className={`${styles.progressStep} ${step >= 2 ? styles.activeStep : ''}`}>
                  <div className={styles.stepNumber}>2</div>
                  <span>Columns</span>
                </div>
                <div className={styles.stepConnector}></div>
                <div className={`${styles.progressStep} ${step >= 3 ? styles.activeStep : ''}`}>
                  <div className={styles.stepNumber}>3</div>
                  <span>Extract</span>
                </div>
              </div>
            </div>
            <div className={styles.headerActions}></div>
          </div>
          
          <h4 className={styles.formTitle}>
            {view === 'create' ? 'Create New Mapping' : `Edit Mapping: ${selectedMapping?.mapping_name}`}
          </h4>
          
          {/* Step 1: File URL Input */}
          {step === 1 && (
            <div className={styles.section}>
              <h4 className={styles.sectionTitle}>Step 1: Data Source Configuration</h4>
              <p className={styles.description}>
                {view === 'create' 
                  ? 'Enter a name for this mapping configuration and the URL of your Google Sheet, CSV, or Excel file.'
                  : 'Review or update the mapping name and file URL.'}
              </p>
              
              <form onSubmit={handleFileUrlSubmit} className={styles.form}>
                <div className={styles.formGroup}>
                  <label htmlFor="mappingName" className={styles.label}>Mapping Name</label>
                  <input
                    type="text"
                    id="mappingName"
                    className={styles.input}
                    placeholder="Enter a name for this mapping configuration"
                    value={mappingName}
                    onChange={(e) => setMappingName(e.target.value)}
                    disabled={isLoading || (view === 'edit')} // Disabled in edit mode
                    required
                  />
                </div>
                
                <div className={styles.formGroup}>
                  <label htmlFor="fileUrl" className={styles.label}>File URL</label>
                  <input
                    type="url"
                    id="fileUrl"
                    className={styles.input}
                    placeholder="https://docs.google.com/spreadsheets/d/..."
                    value={fileUrl}
                    onChange={handleFileUrlChange}
                    disabled={isLoading}
                    required
                  />
                </div>
                
                <button 
                  type="submit" 
                  className={styles.button}
                  disabled={isLoading || !selectedClient || !fileUrl || !mappingName}
                >
                  {isLoading ? <FaSpinner className={styles.spinner} /> : 'Next'}
                </button>
              </form>
            </div>
          )}
          
          {/* Step 2: Column Selection */}
          {step === 2 && (
            <div className={styles.section}>
              <h4 className={styles.sectionTitle}>Step 2: Select Required Columns</h4>
              <p className={styles.description}>
                Select the columns that contain the product ID and PDF URL.
              </p>
              
              <div className={styles.formGroupDropdown}>
                <label className={styles.label}>ID Column</label>
                <Select
                  options={headerOptions}
                  value={idColumn}
                  onChange={setIdColumn}
                  isDisabled={isLoading}
                  placeholder="Select ID column"
                  className={styles.select}
                  styles={customSelectStyles}
                  menuPortalTarget={document.body}
                  menuPosition={'fixed'}
                />
              </div>
              
              <div className={styles.formGroupDropdown}>
                <label className={styles.label}>PDF URL Column</label>
                <Select
                  options={headerOptions}
                  value={pdpUrlColumn}
                  onChange={setPdpUrlColumn}
                  isDisabled={isLoading}
                  placeholder="Select PDF URL column"
                  className={styles.select}
                  styles={customSelectStyles}
                  menuPortalTarget={document.body}
                  menuPosition={'fixed'}
                />
              </div>
              
              <div className={styles.buttonGroup}>
                <button 
                  type="button" 
                  className={styles.buttonSecondary}
                  onClick={handleBack}
                  disabled={isLoading}
                >
                  Back
                </button>
                <button 
                  type="button" 
                  className={styles.button}
                  onClick={handleColumnSelection}
                  disabled={isLoading || !idColumn || !pdpUrlColumn}
                >
                  Next
                </button>
              </div>
            </div>
          )}
          
          {/* Step 3: PDF Extraction Prompt */}
          {step === 3 && (
            <div className={styles.sectionContainer}>
              <div className={styles.section}>
                <h4 className={styles.sectionTitle}>Step 3: PDF Extraction Configuration</h4>
                <p className={styles.description}>
                  Define how to extract content from PDF files. The AI will use your prompt to extract relevant information.
                </p>
                
                <div className={styles.formGroup}>
                  <label htmlFor="extractionPrompt" className={styles.label}>PDF Extraction Prompt</label>
                  <textarea
                    id="extractionPrompt"
                    className={styles.textarea}
                    placeholder="Instructions for extracting content from PDFs..."
                    value={extractionPrompt}
                    onChange={(e) => setExtractionPrompt(e.target.value)}
                    disabled={isLoading}
                    rows={5}
                  />
                </div>
                
                <div className={styles.buttonGroup}>
                  <div>
                    <button 
                      type="button" 
                      className={styles.buttonSecondary}
                      onClick={handleBack}
                      disabled={isLoading}
                    >
                      Back
                    </button>
                    <button 
                      type="button" 
                      className={styles.button}
                      onClick={handlePreviewExtraction}
                      disabled={isLoading || !extractionPrompt}
                    >
                      {isLoading ? <FaSpinner className={styles.spinner} /> : (
                        <>
                          <FaSearch className={styles.buttonIcon} />
                          Preview Output
                        </>
                      )}
                    </button>
                  </div>
                  <div>
                  {!hasPreviewedOutput && (
                      <div className={styles.previewRequiredHint}>
                        Preview output required before saving
                      </div>
                    )}
                    <button 
                      type="button" 
                      className={styles.buttonPreview}
                      onClick={() => handleSaveMapping(false)}
                      disabled={isLoading || !hasPreviewedOutput}
                      title={!hasPreviewedOutput ? "You must preview the output before saving" : ""}
                    >
                      {isLoading ? <FaSpinner className={styles.spinner} /> : (
                        view === 'edit' ? 'Update Configuration' : 'Save Configuration'
                      )}
                    </button>

                  </div>
                </div>
              </div>
              
              {/* Overwrite Confirmation Dialog */}
              {showOverwriteConfirm && (
                <div className={styles.confirmOverlayContainer}>
                  <div className={styles.confirmDialog}>
                    <div className={styles.confirmHeader}>
                      <FaExclamation size={20} />
                      <h4>Mapping Already Exists</h4>
                    </div>
                    <p>
                      A mapping configuration with the name "{mappingName}" already exists for client "{selectedClient}". 
                      Do you want to overwrite the existing configuration?
                    </p>
                    <div className={styles.confirmButtons}>
                      <button 
                        onClick={handleCancelOverwrite}
                        className={styles.buttonSecondary}
                      >
                        Cancel
                      </button>
                      <button 
                        onClick={handleConfirmOverwrite}
                        className={styles.buttonWarning}
                      >
                        Overwrite
                      </button>
                    </div>
                  </div>
                </div>
              )}
              
              {/* PDF Preview Section */}
              {(pdfProcessedText || pdfRawText || pdfInfo) && (
                <div className={styles.section}>
                  <div className={styles.pdfPreviewHeader}>
                    <h4 className={styles.sectionTitle}>
                      <FaFilePdf className={styles.pdfIcon} />
                      PDF Extraction Preview
                    </h4>
                    <div className={styles.pdfHeaderControls}>
                      {tokenUsage && (
                        <button 
                          className={styles.infoButton} 
                          onClick={toggleApiInfoPopup}
                          title="View API Details"
                        >
                          <FaInfoCircle />
                        </button>
                      )}
                      {pdfInfo && (
                        <div className={styles.pdfInfoBadge}>
                          {pdfInfo.num_pages} page{pdfInfo.num_pages !== 1 ? 's' : ''} | ID: {pdfInfo.id_value}
                        </div>
                      )}
                    </div>
                  </div>
                  
                  {/* Side by side comparison of raw and processed text */}
                  {pdfRawText && pdfProcessedText && (
                    <div className={styles.sideBySideContainer}>
                      <div className={styles.pdfColumn}>
                        <div className={styles.columnHeader}>
                          <FaFileAlt className={styles.columnIcon} />
                          Raw PDF Text
                        </div>
                        <div className={styles.pdfPreviewContent}>
                          {pdfRawText}
                        </div>
                      </div>
                      <div className={styles.pdfColumn}>
                        <div className={styles.columnHeader}>
                          <FaRobot className={styles.columnIcon} />
                          AI Processed Text
                        </div>
                        <div className={styles.pdfPreviewContent}>
                          {pdfProcessedText}
                        </div>
                      </div>
                    </div>
                  )}
                  
                  {/* If only raw text is available */}
                  {pdfRawText && !pdfProcessedText && (
                    <div className={styles.pdfPreviewContent}>
                      {pdfRawText}
                    </div>
                  )}
                  
                  {/* If no text is available */}
                  {!pdfRawText && !pdfProcessedText && (
                    <div className={styles.pdfPreviewEmpty}>
                      No text content could be extracted from this PDF.
                    </div>
                  )}
                  
                  {pdfInfo && pdfInfo.url && (
                    <div className={styles.pdfSourceLink}>
                      <a 
                        href={pdfInfo.url} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className={styles.pdfLink}
                      >
                        Open Original PDF
                      </a>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </>
      )}
      
      {/* Delete Confirmation Dialog */}
      {showDeleteConfirm && mappingToDelete && (
        <div className={styles.confirmOverlayContainer}>
          <div className={styles.confirmDialog}>
            <div className={styles.confirmHeader}>
              <FaTrashAlt size={20} style={{ color: '#e53939' }} />
              <h4 style={{ color: '#e53939' }}>Delete Mapping</h4>
            </div>
            <p>
              Are you sure you want to delete the mapping "{mappingToDelete.mapping_name}"? 
              This action cannot be undone.
            </p>
            <div className={styles.confirmButtons}>
              <button 
                onClick={cancelDeleteMapping}
                className={styles.buttonSecondary}
              >
                Cancel
              </button>
              <button 
                onClick={confirmDeleteMapping}
                className={styles.buttonDanger}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
      
      {/* API Info Popup */}
      {showApiInfo && (tokenUsage || apiMessages) && (
        <div className={styles.apiInfoPopupOverlay}>
          <div className={styles.apiInfoPopup}>
            <div className={styles.apiInfoHeader}>
              <h4>OpenAI API Details</h4>
              <button 
                className={styles.closeButton} 
                onClick={toggleApiInfoPopup}
              >
                <FaTimes />
              </button>
            </div>
            <div className={styles.apiInfoContent}>
              <div className={styles.apiInfoSection}>
                <h5>Extraction Prompt</h5>
                <div className={styles.apiPromptDisplay}>
                  {extractionPrompt}
                </div>
              </div>
              
              {apiMessages && (
                <div className={styles.apiInfoSection}>
                  <h5>API Messages</h5>
                  {apiMessages.map((message, index) => (
                    <div key={index} className={styles.apiMessageItem}>
                      <div className={styles.apiMessageRole}>{message.role}</div>
                      <div className={styles.apiMessageContent}>
                        {message.content.trim().replace(/[ \t]+/g, ' ')}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PdfDataMapper;
